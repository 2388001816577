import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import {
  ALTERNATIVE_OFFER_SHOW_MESSAGE_IDKEY,
  ALTERNATIVEOFFERIDKEY,
  CMS_CONFIG,
  deleteIsStartFromLockin,
  deleteStartFromLockinId,
  getRedirectCoverage,
  getSelectedOfferIds,
  ICommonLabelsInStepper,
  IProduct,
  isInApp,
  LOGGED_USER_KEYS,
  organizeCommonLabelsInStepper,
  setIsStartFromLockIn,
  setStartFromLockInId,
  usePage,
  useCmsConfig,
  setHubPath,
} from '@vfit/consumer/data-access';
import { API, checkWindow, PAGES } from '@vfit/shared/data-access';
import {
  LoggerInstance,
  resetData,
  ErrorDetail,
  errorManager,
  ErrorService,
} from '@vfit/shared/data-access';
import { useCoverageToolFlow } from '@vfit/consumer/hooks';
import { StepperStateCards } from '@vfit/shared/components';
import { IUpdateProductPriceType } from '@vfit/shared/models';
import { updateProductPrice } from '@vfit/consumer/ibuyfixed';
import { ICoverageToolModalProps } from './coverageToolModal.models';
import { CoverageToolModalContainer } from './coverageToolModal.style';
import { useCoverageToolModalProvider } from './coverageToolModal.context';
import { getCoverageToolFlow, organizeFlowsAppend } from './coverageToolModal.utils';
import { ID_FLOWS, MANUAL_FLOW } from './coverageToolModal.flow';
import { IStepperUtils } from '../StepperStateCards/stepperStateCards.models';

const CoverageToolModalContent = ({
  handleClose,
  backgroundImage,
  product,
  customRedirect,
}: ICoverageToolModalProps) => {
  const queryClient = useQueryClient();
  const isApp = isInApp() || window.localStorage.getItem('isApp') === 'true';
  const stepperRef = useRef<IStepperUtils>();
  const {
    setOfferId,
    isManual,
    setSlidesGoBack,
    slidesGoBack,
    setProduct,
    offerId,
    isDisabledBackButton,
    setIsLoading,
    setIsSuccess,
    setIsError,
    setErrorCoverage,
    setIsAddressConfirmed,
    isAddressConfirmed,
    address,
  } = useCoverageToolModalProvider();
  const silentLogin: any = queryClient.getQueryData('silentLogin');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const [steps, setSteps] = useState(
    getCoverageToolFlow(product?.isLockInProduct, !!isApp, isLoggedUser)
  );
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [, isSuccess, isLoading, isError, , errorType] = useCoverageToolFlow({
    offerId,
    onConfirmAddress: isAddressConfirmed,
    alternativeProductList: product?.alternativeProductOfferList,
    alternativeProductListByTech: product?.alternativeProductOfferListByTech,
    step: steps?.[activeSlideIndex].title,
  });
  const { products: allProducts } = usePage();
  const { pathname, reload } = useRouter();
  const nodeRef = useRef(null);

  const programmaticallyGoBackNext = async (numberSlides: number, type: 'NEXT' | 'BACK') => {
    const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
    if (numberSlides !== -1) {
      // eslint-disable-next-line no-restricted-syntax, @typescript-eslint/no-unused-vars
      for (const indexSlides of Array.from(Array(numberSlides))) {
        if (type === 'NEXT') {
          stepperRef?.current?._stepperGoNext();
          await delay(100);
        } else {
          stepperRef?.current?._stepperGoPrev();
          await delay(600);
        }
      }
      setSlidesGoBack(-1);
    }
  };
  const alternativeProductShowMessageInMap = product?.alternativeProductShowMessageInMap || [];

  useEffect(() => {
    setIsSuccess(isSuccess);
  }, [isSuccess]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      resetData(queryClient, ['validatedAddress', 'offeringServiceability', 'createdAddress']);
      setIsAddressConfirmed(false);
    }
    setErrorCoverage({
      ...address,
      errorType,
    });
    setIsError(isError);
  }, [isError]);

  useEffect(() => {
    setErrorCoverage(address);
  }, [errorType]);

  useEffect(() => {
    if (activeSlideIndex === 0) {
      setIsError(false);
      setIsLoading(false);
      setIsSuccess(false);
    }
  }, [activeSlideIndex]);

  useEffect(() => {
    if (slidesGoBack && slidesGoBack !== -1 && stepperRef?.current) {
      programmaticallyGoBackNext(slidesGoBack, 'BACK');
    }
  }, [slidesGoBack]);

  const hideBackButton = useMemo(
    () => (isApp || product?.disableBackOnCoverage) && activeSlideIndex === 0,
    [isApp, activeSlideIndex]
  );

  useEffect(() => {
    const organizedStepManual = organizeFlowsAppend(
      steps,
      isManual,
      ID_FLOWS.AUTO,
      MANUAL_FLOW.MANUAL_CARD
    );
    setSteps(organizedStepManual);
  }, [isManual]);

  useEffect(() => {
    LoggerInstance.debug('Set product', product);
    localStorage.setItem('tProd', JSON.stringify(product));
    setOfferId(product?.offerId?.toString() || '');
    setProduct(product as IProduct);
    if (product?.isLockInProduct) {
      setIsStartFromLockIn();
      setStartFromLockInId(product.offerId.toString());
      sessionStorage.removeItem('uxfauthorization');
    } else {
      deleteIsStartFromLockin();
      deleteStartFromLockinId();
    }
  }, [product]);

  useEffect(() => {
    if (checkWindow()) {
      localStorage.setItem('hubPath', setHubPath(product?.slug || ''));
      /**
       * If browser back button was used, flush cache
       * This ensures that user will always see an accurate, up-to-date view based on their state
       * https://stackoverflow.com/questions/8788802/prevent-safari-loading-from-cache-when-back-button-is-clicked
       */
      window.onpageshow = (event) => {
        if (event.persisted) {
          window.location.reload();
          handleClose?.();
        }
      };
    }
  }, []);

  const handleRedirect = () => {
    const { offerId: filterdOfferId, cmsId } = getSelectedOfferIds();
    const isAlternativeOffer: boolean = product?.offerId != filterdOfferId;
    const paths = pathname.split('/', -1);
    const isShoppingCart = paths[paths.length - 1] === PAGES.SHOPPING_CART;
    let productRedirect: IProduct | undefined = {
      ...(product as IProduct),
      ...updateProductPrice(product as IProduct, IUpdateProductPriceType.EDIT),
    };
    localStorage.removeItem(ALTERNATIVEOFFERIDKEY);
    localStorage.removeItem(ALTERNATIVE_OFFER_SHOW_MESSAGE_IDKEY);
    if (isAlternativeOffer && !isApp && !isShoppingCart) {
      productRedirect = allProducts?.find((productForSlug) => productForSlug?.cmsId == cmsId);
      if (productRedirect) {
        localStorage.setItem('tProd', JSON.stringify(productRedirect));
        localStorage.setItem(ALTERNATIVEOFFERIDKEY, '1');
        if (productRedirect?.cmsId) {
          const showMessageInMap =
            alternativeProductShowMessageInMap?.includes(productRedirect.cmsId.toString()) || false;
          if (showMessageInMap) localStorage.setItem(ALTERNATIVE_OFFER_SHOW_MESSAGE_IDKEY, '1');
        }
      }
    }
    if (!productRedirect) {
      LoggerInstance.debug('Non siamo riusciti a trovare il prodotto');
      const errorGeneric: ErrorDetail = {
        message: 'Si è verificato un errore.',
        title: 'Ops',
        actionEvent: () => {
          if (checkWindow()) {
            window.location.href = '/';
          }
        },
        actionText: 'Chiudi',
      };
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), errorGeneric);
      return;
    }
    if (productRedirect?.slug) {
      if (isShoppingCart) {
        const querySlug = paths[paths.length - 2];
        if (querySlug && querySlug == productRedirect?.slug) {
          reload();
        }
        return;
      }
      window.location.href = getRedirectCoverage();
    } else if (customRedirect) {
      customRedirect();
    }
  };
  const commonLabels = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_STEPPER_COMMON_LABELS_FIXED
  ) as ICommonLabelsInStepper;
  const { goBackInStepper } = organizeCommonLabelsInStepper(commonLabels);

  return (
    <CoverageToolModalContainer ref={nodeRef}>
      <StepperStateCards
        ref={stepperRef}
        steps={steps}
        onBack={handleClose}
        onClose={handleClose}
        onFinish={handleRedirect}
        blurredImage={backgroundImage}
        hideBackButton={hideBackButton || isDisabledBackButton}
        onActiveSlideChange={setActiveSlideIndex}
        goBackLabel={goBackInStepper}
      />
    </CoverageToolModalContainer>
  );
};

export default CoverageToolModalContent;
