import { awsConsumerClient, INextError, LoggerInstance } from '@vfit/shared/data-access';
import { API, AppMobile, checkWindow, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useQuery, useQueryClient } from 'react-query';
import {
  errorMock,
  IActivableProduct,
  IAppSession,
  retrieveUserAgent,
} from '@vfit/consumer/data-access';

const getActive = (
  payload: { customerId: string },
  headers?: { [key: string]: string }
): Promise<unknown> =>
  awsConsumerClient.get(`${API.DXL_ACTIVE_PRODUCT_LIST}`, {
    searchParams: {
      ...payload,
    },
    headers: {
      ...(Object.keys(headers)?.length > 0 && {
        ...headers,
      }),
    },
  });

export const useGetActive = (cusId: string, deviceId: string, enabled = false) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['getActiveProducts'],
    () => {
      const isApp = AppMobile.checkIsApp();
      const appSession: IAppSession =
        isApp && checkWindow() ? getFromLocalStorageByKey('appSession') : null;
      const headers = {
        'X-Device-UserAgent':
          isApp && appSession?.customerUserAgent
            ? appSession?.customerUserAgent
            : retrieveUserAgent(),
        'X-Device-Id': isApp && appSession?.installationId ? appSession?.installationId : deviceId,
      };
      const customerId = isApp ? appSession?.customerId || cusId : cusId;
      return getActive({ customerId }, headers);
    },
    {
      enabled,
      onSuccess: (data: IActivableProduct[]) => {
        queryClient.setQueryData('getActiveProducts', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - getActiveProducts', error);
        queryClient.setQueryData('getActiveProducts', errorMock('getActiveProducts'));
      },
    }
  );
};
