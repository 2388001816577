import { IActionCustom } from './action.models';

export interface IKeyword {
  keyword: string;
}

export type IOnTrack = (s: ICommonSlides) => void;

export interface ICommonSlides {
  id?: number;
  key?: string;
  redirectCoveragePath?: string;
  image?: string;
  imageMobile?: string;
  isContain?: string;
  overlayImage?: string;
  overlayImageMobile?: string;
  video?: string;
  videoMobile?: string;
  frontLabel?: string;
  category?: string;
  title?: string;
  description?: string;
  withMask?: boolean;
  maskIcon?: string;
  maskIconSize?: number;
  customFontSize?: ICustomFontSize;
  action?: IActionCustom;
  showInfoLine?: boolean;
  offerType?: number;
  slug?: string;
  detail?: IDetail;
  positionTextTop?: string;
  pills?: {
    pills?: IKeyword[];
    isDark: string;
  };
  actionNavigationUrl?: string;
}

export interface IDetail {
  subtitle: string;
  title: string;
  description: IDescription;
  accordion: IAccordion[];
}

export interface IAccordion {
  isSummary?: string;
  description?: string;
  buttonLabel?: string;
  title?: string;
  text?: string;
  url?: string;
}

export interface IDescription {
  textHtml: string;
  image: string;
  textSimple: string;
}

export interface ICustomFontSize {
  title: number;
  description: number;
}

export enum IType {
  TEXT,
  PRODUCT,
}

export interface ITextModal {
  show: boolean;
  text?: string;
  title?: string;
  disableOrganizeText?: boolean;
}
