import { Children, forwardRef, useEffect, useState } from 'react';
import { Arrow } from '@vfit/shared-icons';
import { ImageAtoms, ButtonSlide, CustomText, Price } from '@vfit/shared/atoms';
import { useRouter } from 'next/router';
import { Inflation, VfModal } from '@vfit/shared/components';
import { Fade } from 'react-awesome-reveal';
import { useDeviceType } from '@vfit/shared/hooks';
import { IGradientImage, PriceVariant } from '@vfit/shared/models';
import { checkWindow, getButtonActionByActionType } from '@vfit/shared/data-access';
import { purify } from '@vfit/shared/themes';
import { checkIsApp } from '@vfit/consumer/data-access';
import { ISlideProductProps } from './slideProduct.models';
import {
  ButtonOverlayContainer,
  CircleButton,
  DivImgContainer,
  LandingTitle,
  OverlayBody,
  OverlayMain,
  OverlayTitle,
  PillElementApp,
  SlideButtons,
  SlideProductButtonSlideContainer,
  SlideProductContainer,
  SlideProductContent,
  SlideProductContentBottom,
  SlideProductContentTop,
  SlideProductNavigation,
  SlideProductText,
  TargetContainer,
  TargetText,
  Title,
  TitleApp,
} from './slideProduct.style';

// TODO Refactor this component. Split logic
const SlideProduct = forwardRef<HTMLDivElement, ISlideProductProps>(
  (
    {
      product,
      globalPromo,
      onActiveProduct,
      onButtonScroll,
      disableNavigation = false,
      isPanicMode,
      isActiveAddress,
      addressAndTechnology,
    }: ISlideProductProps,
    ref
  ) => {
    const {
      offerId,
      video,
      title,
      price,
      originalPrice,
      recurrence,
      subprice,
      description,
      image,
      imageMobile,
      category,
      backLabel,
      backUrl,
      inflactionPdp,
      targetAction,
      isGlobalPromoActive,
      pills,
      priceVariant,
      isLandingClub,
    } = product;
    const [pTitle] = purify([title]);
    const [isHeaderLite, setIsHeaderLite] = useState(false);
    const { isMobile } = useDeviceType();
    const { asPath } = useRouter();
    const [isOpenTargetModal, setIsOpenTargetModal] = useState(false);
    const promoMarketingTag = globalPromo?.marketingTagLabel || '';
    const extendedHeader = document.getElementById('extendedHeader')?.firstChild;
    const [targetLabel, modalTitle, modalDescription] = purify(
      !isGlobalPromoActive
        ? [targetAction?.label, targetAction?.title, targetAction?.description]
        : [
            inflactionPdp && inflactionPdp[0]?.text?.toUpperCase?.(),
            inflactionPdp && inflactionPdp[0]?.text,
            inflactionPdp && inflactionPdp[0]?.popup,
          ]
    );
    const { push } = useRouter();

    const handleBack = () => {
      if (backUrl) {
        window.open(backUrl, '_blank');
      } else {
        window.location.href = asPath.slice(0, asPath.lastIndexOf('/'));
      }
    };

    const renderPills = () => {
      if (isGlobalPromoActive) {
        if (promoMarketingTag) {
          return (
            <PillElementApp>
              <span>{promoMarketingTag}</span>
            </PillElementApp>
          );
        }
      }
      return Children.toArray(
        pills?.map((pill) => (
          <PillElementApp>
            <span>{pill}</span>
          </PillElementApp>
        ))
      );
    };

    const renderTopSlide = () => {
      if (isActiveAddress) {
        if (!addressAndTechnology) return null;
        return addressAndTechnology?.();
      }
      return (
        <>
          {checkIsApp() && renderPills()}
          {category && <h2>{!checkIsApp() ? category?.toUpperCase() : category}</h2>}
          {pTitle && !checkIsApp() && !product?.isLandingClub && (
            <Title text={pTitle} dangerouslySetInnerHTML={{ __html: pTitle }} />
          )}
          {pTitle && checkIsApp() && !product?.isLandingClub && (
            <TitleApp text={pTitle} dangerouslySetInnerHTML={{ __html: pTitle }} />
          )}
          {pTitle && product?.isLandingClub && (
            <LandingTitle text={pTitle} dangerouslySetInnerHTML={{ __html: pTitle }} />
          )}
        </>
      );
    };

    useEffect(() => {
      if (checkWindow()) {
        const headerLite = document.getElementsByClassName('headerLite');
        if (headerLite?.length > 0) {
          setIsHeaderLite(true);
        }
      }
    }, []);

    const content = () => (
      <SlideProductContent>
        <SlideProductContentTop>
          <SlideProductNavigation isHeaderLiteMode={isHeaderLite}>
            {!disableNavigation && backLabel && !checkIsApp() && (
              <button type="button" className="back" onClick={handleBack}>
                <div>
                  <Arrow rotate={130} />
                </div>
                <span>{backLabel}</span>
              </button>
            )}
            {targetAction && targetLabel && (
              <TargetContainer onClick={() => setIsOpenTargetModal(true)}>
                <TargetText dangerouslySetInnerHTML={{ __html: targetLabel || '' }} />
                <CircleButton>+</CircleButton>
              </TargetContainer>
            )}
          </SlideProductNavigation>

          <Fade triggerOnce>
            <SlideProductText disablePaddingTop={!!extendedHeader}>
              <>
                {renderTopSlide()}
                <div
                  className={checkIsApp() ? 'text-app' : 'text'}
                  style={{ marginTop: 32, marginBottom: 32 }}
                >
                  {description && isLandingClub && (
                    <CustomText text={description} textAlign="left" />
                  )}
                  {price && (
                    <div className="price">
                      <Price
                        label={product?.label || ''}
                        amount={price}
                        oldAmount={originalPrice}
                        recurrence={recurrence}
                        variant={
                          product?.isLandingClub ? 'freeAmount' : (priceVariant as PriceVariant)
                        }
                        detail={product?.isLandingClub ? product?.subprice : ''}
                        description={product?.isLandingClub ? product?.descriptionRecurring : ''}
                      />
                    </div>
                  )}
                  {subprice && !product?.isLandingClub && <h5>{subprice}</h5>}
                  {description && !isLandingClub && (
                    <CustomText text={description} textAlign="left" />
                  )}
                  {inflactionPdp &&
                    inflactionPdp[0]?.text &&
                    !isGlobalPromoActive &&
                    inflactionPdp.map((iPdp) => (
                      <Inflation
                        InflationTitle={iPdp?.text}
                        popup={iPdp?.popup}
                        color={iPdp?.color}
                        compactStyle
                      />
                    ))}
                </div>
                <SlideButtons>
                  {product.action?.title && !isMobile && (
                    <SlideProductButtonSlideContainer isApp={checkIsApp()}>
                      <ButtonSlide
                        label={
                          isPanicMode ? product.panicModeAction?.title || '' : product.action.title
                        }
                        onClick={() =>
                          onActiveProduct(isPanicMode ? product.panicModeAction : product.action)
                        }
                        isApp={checkIsApp()}
                        buttonColor="#e60000"
                        labelColor="#fff"
                        borderColor="#e60000"
                        hoverColor="#bd0000"
                        clickColor="#a10000"
                        name={`action_actfirst_${product?.offerId?.toString() || ''}`}
                        ref={ref}
                      />
                    </SlideProductButtonSlideContainer>
                  )}
                  {product.pdpSecondAction?.title && !isPanicMode && (
                    <SlideProductButtonSlideContainer isApp={checkIsApp()}>
                      <ButtonSlide
                        label={product.pdpSecondAction.title}
                        onClick={() => onActiveProduct(product.pdpSecondAction)}
                        isApp={checkIsApp()}
                        buttonColor="#fff"
                        hoverColor="#262626"
                        labelColor={checkIsApp() ? '#e60000' : ''}
                        hoverTextColor="#fff"
                        clickColor="#7e7e7e"
                        clickTextColor="#fff"
                        name={`action_actsecond_${product?.offerId?.toString() || ''}`}
                      />
                    </SlideProductButtonSlideContainer>
                  )}
                </SlideButtons>
              </>
            </SlideProductText>
          </Fade>
        </SlideProductContentTop>
        {!isMobile && (
          <SlideProductContentBottom>
            <ButtonSlide
              onClick={onButtonScroll}
              animated
              small
              smallSize={50}
              rotation={90}
              isApp={checkIsApp()}
              buttonColor="#fff"
              hoverColor="rgba(255, 255, 255, 0.8)"
              clickColor="rgba(255, 255, 255, 0.6)"
              name="action_go_bottom"
            />
          </SlideProductContentBottom>
        )}
      </SlideProductContent>
    );

    const containerImage = () => (
      <SlideProductContainer key={offerId}>
        {content()}
        <DivImgContainer absolute>
          <ImageAtoms
            gradient={IGradientImage.GRADIENT_TOP_LEFT}
            nameCard="slideProduct"
            image={image}
            imageMobile={imageMobile}
            isMobile={isMobile}
          />
        </DivImgContainer>
      </SlideProductContainer>
    );

    // const containerVideo = () => (
    //   <SlideProductContainerVideo key={id}>
    //     <div>
    //       <video
    //         autoPlay
    //         playsInline
    //         src={video}
    //         loop
    //         muted
    //       />
    //     </div>
    //     {content()}
    //   </SlideProductContainerVideo>
    // )

    getButtonActionByActionType(targetAction?.action, push);
    const modalContent = () => (
      <OverlayMain>
        <OverlayTitle dangerouslySetInnerHTML={{ __html: modalTitle || '' }} />
        <OverlayBody dangerouslySetInnerHTML={{ __html: modalDescription || '' }} />
        {targetAction?.action &&
          targetAction?.action?.type &&
          targetAction?.action?.title &&
          !isGlobalPromoActive && (
            <ButtonOverlayContainer>
              <ButtonSlide
                label={targetAction?.action?.title || ''}
                isApp={checkIsApp()}
                onClick={() => getButtonActionByActionType(targetAction?.action, push)}
                buttonColor="#e60000"
                labelColor="#fff"
                borderColor="#e60000"
                hoverColor="#bd0000"
                clickColor="#a10000"
              />
            </ButtonOverlayContainer>
          )}
      </OverlayMain>
    );

    return (
      <div>
        {!video && containerImage()}
        {/* TODO: Adapt video */}
        {/* {video && containerVideo()} */}
        <VfModal isOpen={isOpenTargetModal} handleClose={() => setIsOpenTargetModal(false)}>
          {modalContent()}
        </VfModal>
      </div>
    );
  }
);

export default SlideProduct;
