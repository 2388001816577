import { TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';

interface ITaggingHomepage {
  taggingOptions?: TrackingOptions;
  isDisabledInitialTrack?: boolean;
}

const TaggingHomepage = ({ taggingOptions, isDisabledInitialTrack }: ITaggingHomepage) => {
  const client = useQueryClient();

  const lang = localStorage.getItem('lang');

  delete taggingOptions?.product_category;

  const { trackView } = useTracking({
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: taggingOptions,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isDisabledInitialTrack || false,
    page_country: lang?.toUpperCase() || 'IT',
    page_language: lang || 'IT',
    page_locale: lang ? `${lang}-${lang?.toUpperCase()}` : 'it-IT',
  });

  setTrackLink(trackView);

  return null;
};

export default TaggingHomepage;
