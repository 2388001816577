import {
  awsConsumerClient,
  handleUseQuery,
  INextError,
  LoggerInstance,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { API, AppMobile, checkWindow, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  IActivableResponse,
  IAppSession,
  ProductType,
  retrieveUserAgent,
} from '@vfit/consumer/data-access';

const getActivable = (
  payload: {
    sims: string;
    productType: string;
  },
  headers?: { [key: string]: string }
): Promise<IActivableResponse> =>
  awsConsumerClient.get(`${API.DXL_ACTIVABLE_PRODUCTS}`, {
    searchParams: {
      ...payload,
    },
    headers: {
      ...(Object.keys(headers)?.length > 0 && {
        ...headers,
      }),
    },
  });

export const useGetActivable = (
  msisdn: string,
  productType: ProductType,
  deviceId: string,
  enabled = false
) => {
  const keysDependency = ['getActivableProducts'];
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const isApp = AppMobile.checkIsApp();
      const appSession: IAppSession =
        isApp && checkWindow() ? getFromLocalStorageByKey('appSession') : null;
      const headers = {
        'X-Device-UserAgent':
          isApp && appSession?.customerUserAgent
            ? appSession?.customerUserAgent
            : retrieveUserAgent(),
        'X-Device-Id': isApp && appSession?.installationId ? appSession?.installationId : deviceId,
      };
      return getActivable({ sims: msisdn, productType }, headers);
    },
    options: {
      enabled,
      onSuccess: (data: IActivableResponse) => {
        LoggerInstance.debug('USER: catalog success', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - getActivableProducts', error);
      },
    },
  };
  return handleUseQuery(options);
};
