import React, { useEffect } from 'react';
import { Button, ImageAtoms, Svg } from '@vfit/shared/atoms';
import { purify } from '@vfit/shared/themes';
import {
  AppMobile,
  errorMock,
  errorManager,
  ErrorService,
  setTrackLink,
  setTrackView,
  useTracking
} from '@vfit/shared/data-access';
import {
  IActivableProduct,
  IActivationRequest,
  ProductType,
  usePostActivation,
} from '@vfit/consumer/data-access';
import * as S from './activationLandingModal.style';
import { IActivationLandingModal } from './activationLandingModal.models';
import {
  getTaggingActivationLanding,
  trackActivationLanding,
} from './activationLandingModal.utils';

const ActivationLandingModal: React.FC<IActivationLandingModal> = ({
  activationData,
  offer,
  activationLandingAEMData: cmsObject,
  onClose,
}) => {
  const {
    isLoading: isLoadingActivation,
    mutate: activationMutation,
    error: errorPostActivation,
    isSuccess,
    isError,
  } = usePostActivation();
  const [
    pTitle,
    pDescription,
    pSubDescription,
    pActiveLabel,
    pCancelLabel,
    pSuccessTitle,
    pSuccessDescription,
    pErrorTitle,
    pErrorDescription,
    pErrorButton,
    pLoadingTitle,
    pLoadingDescription,
  ] = purify([
    cmsObject?.activate?.title || '',
    cmsObject?.activate?.description || '',
    cmsObject?.activate?.subDescription || '',
    cmsObject?.activate?.activeLabel || '',
    cmsObject?.activate?.cancelLabel || '',
    cmsObject?.successModal?.title || '',
    cmsObject?.successModal?.description || '',
    cmsObject?.errorModal?.title || '',
    cmsObject?.errorModal?.description || '',
    cmsObject?.errorModal?.buttonLabel || '',
    cmsObject?.loadingModal?.title || '',
    cmsObject?.loadingModal?.description || '',
  ]);
  const msisdn = activationData?.item || '';
  const tagging = getTaggingActivationLanding(offer || undefined);
  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'confirm',
    opts: {
      ...tagging.opts,
      journey_name: 'activation',
      ...(tagging?.trackingProduct && { ...tagging.trackingProduct }),
      ...(tagging?.pageProductsInfo && { ...tagging.pageProductsInfo }),
      product_quantity: '1',
      product_target_segment: 'consumer',
    },
  });

  setTrackView(trackView);
  setTrackLink(trackView);

  const onActiveLanding = () => {
    const allProductsSection: IActivableProduct[] = activationData?.products || [];
    let activableProduct: IActivableProduct | undefined;
    allProductsSection?.forEach((e) => {
      const allBOIds = e?.campaignDetail?.components?.billingOffer || [];
      if (allBOIds) {
        const ids = allBOIds?.map((aBoId: { id: string }) => aBoId.id);
        if (ids?.includes(offer?.id?.toString()) && !activableProduct) {
          activableProduct = e;
        }
      }
    });
    if (!activableProduct) {
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: 'Ops!',
        message: 'Si è verificato un errore. Riprova in seguito',
        actionText: 'Chiudi',
        actionEvent: () => window.location.reload(),
        onClose: () => window.location.reload(),
      });
      return;
    }
    const activationBody: IActivationRequest = {
      payload: {
        id: activableProduct.id,
        ...(activableProduct?.name && { name: activableProduct?.name }),
        ...(activableProduct?.type && { type: activableProduct?.type }),
        ...(activableProduct?.serviceId && { serviceId: activableProduct?.serviceId }),
        ...(activableProduct?.activationDate && {
          activationDate: activableProduct?.activationDate,
        }),
        ...(activableProduct?.campaignDetail?.components && {
          components: activableProduct?.campaignDetail?.components,
        }),
      },
      queryString: {
        msisdn,
        productType: ProductType.NBA,
      },
    };
    activationMutation(activationBody);
  };

  useEffect(() => {
    trackActivationLanding(
      offer,
      isSuccess,
      isLoadingActivation,
      isError,
      errorPostActivation ? errorMock('postActivation', errorPostActivation) : undefined
    );
  }, [isSuccess, isError, isLoadingActivation]);

  const getDefaultLanding = () => (
    <>
      <S.HeaderContainer>
        <S.Title>
          {pTitle
            ?.replace('$PHONE_NUMBER', msisdn || '')
            ?.replace('$OFFER_NAME', offer?.title || '') || ''}
        </S.Title>
        <S.CloseIcon onClick={onClose}>
          <Svg name="close" color="#0d0d0d" width={13} />
        </S.CloseIcon>
      </S.HeaderContainer>
      <S.Description
        dangerouslySetInnerHTML={{
          __html:
            pDescription
              ?.replace('$PHONE_NUMBER', msisdn || '')
              ?.replace('$OFFER_NAME', offer?.title || '') || '',
        }}
      />
      <S.Description
        dangerouslySetInnerHTML={{
          __html:
            pSubDescription?.replace?.('€', '')?.replace('$OFFER_PRICE', offer?.price || '') || '',
        }}
      />
      <S.ButtonContainer>
        <Button onClick={onActiveLanding} variant="mva:primary" style={{ marginBottom: 16 }}>
          <span className={`${AppMobile.checkIsApp() ? 'capitalize-first' : ''}`}>
            {pActiveLabel}
          </span>
        </Button>
        <Button onClick={onClose} variant="mva:alt1">
          <span className={`${AppMobile.checkIsApp() ? 'capitalize-first' : ''}`}>
            {pCancelLabel}
          </span>
        </Button>
      </S.ButtonContainer>
    </>
  );

  const getLoadingLanding = () => (
    <S.SecondContainer>
      <ImageAtoms image={cmsObject?.loadingModal?.icon} nameCard="loading-icon" width="120px" />
      <S.SecondaryTitle>{pLoadingTitle}</S.SecondaryTitle>
      <S.SecondaryDescription
        dangerouslySetInnerHTML={{
          __html: pLoadingDescription || '',
        }}
      />
    </S.SecondContainer>
  );

  const getSuccessLanding = () => (
    <S.SecondContainer>
      <ImageAtoms image={cmsObject?.successModal?.icon} nameCard="success-icon" width="120px" />
      <S.SecondaryTitle>
        {pSuccessTitle
          ?.replace('$PHONE_NUMBER', msisdn || '')
          ?.replace('$OFFER_NAME', offer?.title || '') || ''}
      </S.SecondaryTitle>
      <S.SecondaryDescription
        dangerouslySetInnerHTML={{
          __html:
            pSuccessDescription
              ?.replace('$PHONE_NUMBER', msisdn || '')
              ?.replace('$OFFER_NAME', offer?.title || '') ||
            '' ||
            '',
        }}
      />
    </S.SecondContainer>
  );

  const getErrorLanding = () => (
    <S.SecondContainer>
      <ImageAtoms image={cmsObject?.errorModal?.icon} nameCard="error-icon" width="120px" />
      <S.SecondaryTitle>{pErrorTitle}</S.SecondaryTitle>
      <S.SecondaryDescription
        dangerouslySetInnerHTML={{
          __html: pErrorDescription || '',
        }}
      />
      <S.ButtonContainer>
        <Button onClick={onClose} variant="mva:primary" style={{ marginBottom: 16 }}>
          <span className={`${AppMobile.checkIsApp() ? 'capitalize-first' : ''}`}>
            {pErrorButton}
          </span>
        </Button>
      </S.ButtonContainer>
    </S.SecondContainer>
  );

  return (
    <S.Container isApp={AppMobile.checkIsApp()}>
      {isLoadingActivation && getLoadingLanding()}
      {!isLoadingActivation && isSuccess && getSuccessLanding()}
      {!isLoadingActivation && isError && getErrorLanding()}
      {!isLoadingActivation && !isSuccess && !isError && getDefaultLanding()}
    </S.Container>
  );
};

export default ActivationLandingModal;
