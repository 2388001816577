import { awsConsumerClient, INextError, LoggerInstance } from '@vfit/shared/data-access';
import {
  API,
  AppMobile,
  checkWindow,
  getFromLocalStorageByKey,
} from '@vfit/shared/data-access';
import { useMutation } from 'react-query';
import { IActivationPayload, IActivationRequest } from './postActivation.model';
import { retrieveUserAgent } from '../../../utils/app';
import { IAppSession } from '../../dxl-app-integration';

const postActivation = (
  payload: IActivationPayload,
  msisdn: string,
  productType: string,
  headers?: { [key: string]: string }
): Promise<unknown> =>
  awsConsumerClient.post(`${API.DXL_ACTIVATE_PRODUCTS}`, payload, {
    headers: {
      ...(Object.keys(headers)?.length > 0 && {
        ...headers,
      }),
    },
    searchParams: {
      sim: msisdn,
      productType,
    },
  });

export const usePostActivation = () =>
  useMutation(
    'activateProduct',
    (request: IActivationRequest) => {
      const isApp = AppMobile.checkIsApp();
      const appSession: IAppSession =
        isApp && checkWindow() ? getFromLocalStorageByKey('appSession') : null;
      const headers = {
        'X-Device-UserAgent':
          isApp && appSession?.customerUserAgent
            ? appSession?.customerUserAgent
            : retrieveUserAgent(),
        'X-Device-Id':
          isApp && appSession?.installationId ? appSession?.installationId : request.deviceId,
      };
      return postActivation(
        request.payload,
        request.queryString.msisdn,
        request.queryString.productType,
        headers
      );
    },
    {
      onSuccess: (data: any) => {
        LoggerInstance.error('SUCCESS - activateProducts', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - activateProducts', error);
      },
    }
  );
