import { API, INextError, nextClient } from '@vfit/shared/data-access';
import { useQuery, useQueryClient } from 'react-query';
import { errorMock } from '@vfit/consumer/data-access';
import { IGetAddonResponse } from './getAddon.models';

const getAddonService = (cartItemId: string, cartId: string): Promise<IGetAddonResponse> =>
  nextClient.get(`${API.MULTI_PLAYING_OFFERING_CONFIGURATION}/${cartItemId}`, {
    searchParams: {
      salesChannel: 'selfService',
      context: `shoppingCart.Id==${cartId}`,
      isChangeTechOnly: false,
    },
  });

export const useGetAddons = (cartItemId: string, cartId: string, queryEnabler = false) => {
  const queryClient = useQueryClient();
  return useQuery(['getAvailableAddons'], () => getAddonService(cartItemId, cartId), {
    enabled: queryEnabler,
    onSuccess: (data: IGetAddonResponse) => {
      queryClient.setQueryData('getAvailableAddons', data);
    },
    onError: (error: INextError) => {
      queryClient.setQueryData('getAvailableAddons', errorMock('getAvailableAddons', error));
    },
  });
};
