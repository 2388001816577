import { SoftManagerService } from '@vfit/consumer/providers';
import { ENRG_QSTRING, IAppSession } from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { getFromLocalStorageByKey, setTrackLink, setTrackView, TrackingOptions, useTracking } from '@vfit/shared/data-access';

interface ITaggingBlank {
  taggingOptions?: TrackingOptions;
  isDisabledInitialTrack?: boolean;
}

const TaggingBlank = ({ taggingOptions, isDisabledInitialTrack }: ITaggingBlank) => {
  const client = useQueryClient();
  const appSession: IAppSession = getFromLocalStorageByKey('appSession');
  const queryString = new URLSearchParams(window?.location?.search);
  const msisdn256 = queryString?.get(ENRG_QSTRING.MSISDN)?.replace(/ /g, '+');
  const { trackView } = useTracking({
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: {
      ...taggingOptions,
      product_category: '',
    },
    visitorTrackingOpts: {
      ...SoftManagerService(client).getUser(),
      ...(!appSession?.value && { visitor_id_asset_active: msisdn256 }),
    },
    disableInitialTrack: isDisabledInitialTrack || false,
  });
  setTrackView(trackView);
  setTrackLink(trackView);

  return null;
};

export default TaggingBlank;
