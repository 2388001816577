import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const withProtectedWithoutCookie = () => (Component) => (props) => {
  const { page } = props || {};
  const [inputValue, setInputValue] = useState('');
  const { isProtected, protectedPass } = page?.params || {};
  const [isShowPage, setIsShowPage] = useState(!isProtected);

  const checkPass = () => {
    if (isProtected && page?.params?.key) {
      if (inputValue === protectedPass) {
        setIsShowPage(true);
      } else {
        setIsShowPage(false);
      }
    }
  };

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  if (!isShowPage)
    return (
      <div
        style={{
          position: 'absolute',
          top: '40%',
          left: '37%',
        }}
      >
        <p>Please enter password</p>
        <div>
          <input
            style={{
              border: '1x solid black',
            }}
            type="text"
            id="inputField"
            value={inputValue}
            onChange={handleInputChange}
          />
          <button onClick={checkPass}>SUBMIT</button>
        </div>
      </div>
    );

  return <Component {...props} />;
};

export { withProtectedWithoutCookie };
