import {
  CMS_CONFIG,
  IPersonalInfoCMS,
  checkIsApp,
  retrieveProductTechnology,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { LoggerInstance, useTracking } from '@vfit/shared/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { ButtonSlide } from '@vfit/shared/atoms';
import { removeCurrency } from '@vfit/consumer/hooks';
import { useQueryClient } from 'react-query';
import { SoftManagerService } from '@vfit/consumer/providers';
import { ConfirmIdentity } from '@vfit/shared/components';
import { IFindCallerObject } from '@vfit/shared/models';
import { organizeConfirmIdentityCard } from './coverageToolChooseContact.utils';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';
import { organizeCoverageToolCheckNumberCard } from '../CoverageToolCheckNumberCard/coverageToolCheckNumberCard.utils';
import { ICommonData } from '../../../StepperStateCards/stepperStateCards.models';
import { ButtonWrapper } from './coverageToolChooseContact.style';

const CoverageToolChooseContact = ({ handleGoNextSlide }: ICommonData) => {
  const queryClient = useQueryClient();
  const { findCaller, product, setFindCaller } = useCoverageToolModalProvider();

  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;

  const pageProduct = {
    product_category: 'fixed',
    product_id: `${product?.offerId}`,
    product_name: product?.slug,
    product_price: product?.price && removeCurrency(product.price),
    product_quantity: '1',
  };

  const opts = {
    event_category: 'journey',
    page_subsection: 'lock-in verify id',
    page_section: 'fixed offers',
    page_type: 'verify id',
    journey_name: 'verify lock-in',
    product_technology: retrieveProductTechnology(),
  };

  useTracking({
    event: ['journey'],
    event_label: 'verify id',
    opts,
    pageProduct,
    visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
  });

  const handleSelect = (selectedItem: { field: string; fieldType: 'msisdn' | 'email' }) => {
    setFindCaller({
      ...(findCaller as IFindCallerObject),
      selectedContactMedium: selectedItem.field?.trim() ? selectedItem : undefined,
    });
  };

  const { descriptionCms, placeholderCms, titleCms, ctaLabelCms } =
    organizeConfirmIdentityCard(dataFromCms);

  const { checkNumberProps } = organizeCoverageToolCheckNumberCard(dataFromCms);

  const cardConfig = {
    getSelectedData: handleSelect,
    identityTypeItems: [
      (findCaller as IFindCallerObject)?.individualRef?.email || '',
      (findCaller as IFindCallerObject)?.individualRef?.msisdn || '',
    ],
    title: titleCms,
    description: descriptionCms,
    placeholder: placeholderCms,
    cta: {
      label: ctaLabelCms,
      action: () => LoggerInstance.debug('click from'),
    },
  };

  const onSubmit = () => {
    if (handleGoNextSlide && (findCaller as IFindCallerObject)?.selectedContactMedium) {
      handleGoNextSlide();
    }
  };

  return (
    <>
      <ConfirmIdentity data={cardConfig} />
      <ButtonWrapper>
        <ButtonSlide
          disabled={false}
          label={checkNumberProps?.ctaLabel || 'Prosegui'}
          onClick={onSubmit}
          isApp={checkIsApp()}
          buttonColor="#e60000"
          borderColor="#e60000"
          labelColor="#fff"
          hoverColor="#bd0000"
          clickColor="#a10000"
          name="action_checkNumber"
        />
      </ButtonWrapper>
    </>
  );
};

export default CoverageToolChooseContact;
