import {
  checkPanicMode,
  CONSUMER_CMS_DEFAULT,
  evaluateOfferDetails,
  getRedirectUrl,
  getTechByKey,
  IDroppedCartCms,
  IGlobalConfigCMS,
  IGlobalPromoCMS,
  ITechs,
  retrieveCartCookie,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { HeaderWithMap } from '@vfit/shared/components';
import { ICartCookie } from '@vfit/shared/models';
import { API, isAcceptedCookieLawGroup, checkWindow } from '@vfit/shared/data-access';
import { MainContainer } from './droppedCart.style';
import { evaluateCmsData, overrideSpecs } from './droppedCart.utils';
import { IDroppedCart } from './droppedCart.models';

const DroppedCart = ({ productsAll }: IDroppedCart) => {
  const cookieCart = retrieveCartCookie() as ICartCookie;
  const address = cookieCart?.address;
  const technology = cookieCart?.technology;
  const displayedTechnology = technology?.displayedTecnology;
  const userType = cookieCart?.userType;
  const placeId = cookieCart?.placeId;
  const offerId = cookieCart?.offerId;
  const cmsId = cookieCart?.cmsId || '';
  const isLockinProduct = cookieCart?.offerDetails?.isLockinProduct || false;
  const basePathRedirect = cookieCart?.offerDetails?.redirectUrl || '';
  const ctaRedirectUrl = getRedirectUrl(
    basePathRedirect,
    placeId || '',
    offerId || 0,
    cmsId,
    isLockinProduct
  );
  const { globalconfig } =
    (useCmsConfig(CONSUMER_CMS_DEFAULT, API.CMS_GET_GLOBAL_CONFIG) as IGlobalConfigCMS) || {};
  const { globalpromo } =
    (useCmsConfig(CONSUMER_CMS_DEFAULT, API.CMS_GET_GLOBAL_PROMO) as IGlobalPromoCMS) || {};
  const { panicMode } = globalconfig || {};
  const isPanicMode = checkPanicMode(
    panicMode,
    productsAll?.find((p) => p?.cmsId === cookieCart?.cmsId)
  );
  if (checkWindow() && panicMode) {
    (window as any).isPanicMode = isPanicMode;
  }

  const allTechs = useCmsConfig(CONSUMER_CMS_DEFAULT, API.CMS_GET_TECNOLOGIES) as ITechs;

  const droppedCartCMS = useCmsConfig(
    CONSUMER_CMS_DEFAULT,
    API.CMS_GET_DROPPED_CARD
  ) as IDroppedCartCms;

  const isAcceptedPrivacyPolicy = isAcceptedCookieLawGroup('4');
  const cmsRawData = displayedTechnology
    ? getTechByKey(allTechs, displayedTechnology)
    : droppedCartCMS;
  const cmsRefinedData = evaluateCmsData(
    cmsRawData,
    address,
    technology,
    userType,
    isLockinProduct
  );
  const offerDetails = evaluateOfferDetails(productsAll, cookieCart?.productId, isPanicMode);
  const isOkPlaceId = placeId && placeId !== 'undefined';
  const productHasGlobalPromo = productsAll?.find(
    (p) => p.cmsId === cookieCart?.cmsId
  )?.isGlobalPromoActive;

  const showCookieCart = Boolean(cookieCart) && isAcceptedPrivacyPolicy && isOkPlaceId;

  if (showCookieCart) {
    return (
      <MainContainer>
        <HeaderWithMap
          cmsData={
            productHasGlobalPromo
              ? {
                  ...cmsRefinedData,
                  specs: overrideSpecs(cmsRefinedData.specs, {
                    value: globalpromo.droppedCartLabel,
                  }),
                }
              : cmsRefinedData
          }
          address={address}
          offerDetails={offerDetails}
          ctaRedirectUrl={ctaRedirectUrl}
          userType={userType}
        />
      </MainContainer>
    );
  }
  return null;
};

export default DroppedCart;
