import {
  API,
  getFromLocalStorageByKey,
  INextError,
  LoggerInstance,
  nextClient,
} from '@vfit/shared/data-access';
import { useMutation, useQueryClient } from 'react-query';
import { EndpointsEnum, errorMock, IOfferingType } from '@vfit/consumer/data-access';
import { buildAdditionPayload, buildRemovalPayload } from './postAddon.utils';
import { IUseAddonMutation } from './postAddon.models';

const fixedVoiceService = (
  endpoint: EndpointsEnum,
  offeringId: string,
  payload: unknown
): Promise<unknown> =>
  nextClient.post(`${API.FIXED_VOICE_OFFERING_CONFIGURATION}/${offeringId}/${endpoint}`, payload);

const internetService = (
  endpoint: EndpointsEnum,
  offeringId: string,
  payload: unknown
): Promise<unknown> =>
  nextClient.post(`${API.INTERNET_OFFERING_CONFIGURATION}/${offeringId}/${endpoint}`, payload);

const dataSimService = (
  endpoint: EndpointsEnum,
  offeringId: string,
  payload: unknown
): Promise<unknown> =>
  nextClient.post(`${API.DATA_SIM_OFFERING_CONFIGURATION}/${offeringId}/${endpoint}`, payload);

export const useAddAddon = () => {
  const queryClient = useQueryClient();
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartItem = shoppingCart?.cartItem?.[0];
  return useMutation(
    'addAddon',
    // eslint-disable-next-line consistent-return
    ({ cartId, addonId, type }: IUseAddonMutation) => {
      switch (type) {
        case IOfferingType.FIXED_VOICE: {
          const payloadFixedVoice = buildAdditionPayload(cartId, addonId, type);
          const fixedVoiceId: string = cartItem?.fixedVoice?.[0]?.id;
          return fixedVoiceService(EndpointsEnum.ADD_FIXED_VOICE, fixedVoiceId, payloadFixedVoice);
        }
        case IOfferingType.INTERNET: {
          const payloadInternet = buildAdditionPayload(cartId, addonId, type);
          const internetId: string = cartItem?.internet?.[0]?.id;
          return internetService(EndpointsEnum.ADD_INTERNET, internetId, payloadInternet);
        }
        case IOfferingType.SIM_DATA: {
          const payloadSimData = buildAdditionPayload(cartId, addonId, type);
          const dataSimId = cartItem?.dataSim?.[0].id;
          return dataSimService(EndpointsEnum.ADD_DATA_SIM, dataSimId, payloadSimData);
        }
      }
    },
    {
      onSuccess: (data: unknown) => {
        queryClient.setQueryData('addAddon', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - addAddon', error);
        queryClient.setQueryData('addAddon', errorMock('addAddon', error));
      },
    }
  );
};

export const useRemoveAddon = () => {
  const queryClient = useQueryClient();
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartItem = shoppingCart?.cartItem?.[0];

  return useMutation(
    'removeAddon',
    // eslint-disable-next-line consistent-return
    ({ cartId, addonId, type }: IUseAddonMutation) => {
      switch (type) {
        case IOfferingType.FIXED_VOICE: {
          const payloadFixedVoice = buildRemovalPayload(cartId, addonId, type);
          const fixedVoiceId: string = cartItem?.fixedVoice?.[0]?.id;
          return fixedVoiceService(
            EndpointsEnum.REMOVE_FIXED_VOICE,
            fixedVoiceId,
            payloadFixedVoice
          );
        }
        case IOfferingType.INTERNET: {
          const payloadInternet = buildRemovalPayload(cartId, addonId, type);
          const internetId: string = cartItem?.internet?.[0]?.id;
          return internetService(EndpointsEnum.REMOVE_INTERNET, internetId, payloadInternet);
        }
        case IOfferingType.SIM_DATA: {
          const payloadSimData = buildRemovalPayload(cartId, addonId, type);
          const dataSimId = cartItem?.dataSim?.[0].id;
          return dataSimService(EndpointsEnum.REMOVE_DATA_SIM, dataSimId, payloadSimData);
        }
      }
    },
    {
      onSuccess: (data: unknown) => {
        queryClient.setQueryData('removeAddon', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - removeAddon', error);
        queryClient.setQueryData('removeAddon', errorMock('removeAddon', error));
      },
    }
  );
};
