import React, { useEffect } from 'react';
import {
  checkIsGoBackSIA,
  IGlobalPromoCMS,
  IProduct,
  overridePromo,
} from '@vfit/consumer/data-access';
import { getHome } from '@vfit/shared/data-access';
import { CheckoutProvider, useCheckout } from './iBuyFixed.context';
import ShoppingCart from './ShoppingCart/shoppingCart';
import { ENABLE_DEBUG, productNotFound, retrieveProduct } from './iBuyFixed.utils';
import { IIBuyFixed } from './iBuyFixed.models';
import { useQueryClient } from 'react-query';

const ShoppingCartOffer = ({ isSuccessDynamicCart, isErrorDynamicCart }: IIBuyFixed) => {
  const queryClient = useQueryClient();
  const { product, setProduct } = useCheckout();
  const { globalpromo } = (queryClient?.getQueryData('getGlobalPromo') || {}) as IGlobalPromoCMS;

  useEffect(() => {
    const productInPage = retrieveProduct(!checkIsGoBackSIA()) as IProduct;
    if (!productInPage) {
      productNotFound();
      setTimeout(() => {
        if (!ENABLE_DEBUG) window.location.href = `${getHome()}`;
      }, 1000);
    } else {
      setProduct({ ...overridePromo(productInPage, globalpromo) });
    }
  }, []);

  if (!product || Object.keys(product)?.length === 0) return null;

  return (
    <ShoppingCart
      isSuccessDynamicCart={isSuccessDynamicCart}
      isErrorDynamicCart={isErrorDynamicCart}
    />
  );
};

const IBuyFixed = ({ isSuccessDynamicCart, isErrorDynamicCart }: IIBuyFixed) => (
  <CheckoutProvider>
    <ShoppingCartOffer
      isSuccessDynamicCart={isSuccessDynamicCart}
      isErrorDynamicCart={isErrorDynamicCart}
    />
  </CheckoutProvider>
);

export default IBuyFixed;
