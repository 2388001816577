import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToCssFontSize,
  pxToRem,
} from '@vfit/shared/themes';

export const SlideProductContainer = styled.div`
  position: relative;
  min-height: 344px;

  @media (min-width: ${breakpoints.tablet}) {
    // min-height: 800px;
    min-height: 680px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: inherit;
  }
`;

export const DivImgContainer = styled.div<{ absolute: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: 0 0 20px 20px;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? 0 : '')};
  left: ${(props) => (props.absolute ? 0 : '')};

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 30px 30px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    border-radius: 0 0 30px 30px;
  }
`;

export const SlideProductContainerVideo = styled.div`
  height: calc(100vh - 250px);
  max-height: 80vh;
  border-radius: 0 0 30px 30px;
  position: relative;

  div {
    position: absolute;
    width: 100%;
  }

  div::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(90deg, rgba(62, 62, 62, 6%) 17.03%, rgba(0, 0, 0, 0%) 83.87%);

    @media (min-width: ${breakpoints.tablet}) {
      border-radius: 0 0 30px 30px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      border-radius: 0 0 30px 30px;
    }
  }

  video {
    object-fit: cover;
    width: 100%;
    height: calc(100vh - 250px);

    @media (min-width: ${breakpoints.tablet}) {
      object-fit: cover;
      border-radius: 0 0 30px 30px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      object-fit: cover;
      border-radius: 0 0 30px 30px;
    }
  }
`;

export const SlideProductNavigation = styled.div<{ isHeaderLiteMode?: boolean }>`
  margin-top: ${({ isHeaderLiteMode }) => (isHeaderLiteMode ? '70px' : '24px')};
  padding-left: 30px;
  margin-bottom: 28px;
  display: flex;
  align-items: center;

  .back {
    width: inherit;
    display: flex;
    cursor: pointer;
    margin: 0;
    appearance: none;
    background: none;
    border: none;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
  }

  div {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 9px;
      height: 16px;

      path {
        stroke: white;
      }
    }
  }

  span {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(16)}
    color: white;
    margin-left: 15px;
  }
  @media (min-width: ${breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 67px;
    padding-left: 65px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: ${({ isHeaderLiteMode }) => (isHeaderLiteMode ? '100px' : '56px')};
    padding-left: 80px;
    margin-bottom: inherit;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const SlideProductContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SlideProductContentTop = styled.div`
  width: 100%;
  flex: 0.8;

  @media (min-width: ${breakpoints.tablet}) {
    flex: 0.9;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex: 0.8;
  }
`;

export const SlideProductContentBottom = styled.div`
  width: 100%;
  flex: 0.2;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  @media (min-width: ${breakpoints.tablet}) {
    padding-bottom: 60px;
    flex: 0.1;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-bottom: 60px;
    flex: 0.2;
  }
`;

export const SlideProductButtonSlideContainer = styled.div<{ isApp: boolean }>`
  width: 324px;
  max-width: 400px;
  padding-bottom: 25px;

  div {
    width: 324px;
    max-width: 400px;
    span {
      display: ${({ isApp }) => (isApp ? 'inline-block' : '')};
      text-transform: ${({ isApp }) => (isApp ? 'lowercase' : '')};
      font-weight: ${({ isApp }) => (isApp ? '400' : '')};
      font-size: ${({ isApp }) => (isApp ? '16px' : '')};
    }
    span::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const SlideProductText = styled.div<{ disablePaddingTop?: boolean }>`
  .capitalize-first {
    text-transform: lowercase;
  }
  .capitalize-first::first-letter {
    text-transform: uppercase;
  }

  h2 {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(16, 24)}
    letter-spacing: ${pxToRem(0.5)};
    text-transform: uppercase;
    color: ${colors.$ffffff};
    margin: 0 30px;
  }

  h3 {
    font-family: ${fonts.exbold};
    font-weight: 400;
    ${pxToCssFont(42, 40)}
    color: ${colors.$ffffff};
    margin: 21px 30px 0;
    max-width: none;
  }

  h4 {
    font-family: ${fonts.exbold};
    ${pxToCssFont(24, 28)}
    font-weight: 400;
    color: ${colors.$f2f2f2};
    margin: 30px 30px 0;
    max-width: none;
  }

  h5 {
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}
    font-weight: 700;
    color: ${colors.$bebebe};
    margin: 8px 30px 0;
    max-width: none;
  }

  div {
    button {
      margin-left: 30px;
    }
  }

  .text {
    div {
      font-family: ${fonts.regular};
      ${pxToCssFont(18, 24)}
      font-weight: 400;
      color: ${colors.$f2f2f2};
      margin: 8px 30px 0;
      max-width: none;
    }

    span {
      font-weight: 400;
      color: ${colors.$ffffff};
      align-items: baseline;
    }
  }

  .text-app {
    div {
      font-family: ${fonts.regular};
      ${pxToCssFont(18, 24)}
      font-weight: 400;
      color: ${colors.$f2f2f2};
      margin: 8px 30px 0;
      max-width: none;
    }

    span {
      font-family: ${fonts.regular};
      font-weight: 700;
      color: ${colors.$ffffff};
      align-items: baseline;
    }
  }

  .price {
    h4 {
      margin: inherit;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding-left: 32px;
    padding-top: ${({ disablePaddingTop }) => (disablePaddingTop ? '7px' : '61px')};

    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${pxToCssFont(64, 80)}
      max-width: 620px;
    }

    h4 {
      ${pxToCssFont(30, 38)}
    }

    h5 {
      ${pxToCssFont(20, 30)}
      max-width: 70%;
    }

    .text {
      div {
        ${pxToCssFont(14, 18)}
        max-width: 70%;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-left: 50px;
    padding-top: ${({ disablePaddingTop }) => (disablePaddingTop ? '7px' : '56px')};

    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${pxToCssFont(70, 65)}
      max-width: 670px;
    }

    h4 {
      ${pxToCssFont(42, 52)}
    }

    h5 {
      ${pxToCssFont(20, 30)}
      max-width: 70%;
    }

    .text {
      div {
        ${pxToCssFont(18, 24)}
        max-width: 70%;
      }
    }
  }
`;

export const SlideButtons = styled.div`
  display: flex;
  gap: 30px;
  padding-bottom: 30px;
`;

export const TargetContainer = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  display: flex;
  height: 32px;
  width: fit-content;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 4px 16px 4px 4px;
  gap: 8px;
  flex-direction: row-reverse;

  @media (min-width: ${breakpoints.tablet}) {
    height: 34px;
    margin-left: auto;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
    margin-right: 80px;
    margin-left: auto;
  }

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.6;
  }
`;

export const TargetText = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 10px;
  line-height: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const CircleButton = styled.div`
  border-radius: 50%;
  border: hidden;
  min-width: 26px;
  min-height: 26px;
  background-color: #e60000;
  color: #ffffff;
`;

export const OverlayMain = styled.div`
  padding: 24px 0 0 0;
  display: flex;
  flex-direction: column;
`;

export const OverlayTitle = styled.div`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const OverlayBody = styled.div`
  font-family: ${fonts.regular};
`;

export const ButtonOverlayContainer = styled.div`
  margin: 48px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;
  width: fit-content;
`;

export const Title = styled.h3<{ text: string }>`
  font-size: ${(props) => {
    if (props.text.length < 25) {
      return '52px';
    }
    if (props.text.length < 50) {
      return '42px';
    }
    return '32px';
  }}!important;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 70px !important;
  }
`;

export const TitleApp = styled.div<{ text: string }>`
  font-family: ${fonts.regular};
  font-weight: 700;
  font-size: ${(props) => {
    if (props.text.length < 25) {
      return '52px';
    }
    if (props.text.length < 50) {
      return '42px';
    }
    return '32px';
  }};
  color: ${colors.$ffffff};
  margin: 21px 30px 0;
  max-width: none;
`;

export const LandingTitle = styled.div<{ text: string }>`
 font-family: ${fonts.regular};
 font-size: ${(props) => {
    if (props.text.length < 25) {
      return '52px';
    }
    if (props.text.length < 50) {
      return '42px';
    }
    return '32px';
  }};
  color: ${colors.$ffffff};
  margin: 21px 30px 0;
  max-width: none;
`;

export const PillElementApp = styled.div`
  background: ${colors.$fecb00};
  margin-left: 30px;
  margin-bottom: 21px;
  border-radius: 13px;
  width: fit-content;
  padding: 4px 12px;
  span {
    color: ${colors.$0d0d0d};
    align-items: center;
    font-weight: 700;
    ${pxToCssFont(14, 18)}
  }
`;
