import { useEffect, useState } from 'react';
import { checkWindow, errorManager, ErrorService } from '@vfit/shared/data-access';
import { ENRG_QSTRING } from '@vfit/consumer/data-access';

export function usePostAppOfferFlow() {
  const [isLoading, setIsLoading] = useState(true);
  const [isEligible, setIsEligible] = useState(false);

  const getPostData = () => {
    const queryString = new URLSearchParams(window?.location?.search);
    const m = queryString?.get(ENRG_QSTRING.MSISDN)?.replace(/ /g, '+');
    const a = queryString?.get(ENRG_QSTRING.ACCOUNT)?.replace(/ /g, '+');
    const c = queryString?.get(ENRG_QSTRING.CHECKSUM)?.replace(/ /g, '+');
    return {
      msisdn: m,
      account: a,
      checksum: c,
    };
  };

  const manageErrorPostAppOffer = () => {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: 'Ops',
      message: 'Si è verificato un problema. Riprovare in seguito',
      actionText: 'Chiudi',
      fullscreen: true,
      disableCloseModalOnClickAction: true,
    });
  };

  const checkIsPostOffer = () => {
    if (!checkWindow()) {
      return;
    }
    const { msisdn, account } = getPostData();
    if (!msisdn || !account) {
      manageErrorPostAppOffer();
      setIsEligible(false);
      setIsLoading(false);
    } else {
      setIsEligible(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkIsPostOffer();
  }, []);

  return {
    isLoading,
    isEligible,
    getPostData,
  };
}
