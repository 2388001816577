import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  useCmsConfig,
  useValidateAddressMutation,
  ICoverageToolCongifg,
  retrieveProduct,
  getCompleteAddressValidation,
  checkIsApp,
  CONSUMER_CMS_DEFAULT,
  organizeEditAddressFixed,
} from '@vfit/consumer/data-access';
import { API, setTrackLink, trackLink } from '@vfit/shared/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { getPlaceId, useDeviceType } from '@vfit/shared/hooks';
import { LoggerInstance, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { ButtonSlide, Divider, CustomText } from '@vfit/shared/atoms';
import { Form } from '@vfit/shared/components';
import { fonts } from '@vfit/shared/themes';
import { Title } from './coverageTool.style';
import {
  CoverageToolContainer,
  CoverageToolContent,
  CoverageToolContentButtonAuto,
} from './coverageToolContainers.style';
import { ICoverageToolProps, IGeoAddressElab, IInputs } from './coverageTool.models';
import {
  checkAddressFromValue,
  checkCoverageToolType,
  composeAddress,
  getAddressFromLatLng,
  retrieveAddress,
} from './coverageTool.utils';
import { useCoverageToolModalProvider } from '../CoverageToolModal/coverageToolModal.context';
import { IEditAddressCmsConfig } from '@vfit/shared/models';

const CoverageToolAuto = (props: ICoverageToolProps) => {
  const product = retrieveProduct();
  const client = useQueryClient();

  // Product for tracking.
  const pageProduct = {
    product_name: product?.slug,
    product_price: product?.price && removeCurrency(product.price),
    product_id: `${product?.offerId}`,
    product_quantity: '1',
    product_category: ['fixed'],
  };

  const opts = {
    event_category: 'tools',
    page_subsection: 'coverage tool',
    page_section: 'fixed offers',
    page_type: 'address',
    journey_name: 'coverage tool',
  };

  const { trackView } = useTracking({
    event: ['tool_start'],
    opts,
    pageProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });
  setTrackLink(trackView);

  const { isDesktop } = useDeviceType();
  const mutation = useValidateAddressMutation();
  const { setIsForcedManual } = useCoverageToolModalProvider();
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [region, setRegion] = useState<string>('');
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoadingGeolocation, setIsLoadingGeolocation] = useState<boolean>(false);
  const coverageToolConfig = useCmsConfig(
    CONSUMER_CMS_DEFAULT,
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;
  const editAddressStepper = useCmsConfig(
    CONSUMER_CMS_DEFAULT,
    API.CMS_GET_EDIT_ADDRESS
  ) as IEditAddressCmsConfig;
  const {
    labels: { addressInputPlaceHolderAutocomplete },
  } = organizeEditAddressFixed(editAddressStepper?.editaddressconfig);
  const schema = getCompleteAddressValidation(coverageToolConfig);
  const errorMessageGeolocalizzation =
    coverageToolConfig?.coveragetool?.geolocalization?.alertError || '';

  const defaultValues = {
    ...props.state,
  };
  let initialState = {
    placeId: false,
    streetNumber: true,
  };
  if (props.state?.street !== '' && props.state?.streetNumber !== '') {
    initialState = {
      placeId: false,
      streetNumber: false,
    };
  }

  const [hiddenFields, setHiddenFields] = useState(initialState);
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  });

  useEffect(() => {
    mutation.reset();
    setIsFormDisabled(false);
    setIsForcedManual(false);
  }, []);

  // eslint-disable-next-line arrow-body-style
  const GetAddressObjectFromPlaceId = async (placeId: string) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await getPlaceId(placeId);
  };

  const setAddressObject = async (placeId: string | null, text?: string) => {
    //  Only if there is placeId
    if (placeId) {
      const addressObj = await GetAddressObjectFromPlaceId(placeId as string);
      //  Only if getAddressObject retireve an address object
      if (addressObj) {
        //  Shows street number text input at the first step if no retrievable from Google API placeId
        if (!addressObj?.streetNumber)
          setHiddenFields((prevState) => ({
            ...prevState,
            streetNumber: false,
          }));
        else {
          setValue('streetNumber', addressObj.streetNumber);
          await trigger(['streetNumber']);
        }
        //  sets all the other fields
        setValue('placeId', placeId);
        if (addressObj.city) setValue('city', addressObj.city);
        if (addressObj.postalCode) setValue('postalCode', addressObj.postalCode);
        if (addressObj.street) setValue('street', addressObj.street);
        if (addressObj.stateOrProvince) setValue('stateOrProvince', addressObj.stateOrProvince);
        setLatitude(addressObj.latitude);
        setLongitude(addressObj.longitude);
        setRegion(addressObj.region || '');
        await trigger(['city', 'postalCode', 'street', 'stateOrProvince']);
      }
      const inputs = getValues();

      const mutateLocation = {
        ...inputs,
        latitude: parseFloat(addressObj?.latitude || latitude),
        longitude: parseFloat(addressObj?.longitude || longitude),
      };

      mutation.mutate(mutateLocation);
    } else if (!placeId && !!text) {
      //  If placeId === null, it uses the input string 'text' to get the address object
      //  instead of the placeId. It uses useAddressGeocoder()
      const addressGeocoder = await checkAddressFromValue(text);

      if (addressGeocoder) {
        //  Check if with address from addrString + streetNumber can get all the fields.
        //  if not, it sets isManual to true and show all the form fields
        setLatitude(addressGeocoder.latitude);
        setLongitude(addressGeocoder.longitude);

        //  if all fields retrieved, it sets all of them
        if (addressGeocoder.placeId) setValue('placeId', addressGeocoder.placeId);
        if (addressGeocoder.streetNumber) setValue('streetNumber', addressGeocoder.streetNumber);
        if (addressGeocoder.city) setValue('city', addressGeocoder.city);
        if (addressGeocoder.postalCode) setValue('postalCode', addressGeocoder.postalCode);
        if (addressGeocoder.street) setValue('street', addressGeocoder.street);
        if (addressGeocoder.stateOrProvince)
          setValue('stateOrProvince', addressGeocoder.stateOrProvince);

        await trigger();

        const inputs = getValues();

        const mutateLocation = {
          ...inputs,
          latitude: addressGeocoder.latitude ? parseFloat(addressGeocoder.latitude) : 0,
          longitude: addressGeocoder.longitude ? parseFloat(addressGeocoder.longitude) : 0,
        };
        mutation.mutate(mutateLocation);
        //  if (props.onSubmit) props.onSubmit(mutateLocation, checkCoverageToolType(mutateLocation))
      }
    }
  };
  const placeId = watch('placeId');
  const streetNumber = watch('streetNumber');

  useEffect(() => {
    //  first call of setAddressObject using placeId if no street number setted
    if (placeId && !streetNumber && hiddenFields.streetNumber === true) {
      // @ts-ignore
      setAddressObject(placeId).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
    //  to handle abling/disabling button
    if (streetNumber && !errors.streetNumber && placeId) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [placeId, streetNumber, hiddenFields.streetNumber, setAddressObject, setValue]);

  useEffect(() => {
    if (!placeId) {
      setValue('streetNumber', '');
    }
  }, [placeId]);

  const onIconClick = () => {
    reset();
    setHiddenFields({
      placeId: false,
      streetNumber: true,
    });
  };

  const onConfirm = async (input: IInputs, e: any) => {
    e.preventDefault();
    const mutateLocation = {
      ...input,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    };

    mutation.mutate(mutateLocation);
    //  if (props.onSubmit) props.onSubmit(mutateLocation, checkCoverageToolType(mutateLocation));
  };

  const handleFirstClick = async () => {
    if (placeId && streetNumber && hiddenFields.streetNumber === false) {
      const inputs = getValues();
      const formattedAddress = retrieveAddress({
        city: inputs.city,
        postalCode: inputs.postalCode,
        stateOrProvince: inputs.stateOrProvince,
        street: inputs.street,
        streetNumber,
      });
      await setAddressObject(null, formattedAddress).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
    const updatedInputs = getValues();

    const mutateLocation = {
      ...updatedInputs,
      latitude: latitude ? parseFloat(latitude) : 0,
      longitude: longitude ? parseFloat(longitude) : 0,
      region,
    };

    if (props.onSubmit) props.onSubmit(mutateLocation, checkCoverageToolType(mutateLocation));
  };

  const findPosition = async (p: GeolocationPosition) => {
    trackLink('click geolocalization');
    return new Promise((resolve) => {
      getAddressFromLatLng(p?.coords?.latitude, p?.coords?.longitude)
        .then((value: any) => {
          let address: IGeoAddressElab = {
            street: '',
            city: '',
            province: '',
            postalCode: '',
          };
          if (value !== undefined) {
            setHiddenFields((prevState: any) => ({
              ...prevState,
              streetNumber: false,
            }));
            address = composeAddress(value);
          }
          resolve(address);
          if (address.latitude) setLatitude(address.latitude);
          if (address.longitude) setLongitude(address.longitude);
          if (address.streetNumber) setValue('streetNumber', address.streetNumber);
          if (address.postalCode) setValue('postalCode', address.postalCode);
          if (address.province) setValue('stateOrProvince', address.province.toUpperCase());
          if (address.city) setValue('city', address.city.toUpperCase());
          if (address.street) setValue('street', address.street);
          if (address.placeId) setValue('placeId', address.placeId);
          if (address.region) setRegion(address.region || '');
        })
        .catch((err) => {
          LoggerInstance.debug(err);
          // TODO: Error geolocation
        });
    });
  };

  const handleLoadingGeolocation = (isLoadingGeo: boolean) => {
    setIsLoadingGeolocation(isLoadingGeo);
  };

  return (
    <CoverageToolContainer>
      <CoverageToolContent>
        <Title>{coverageToolConfig?.coveragetool?.addressInput?.title || ' '}</Title>
        <CustomText
          text={coverageToolConfig?.coveragetool?.addressInput?.description || ' '}
          textAlign="center"
          size={20}
          lineHeight={26}
          fontFamily={fonts.regular}
          modal
        />
        <Form
          onSubmit={handleSubmit(onConfirm)}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e?.key === 'Enter') e?.preventDefault();
          }}
        >
          <Controller
            control={control}
            name="placeId"
            render={({ field: { onChange, onBlur, ref } }) => (
              <Form.AddressInput
              data-cs-mask=""
                label={coverageToolConfig?.coveragetool?.selectInput?.address || ''}
                onChange={(event) => {
                  setHiddenFields((prevState) => ({
                    ...prevState,
                    streetNumber: true,
                  }));
                  onChange(event);
                }}
                hidden={hiddenFields.placeId}
                autoFocus={isDesktop}
                autoComplete="off"
                onIconClick={onIconClick}
                onBlur={onBlur}
                ref={ref}
                disabled={isFormDisabled || isLoadingGeolocation}
                findPosition={findPosition}
                onChangeGeolocation={handleLoadingGeolocation}
                errorMessage={errorMessageGeolocalizzation}
                placeholderAutocomplete={{
                  enabled: true,
                  label: addressInputPlaceHolderAutocomplete,
                  action: () => {
                    handleFirstClick();
                    setIsForcedManual(true);
                  },
                }}
              />
            )}
          />
          <Divider marginBottom={1} hidden={hiddenFields.streetNumber} />
          <Form.TextInput
           data-cs-mask=""
            placeholder=" "
            label={coverageToolConfig?.coveragetool?.selectInput?.civicNumber || ''}
            hidden={hiddenFields.streetNumber}
            error={!hiddenFields.streetNumber ? errors.streetNumber?.message : ' '}
            autoFocus
            autoComplete="off"
            disabled={isFormDisabled}
            onError={() =>
              trackView({
                event_name: 'form_error',
                event_label_track: 'error',
                event_category: 'please complete street number',
              })
            }
            {...register('streetNumber')}
          />
        </Form>
      </CoverageToolContent>
      <CoverageToolContentButtonAuto>
        <ButtonSlide
          label={coverageToolConfig?.coveragetool?.addressInput?.button || ''}
          disabled={isDisabled}
          disabledButtonColor="#BEBEBE"
          disabledBorderColor="#BEBEBE"
          disabledTextColor="#fff"
          isApp={checkIsApp()}
          onClick={handleFirstClick}
          buttonColor="#e60000"
          labelColor="#fff"
          hoverColor="#bd0000"
          clickColor="#a10000"
          name="action_goToCheckCoverage"
        />
      </CoverageToolContentButtonAuto>
    </CoverageToolContainer>
  );
};

export default CoverageToolAuto;
