import { Children, useEffect, useRef, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { Check } from '@vfit/shared-icons';
import { useRouter } from 'next/router';
import { ButtonSlide, ImageAtoms, CustomText, Price } from '@vfit/shared/atoms';
import {
  checkIsApp,
  CMS_CONFIG,
  getRedirectCoverage,
  IN_ACTIVATION,
  retrieveInTest,
  useCmsConfig,
  usePage,
} from '@vfit/consumer/data-access';
import { useRedirectCoverageToolFlow } from '@vfit/consumer/hooks';
import { useQueryClient } from 'react-query';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  IProductLead,
  LoggerInstance,
  useTracking,
  volaModalManager,
} from '@vfit/shared/data-access';
import {
  IActionType,
  IClickToCallMeData,
  IErrorFormApi,
  IGradientImage,
  ISubmitOutput,
} from '@vfit/shared/models';
import { Inflation, Pills, VfModal } from '@vfit/shared/components';
import { fonts } from '@vfit/shared/themes';
import {
  API,
  IGetAllCmsForm,
  manageErrorLeadPlatform,
  manageSuccessLeadPlatform,
  openLeadModal,
  openPopup,
  PAGES,
} from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { CoverageToolModal } from '@vfit/consumer/components';
import {
  ModalContent,
  ModalTitle,
  ProductCardBottom,
  ProductCardBottomContainer,
  ProductCardBottomContainerText,
  ProductCardContainer,
  ProductCardOfferLabel,
  ProductCardSpecContainer,
  ProductCardSpecItem,
  ProductCardSpecItemAction,
  ProductCardSpecTitle,
  ProductCardTopImage,
  ProductCardTopImageContent,
  DivImgContainer,
  H3Header,
  H2Header,
} from './productCard.style';
import { IProductCardProps } from './productCard.models';
import ProductCardLoader from './components/ProductCardLoader/productCardLoader';

const ProductCard = ({
  noMargin = false,
  isLoading = false,
  product: originalProduct,
  large,
  zoomIn,
  onActive,
  hideActions,
  setSpecHeight,
  classKey,
  showCase,
  onTrack,
  cardIndex,
  haveMarketingLabel,
  isR02,
  tabName,
  hideSpecs,
  isShoppingCart,
}: IProductCardProps) => {
  const { page } = usePage();
  const { asPath, push } = useRouter();
  const swiper = useSwiper();
  const specRef = useRef<HTMLDivElement | null>(null);
  const isInTest = retrieveInTest() && originalProduct?.enableAbTest;
  const abTestConfiguration = originalProduct.offerDetailABTest || {};
  const product = !isInTest
    ? originalProduct
    : {
        ...originalProduct,
        ...abTestConfiguration,
      };
  const {
    title,
    label,
    image,
    price,
    originalPrice,
    recurrence,
    subprice,
    description,
    hasEmptySpace,
    action,
    hubSecondAction,
    buttons,
    specs: specsProduct,
    pills,
    slug,
    category,
    queryParamGoDetail,
    redirectR02,
    inflactionHub,
    inflactionCartView,
    isGlobalPromoActive,
    cmsId,
    offerLockInWeb,
  } = product;
  const [isShowCoverageTool, setIsShowCoverageTool] = useState(false);
  const [isInActivation] = useRedirectCoverageToolFlow(cmsId || '');
  const { isMobile } = useDeviceType();
  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });
  const detailLabel = buttons && buttons?.length > 0 ? buttons?.[0].label || '' : '';
  const queryClient = useQueryClient();
  const volaClickToCallData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_CLICK_TO_CALL_CONF
  ) as IClickToCallMeData;

  const { trackWithNewDatalayer } = useTracking({
    event: ['product_view'],
    disableInitialTrack: true,
  });

  const handlerTracking = () => {
    if (typeof cardIndex === 'number' && typeof showCase === 'number' && onTrack)
      LoggerInstance.debug('Handler track');
  };

  useEffect(() => {
    if (isMobile && swiper) {
      setTimeout(() => {
        swiper?.slideTo(0);
      }, 500);
    }
  }, [tabName]);

  useEffect(() => {
    if (!isShowCoverageTool && isInActivation) {
      setIsShowCoverageTool(true);
    }
  }, [isInActivation]);

  useEffect(() => {
    if (specRef?.current?.offsetHeight && setSpecHeight) {
      setSpecHeight(specRef.current?.offsetHeight);
    }
  }, [setSpecHeight, specRef]);

  const goDetail = () => {
    handlerTracking();
    if (slug) {
      const pathWithoutParams = asPath.split('?')[0];
      const queryParamsDetail =
        queryParamGoDetail && queryParamGoDetail?.length > 0 ? queryParamGoDetail.join('&') : null;
      let href = `${pathWithoutParams}/${slug}`;
      if (queryParamsDetail) {
        href += `?${queryParamsDetail}`;
      }
      window.location.href = href;
    }
  };

  const activeNow = () => {
    handlerTracking();
    if (onActive) {
      onActive();
      return;
    }
    let allLeads;
    let refLead;
    let lead;
    switch (action?.type) {
      case IActionType.CUSTOM_URL:
        if (isR02?.() && redirectR02) {
          window.open(redirectR02, action?.isBlank === 'true' ? '_blank' : '_self');
        } else if (action?.url) {
          window.open(action.url, action?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.CALL_ME_NOW:
        if (action?.callMeNow) openPopup(action.callMeNow);
        break;
      case IActionType.CALL_ME_NOW_SUNRISE:
        if (action?.title && action?.url) {
          volaModalManager.handleVolaModal({
            url: action?.url || '',
            cmsClickToCallData: { ...volaClickToCallData },
            taggingOption: page?.params?.elements?.taggingOptions,
            pageProduct: SoftManagerService(queryClient).getPageProduct(product),
          });
        }
        break;
      case IActionType.COVERAGE_TOOL:
        setIsShowCoverageTool(true);
        break;
      case IActionType.CHECKOUT_MODAL_MOBILE:
        localStorage.setItem('tProd', JSON.stringify(product));
        window.location.href = getRedirectCoverage();
        break;
      case IActionType.COVERAGE_TOOL_WITH_REDIRECT:
        if (action?.url && product?.cmsId) {
          const alreadyHaveParams = action.url.includes('?');
          window.location.href = alreadyHaveParams
            ? `${action.url}&${IN_ACTIVATION}=${product?.cmsId || ''}`
            : `${action.url}?${IN_ACTIVATION}=${product?.cmsId || ''}`;
        }
        break;
      case IActionType.LEAD_MODAL:
        allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
        // form name
        refLead = action?.localUrl || '';
        lead = allLeads[refLead];
        if (lead?.forms) {
          openLeadModal({
            form: lead.forms,
            onSuccess: (submitOutput: ISubmitOutput) =>
              manageSuccessLeadPlatform(submitOutput, push, queryClient),
            onError: (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
              manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient),
          });
        }
        break;
      default:
        break;
    }
  };

  const handleHubAction = () => {
    const type = hubSecondAction?.type ? +hubSecondAction.type : -1;
    let allLeads;
    let refLead;
    let lead;
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (hubSecondAction?.url)
          window.open(
            hubSecondAction.url,
            hubSecondAction?.isBlank === 'true' ? '_blank' : '_self'
          );
        break;
      case IActionType.CALL_ME_NOW:
        if (hubSecondAction?.callMeNow) openPopup(hubSecondAction.callMeNow);
        break;
      case IActionType.CALL_ME_NOW_SUNRISE:
        if (hubSecondAction?.title)
          if (hubSecondAction?.title && hubSecondAction?.url)
            volaModalManager.handleVolaModal({
              url: hubSecondAction?.url || '',
              cmsClickToCallData: { ...volaClickToCallData },
              taggingOption: page?.params?.elements?.taggingOptions,
              pageProduct: SoftManagerService(queryClient).getPageProduct(product),
            });
        break;
      case IActionType.LEAD_MODAL:
        allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
        // form name
        refLead = hubSecondAction?.localUrl || '';
        lead = allLeads[refLead];
        if (lead?.forms) {
          openLeadModal({
            form: lead.forms,
            product: SoftManagerService(queryClient).getPageProduct(product) as IProductLead,
            onSuccess: manageSuccessLeadPlatform,
            onError: manageErrorLeadPlatform,
          });
        }
        break;
      default:
        break;
    }
  };

  const hideCoverageTool = () => {
    const pageDatalayer: any = queryClient.getQueryData('pageDatalayer');
    if (pageDatalayer) {
      trackWithNewDatalayer({
        event: pageDatalayer?.event,
        event_label: pageDatalayer?.event_label,
        opts: pageDatalayer?.opts,
        pageProduct: pageDatalayer?.pageProduct,
        cartProduct: pageDatalayer?.cartProduct,
        visitorTrackingOpts: pageDatalayer?.visitorTrackingOpts,
      });
    }
    setIsShowCoverageTool(false);
  };

  const renderSpecs = () => {
    const shortCharacteristicsLockIn = offerLockInWeb?.lockInMM?.shortCharacteristicsLockIn
      ? [offerLockInWeb.lockInMM.shortCharacteristicsLockIn]
      : [];
    const specs = product?.isLockInMMProduct ? shortCharacteristicsLockIn : specsProduct;
    return (
      <div
        ref={specRef}
        className={`product-specs-${classKey}`}
        style={{
          marginBottom: 32,
        }}
      >
        {Children.toArray(
          specs &&
            specs.length > 0 &&
            specs.map((spec) => {
              const { title: specTitle, items } = spec;
              return (
                <div>
                  {specTitle && <ProductCardSpecTitle>{specTitle}</ProductCardSpecTitle>}
                  {items &&
                    items.length > 0 &&
                    Children.toArray(
                      items
                        .filter((item) => !!item.title)
                        .map((item) => (
                          <ProductCardSpecContainer>
                            <ProductCardSpecItem>
                              <div>
                                <Check />
                              </div>
                              <CustomText text={item.title} textAlign="left" />
                            </ProductCardSpecItem>
                            {item.action && (
                              <div
                                role="presentation"
                                onClick={() =>
                                  setModal({
                                    show: true,
                                    text: item.action || '',
                                    title: item?.titleOverlay || item.title || '',
                                  })
                                }
                              >
                                <ProductCardSpecItemAction>+</ProductCardSpecItemAction>
                              </div>
                            )}
                          </ProductCardSpecContainer>
                        ))
                    )}
                </div>
              );
            })
        )}
      </div>
    );
  };

  const renderInflation = () => {
    if (isShoppingCart && inflactionCartView && inflactionCartView[0]?.text) {
      return Children.toArray(
        inflactionCartView.map((infationCart) => (
          <Inflation
            InflationTitle={infationCart?.text}
            popup={infationCart?.popup}
            color={infationCart?.color}
            inflationDisclaimer={infationCart?.disclaimer}
            isGlobalPromo={isGlobalPromoActive}
          />
        ))
      );
    }
    if (inflactionHub && inflactionHub[0]?.text) {
      return Children.toArray(
        inflactionHub.map((inflation) => (
          <Inflation
            InflationTitle={inflation?.text}
            popup={inflation?.popup}
            color={inflation?.color}
            isGlobalPromo={isGlobalPromoActive}
          />
        ))
      );
    }

    return undefined;
  };

  const renderPrice = () => (
    <>
      {(price || recurrence || title) && (
        <div className="productTitle">
          <Price amount={price} recurrence={recurrence} oldAmount={originalPrice} />
        </div>
      )}
      {subprice && <h4>{subprice || '\u00A0'}</h4>}
      {description && (
        <div className="productDescription">
          <CustomText
            text={description}
            textAlign="left"
            fontFamily={fonts.regular}
            size={14}
            lineHeight={18}
          />
        </div>
      )}
      {hasEmptySpace && <div style={{ marginBottom: '60px' }} />}
      {(!isGlobalPromoActive || !isShoppingCart) && renderInflation()}
    </>
  );

  const renderButtons = () => (
    <div
      style={{
        marginBottom: 32,
      }}
    >
      {action?.type && action.title && (
        <div
          style={{
            marginBottom: 20,
          }}
        >
          <ButtonSlide
            buttonColor="#e60000"
            hoverColor="#bd0000"
            clickColor="#a10000"
            isApp={checkIsApp()}
            labelColor="#fff"
            onClick={activeNow}
            label={action.title}
            name={`action_card_${!cardIndex ? '1' : cardIndex + 1}_${
              product?.offerId?.toString() || ''
            }`}
          />
        </div>
      )}
      {hubSecondAction?.type && hubSecondAction.title && (
        <div
          style={{
            marginBottom: 20,
          }}
        >
          <ButtonSlide
            buttonColor="#fff"
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            borderColor="#0d0d0d"
            isApp={checkIsApp()}
            onClick={handleHubAction}
            label={hubSecondAction.title}
            name={`secondAction_card_${!cardIndex ? '1' : cardIndex + 1}_${
              product?.offerId?.toString() || ''
            }`}
          />
        </div>
      )}
      {detailLabel && (
        <ButtonSlide
          buttonColor="#fff"
          hoverColor="#262626"
          hoverTextColor="#fff"
          clickColor="#7e7e7e"
          clickTextColor="#fff"
          borderColor="#0d0d0d"
          isApp={checkIsApp()}
          onClick={goDetail}
          label={detailLabel}
          name={`detailAction_card_${!cardIndex ? '1' : cardIndex + 1}_${
            product?.offerId?.toString() || ''
          }`}
        />
      )}
    </div>
  );

  const clickImage = () => {
    if (!product.disableClickDetail) goDetail();
  };

  return (
    <>
      {haveMarketingLabel && (
        <ProductCardOfferLabel haveLabel={!!product.marketingLabel}>
          {product.marketingLabel && <span>{product.marketingLabel}</span>}
        </ProductCardOfferLabel>
      )}
      <ProductCardContainer noMargin={noMargin} haveOfferLabel={haveMarketingLabel}>
        {isLoading && <ProductCardLoader />}
        {!isLoading && (
          <>
            <ProductCardTopImage
              large={large}
              isClickable={!product?.disableClickDetail || !product?.isLockInMMProduct}
              onClick={clickImage}
            >
              <Pills pills={pills || []} />
              <ProductCardTopImageContent>
                <H2Header>{category}</H2Header>
                <H3Header>{title}</H3Header>
              </ProductCardTopImageContent>
              <DivImgContainer absolute zoomIn={zoomIn}>
                <ImageAtoms
                  gradient={IGradientImage.GRADIENT_BOTTOM_40}
                  nameCard="topImage"
                  image={image}
                />
              </DivImgContainer>
            </ProductCardTopImage>
            <ProductCardBottom>
              <ProductCardBottomContainer>
                <ProductCardBottomContainerText>
                  {isMobile && label && <h6>{label}</h6>}
                  {!isMobile && label && <h6>{label || '\u00A0'}</h6>}
                  {renderPrice()}
                </ProductCardBottomContainerText>
                <div>{!hideActions && renderButtons()}</div>
                <div>{!hideSpecs && renderSpecs()}</div>
                <div>{isGlobalPromoActive && isShoppingCart && renderInflation()}</div>
              </ProductCardBottomContainer>
            </ProductCardBottom>
          </>
        )}
        <VfModal
          height={563}
          isOpen={modal.show}
          handleClose={() => setModal({ show: false, text: '', title: '' })}
        >
          <>
            {modal.title && (
              <ModalTitle>
                <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
              </ModalTitle>
            )}
            <ModalContent>
              <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
            </ModalContent>
          </>
        </VfModal>
        <CoverageToolModal
          backgroundImage={product.image}
          isOpen={isShowCoverageTool}
          product={product}
          handleClose={hideCoverageTool}
        />
      </ProductCardContainer>
    </>
  );
};

export default ProductCard;
