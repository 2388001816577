import { useState } from 'react';
import { IDatalayer, ITracking, trackLink } from '@vfit/shared/data-access';
import { IProduct } from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { SoftManagerService } from '@vfit/consumer/providers';

export const useProductListTagging = () => {
  const queryClient = useQueryClient();
  const pageDatalayer = queryClient.getQueryData('pageDatalayer') as ITracking;
  const initialDatalayer = datalayerAdapter(pageDatalayer) || DEFAULT_DATALAYER;
  const [currentDatalayer, setCurrentDatalayer] = useState<IDatalayer>(initialDatalayer);

  const trackProductLink = (currentProduct: IProduct) => {
    const visitor_login_status = SoftManagerService(queryClient)?.getUser()?.visitor_login_status;
    trackLink(
      'swipe',
      'users',
      'swipe',
      'ui_interaction',
      'link',
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {
        product_id: currentProduct?.cmsId,
        product_category: currentProduct?.category,
        product_name: currentProduct?.slug,
        product_price: currentProduct?.price,
        product_quantity: '1',
        product_target_segment: 'consumer',
        ...(visitor_login_status && { visitor_login_status }),
      }
    );
  };

  return {
    currentDatalayer,
    setCurrentDatalayer,
    trackProductLink,
  };
};

const datalayerAdapter = (trackObj: ITracking): IDatalayer => ({
  event_name: trackObj.event,
  product_id: trackObj.pageProduct?.product_id,
  product_category: trackObj.pageProduct?.product_category,
  ...trackObj.opts,
});

const DEFAULT_DATALAYER: IDatalayer = {
  event_name: [],
};
