import { Button, Input, Pill } from '@vfit/shared/atoms';
import { API, requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { useState } from 'react';
import {
  CONSUMER_CMS_SHOPPING_CART,
  IVoucherDiscountSchema,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import * as V from './voucherDiscout.style';
import AppliedVoucher from '../AppliedVoucher/appliedVoucher';

const VoucherDiscount = () => {
  const voucherDiscountData = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_VOUCHER_DISCOUNT
  ) as IVoucherDiscountSchema;

  const { src, srcSet } = requireStaticImageAtomComponent(
    voucherDiscountData?.vouchersdiscount?.applyVoucher?.image || ''
  );
  const [showAppliedVoucher, setShowAppliedVoucher] = useState<boolean>(false);
  const [voucherInput, setVoucherInput] = useState('');

  return !showAppliedVoucher ? (
    <>
      <V.PillContainer>
        <Pill
          label={voucherDiscountData?.vouchersdiscount?.applyVoucher?.pills?.label}
          pillsColor={voucherDiscountData?.vouchersdiscount?.applyVoucher?.pills?.color}
          opacity={1}
        />
      </V.PillContainer>
      <V.Container>
        <V.ImageStyle alt="family.svg" srcSet={srcSet} src={src} />
        <V.VoucherContainer>
          <V.FirstSection>
            <h1>{voucherDiscountData?.vouchersdiscount?.applyVoucher?.title}</h1>
            <p>{voucherDiscountData?.vouchersdiscount?.applyVoucher?.subtitle}</p>
            <Input
              label={voucherDiscountData?.vouchersdiscount?.applyVoucher?.placeholder}
              onChange={(e) => {
                setVoucherInput(e.target.value);
              }}
            />
          </V.FirstSection>
          <V.ButtonContainer>
            <Button
              disabled={voucherInput === ''}
              onClick={() =>
                voucherInput != '' ? setShowAppliedVoucher(true) : setShowAppliedVoucher(false)
              }
            >
              {voucherDiscountData?.vouchersdiscount?.applyVoucher?.mainActionLabel}
            </Button>
            <Button variant="secondary">
              {voucherDiscountData?.vouchersdiscount?.applyVoucher?.secondaryActionLabel}
            </Button>
          </V.ButtonContainer>
        </V.VoucherContainer>
      </V.Container>
    </>
  ) : (
    <AppliedVoucher voucherInput={voucherInput} voucherDiscountData={voucherDiscountData} />
  );
};

export default VoucherDiscount;
