import React, { Children } from 'react';
import { useQueryClient } from 'react-query';
import {
  COEXISTENCE_COOKIE_KEY,
  getAnchors,
  getDefaultSlider,
  getExperiencesSlider,
  getLargeLargeSlider,
  getLargeSmallSlider,
  IGlobalConfigCMS,
  IGlobalPromoCMS,
  IProduct,
  isR02User,
  IWidgetType,
  usePage,
} from '@vfit/consumer/data-access';
import {
  getButtonActionByActionType,
  IGetAllCmsForm,
  manageErrorLeadPlatform,
  manageSuccessLeadPlatform,
  PAGES,
  trackLink,
} from '@vfit/shared/data-access';
import { Fade } from 'react-awesome-reveal';
import { useCookie, useDeviceType } from '@vfit/shared/hooks';
import { CustomText, ImageDivider } from '@vfit/shared/atoms';
import { useRouter } from 'next/router';
import {
  Anchors,
  CardInfoProduct,
  ConnectionCards,
  CTCBox,
  Documents,
  FreeHtml,
  Text,
  Video,
  QRModule,
  TopHeroDynamic,
} from '@vfit/shared/components';
import {
  IActionSlide,
  IDivider,
  IErrorFormApi,
  ISliderCarousel,
  ISubmitOutput,
} from '@vfit/shared/models';
import {
  DevicesList,
  LeadPlatformConsumer,
  ProductsWinBack,
  DroppedCart,
  ConsumerFullBanner,
} from '@vfit/consumer/components';
import {
  getFaqWidget,
  getProductSlidesConsumer,
  getSplittedImageTextWidget,
  organizeWidgets,
} from './widgets.utils';
import ExperiencesSlider from '../ExperiencesSlider/experiencesSlider';
import { IWidgets } from './widgets.models';
import SliderCarouselWithCoverage from '../SliderCarouselWithCoverage/sliderCarouselWithCoverage';
import Faq from '../Faq/faq';
import TabsWidget from '../TabsWidget/tabsWidget';
import ProductsSolutions from '../ProductsSolutions/productsSolutions';
import TechnologyCards from '../TechnologyCards/technologyCards';
import { WidgetContainer, WidgetDivider, FormContainer } from './widgets.style';
import SplittedImageTextCard from '../SplittedImageTextCard/splittedImageTextCard';
import { DisclaimerContainer } from '../ProductsList/productsList.style';
import WidgetProductsList from './components/WidgetProductsList/widgetProductsList';
import Floatingbanner from '../StickyFloatingBanner/stickyFloatingBanner';
import { ProductCharacteristicsTable } from './ProductCharacteristicsTable';

const Widgets = ({ widgets, productsAll, allDevices, tagging, page: propsPage }: IWidgets) => {
  const { asPath, push } = useRouter();
  const queryClient = useQueryClient();
  const { customHubs, page: providerPage } = usePage();
  const page = providerPage || propsPage;
  const forms = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
  const { globalconfig } = (queryClient?.getQueryData('getGlobalConfigurations') ||
    {}) as IGlobalConfigCMS;
  const { globalpromo } = (queryClient?.getQueryData('getGlobalPromo') || {}) as IGlobalPromoCMS;
  const { panicMode: panicModeConfig } = globalconfig || {};
  const [coexistenceCookie] = useCookie(COEXISTENCE_COOKIE_KEY, '');
  const { isDesktop, isMobile, isTablet } = useDeviceType();
  const containerStyle: React.CSSProperties = {
    paddingTop: isMobile ? 32 : 48,
  };

  const animateWidgetIfNeeded = (children: React.ReactNode, isNoAnimation = false) =>
    isNoAnimation ? (
      children
    ) : (
      <Fade direction="up" triggerOnce>
        {children}
      </Fade>
    );

  const getSliderCarousel = (
    sliderCarousel: ISliderCarousel,
    isLastWidget: boolean,
    indexSlider: number,
    divider?: IDivider,
    disclaimer?: string,
    isCovered?: boolean,
    isPromo?: boolean
  ) => {
    const sliderCarouselComponent = () => (
      <>
        <SliderCarouselWithCoverage
          {...sliderCarousel}
          showCase={indexSlider + 1}
          containerStyle={containerStyle}
          anchorScroll={indexSlider === 0}
          isCovered={isCovered}
          isPromo={isPromo}
        />
        {disclaimer && (
          <DisclaimerContainer>
            <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
          </DisclaimerContainer>
        )}
      </>
    );

    return (
      <WidgetContainer isLastWidget={isLastWidget}>
        {(isDesktop || isTablet) && sliderCarouselComponent()}
        {isMobile && sliderCarouselComponent()}
        {divider?.image && (
          <WidgetDivider>
            <ImageDivider
              image={divider.image}
              imageMobile={divider.imageMobile}
              isMobile={isMobile}
              alt={divider.text || ''}
            />
          </WidgetDivider>
        )}
      </WidgetContainer>
    );
  };

  const getProductList = (
    products: IProduct[],
    isLastWidget: boolean,
    onTrack?: string,
    title?: string,
    divider?: IDivider,
    disclaimer?: string,
    hideTabs?: boolean
  ) => (
    <WidgetProductsList
      isLastWidget={isLastWidget}
      onTrack={onTrack || ''}
      title={title || ''}
      products={products}
      divider={divider}
      disclaimer={disclaimer}
      hideTabs={hideTabs}
      panicModeConfiguration={panicModeConfig}
      globalPromo={globalpromo}
    />
  );

  const getProductSolutionList = (
    products: IProduct[],
    indexSlider: number,
    isLastWidget: boolean,
    onTrack?: string,
    title?: string,
    divider?: IDivider,
    disclaimer?: string
  ) => (
    <WidgetContainer isLastWidget={isLastWidget}>
      <Fade direction="up" triggerOnce>
        <ProductsSolutions
          showCase={indexSlider}
          onTrack={onTrack}
          solutions={{
            products,
            description: title,
          }}
          classKey="solutions"
          disclaimer={disclaimer}
          divider={divider}
          isR02={() => isR02User(coexistenceCookie || '')}
          panicModeConfiguration={panicModeConfig}
          globalPromo={globalpromo}
        />
      </Fade>
    </WidgetContainer>
  );

  return (
    <>
      {Children.toArray(
        organizeWidgets(widgets)?.map((widget, indexWidget) => {
          const isLastWidget = indexWidget === widgets.length - 1;
          let productsHub: IProduct[];
          let anchors;
          let faqWidget;
          let widgetUi;
          let isImageAnchors;
          let fullBannerWidget;
          switch (widget.type) {
            case IWidgetType.PRODUCTS_SLIDER:
              return getSliderCarousel(
                {
                  ...getProductSlidesConsumer(widget, customHubs, panicModeConfig, globalpromo),
                },
                isLastWidget,
                indexWidget,
                widget.divider,
                widget.disclaimer
              );
            case IWidgetType.EXP_SLIDER:
              return (
                <ExperiencesSlider
                  containerStyle={containerStyle}
                  experiences={getExperiencesSlider(widget).experiences}
                  divider={widget.divider}
                  disclaimer={widget.disclaimer}
                />
              );
            case IWidgetType.ANCHORS:
              anchors = getAnchors(widget);
              isImageAnchors = anchors?.anchors?.every((el) => !!el.image);
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Anchors
                    anchorScroll={indexWidget === 0}
                    onTrack={tagging}
                    anchors={anchors.anchors}
                    text={anchors.text}
                    description={widget?.description || ''}
                    isImageAnchors={isImageAnchors}
                  />
                  {widget?.disclaimer && (
                    <DisclaimerContainer>
                      <CustomText
                        text={widget.disclaimer}
                        lineHeight={22}
                        size={16}
                        textAlign="left"
                      />
                    </DisclaimerContainer>
                  )}
                  {widget?.divider?.image &&
                    widget?.divider?.text &&
                    animateWidgetIfNeeded(
                      <ImageDivider
                        image={widget?.divider?.image}
                        alt={widget?.divider?.text || ''}
                      />,
                      widget?.isNoAnimation
                    )}
                </WidgetContainer>
              );
            case IWidgetType.DEFAULT_SLIDER:
              return getSliderCarousel(
                getDefaultSlider(widget),
                isLastWidget,
                indexWidget,
                widget.divider,
                widget.disclaimer
              );
            case IWidgetType.LARGE_SMALL_SLIDER:
              return getSliderCarousel(
                getLargeSmallSlider(widget),
                isLastWidget,
                indexWidget,
                widget.divider,
                widget.disclaimer,
                true,
                undefined
              );
            case IWidgetType.LARGE_LARGE_SLIDER:
              return getSliderCarousel(
                getLargeLargeSlider(widget),
                isLastWidget,
                indexWidget,
                widget.divider,
                widget.disclaimer,
                false,
                true
              );
            case IWidgetType.IMAGE_TITLE_DESCRIPTION:
              if(widget.elements ==null || widget.elements?.length == 0) {
                return ;
              }
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <ConnectionCards
                    slides={widget.elements}
                    topText={widget.title}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                  />
                </WidgetContainer>
              );
            case IWidgetType.ICON_TITLE_DESCRIPTION:
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <CardInfoProduct
                    newCards={widget.elements}
                    topText={widget.title}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                    onClickCardAction={(actionCard: IActionSlide | undefined, key) => {
                      const slide = widget?.elements?.find((e) => e.key === key);
                      if (slide?.title) trackLink(`click ${slide?.title?.toLowerCase()}`);
                      getButtonActionByActionType(actionCard, push, queryClient, slide);
                    }}
                  />
                </WidgetContainer>
              );
            case IWidgetType.FAQ:
              faqWidget = getFaqWidget(widget);
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Faq
                    faq={faqWidget.faq}
                    topText={faqWidget.topText}
                    containerStyle={containerStyle}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                  />
                </WidgetContainer>
              );
            case IWidgetType.SPLITTED_IMAGE_TEXT:
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <SplittedImageTextCard {...getSplittedImageTextWidget(widget)} />
                </WidgetContainer>
              );
            case IWidgetType.QR_MODULE:
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <QRModule
                    title={widget.title}
                    descriptionDesktop={widget.description || ''}
                    descriptionMobile={widget.descriptionMobile || ''}
                    buttonLabel={widget.button?.label}
                    buttonLink={widget.button?.url}
                    qrCodeImage={widget.imageMobile || ''}
                    headerImage={widget.image || ''}
                    storeList={widget.elements}
                    disclaimer={widget.disclaimer || ''}
                    divider={widget.divider}
                  />
                </WidgetContainer>
              );
            case IWidgetType.HUB:
            case IWidgetType.HUB_CUSTOM:
              if (widget.type === IWidgetType.HUB) {
                productsHub =
                  productsAll?.filter((p) => p.offerVisibility.showInHub === 'true') || [];
              } else {
                productsHub = [];
                widget.elements.forEach((element) => {
                  if (
                    element.key &&
                    element.id &&
                    customHubs?.[element.key.toLowerCase()]?.[`_${element.id}`]
                  ) {
                    productsHub.push(
                      customHubs?.[element.key.toLowerCase()]?.[`_${element.id}`] as IProduct
                    );
                  }
                });
              }
              return getProductList(
                productsHub,
                isLastWidget,
                widget?.tagging?.parameters || '',
                widget.title || '',
                widget.divider,
                widget.disclaimer,
                widget.description === 'hideTabs'
              );
            case IWidgetType.HUB_SOLUTIONS:
            case IWidgetType.HUB_SOLUTIONS_CUSTOM:
              if (widget.type === IWidgetType.HUB_SOLUTIONS) {
                productsHub =
                  productsAll?.filter((p) => p.offerVisibility.showInSolutions === 'true') || [];
              } else {
                productsHub = [];
                widget.elements.forEach((element) => {
                  if (
                    element.key &&
                    element.id &&
                    customHubs?.[element.key.toLowerCase()]?.[`_${element.id}`]
                  ) {
                    productsHub.push(
                      customHubs?.[element.key.toLowerCase()]?.[`_${element.id}`] as IProduct
                    );
                  }
                });
              }
              return getProductSolutionList(
                productsHub,
                indexWidget,
                isLastWidget,
                widget?.tagging?.parameters || '',
                widget.title || '',
                widget.divider,
                widget.disclaimer
              );
            case IWidgetType.HUB_WINBACK:
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Fade direction="up" triggerOnce>
                    <ProductsWinBack
                      indexSlider={indexWidget}
                      productsAll={productsAll || []}
                      description={widget?.title}
                      onTrack={widget?.tagging?.parameters || ''}
                      divider={widget?.divider}
                      disclaimer={widget?.disclaimer}
                      panicModeConfiguration={panicModeConfig}
                    />
                  </Fade>
                </WidgetContainer>
              );
            case IWidgetType.SHOPPING_CART_TECHNOLOGIES: {
              if (!asPath.includes(PAGES.SHOPPING_CART)) {
                return null;
              }
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <TechnologyCards
                    title={widget.title}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                  />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            }
            case IWidgetType.VIDEO:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Video
                    title={widget.title}
                    url={widget.description}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                  />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            case IWidgetType.TEXT:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Text
                    textConfig={{
                      text: widget.description || '',
                      textAlign: widget?.ref || 'left',
                    }}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                    actionText={widget.customAction?.title || ''}
                    actionClick={() => {
                      if (widget?.customAction?.title)
                        trackLink(`click ${widget.customAction?.title?.toLowerCase()}`);
                      getButtonActionByActionType(widget.customAction, push, queryClient);
                    }}
                  />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            case IWidgetType.FREE_HTML:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <FreeHtml
                    html={widget.description}
                    title={widget.title}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                  />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            case IWidgetType.DROPPED_CART:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <DroppedCart productsAll={productsAll} />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, true);
            case IWidgetType.TOP_HERO:
              widgetUi = (
                <WidgetContainer className="noPadding" isLastWidget={isLastWidget}>
                  <TopHeroDynamic
                    image={widget.image}
                    imageMobile={widget.imageMobile || widget.image}
                    divider={widget.divider}
                    disclaimer={widget.disclaimer}
                    action={widget.customAction}
                    actionClick={() => {
                      if (widget?.customAction?.title)
                        trackLink(`click ${widget?.customAction?.title?.toLowerCase()}`);
                      getButtonActionByActionType(widget.customAction, push, queryClient);
                    }}
                    title={widget.title}
                    text={widget.description}
                    backLabel={widget.button?.label}
                    category={widget?.ref}
                    backUrl={widget.button?.url}
                    descriptionBottom={widget?.elements?.[0]?.description || ''}
                    textAction={widget?.elements?.[0]?.action?.title}
                    textActionClick={() => {
                      if (widget?.elements?.[0]?.action?.title)
                        trackLink(`click ${widget?.elements?.[0]?.action?.title?.toLowerCase()}`);
                      getButtonActionByActionType(widget?.elements?.[0]?.action, push, queryClient);
                    }}
                    isShort={widget?.isShort?.toLowerCase() === 'true'}
                  />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, true);
            case IWidgetType.STICKY_BANNER_FOOTER:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Floatingbanner />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            case IWidgetType.DOCUMENTS:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Documents
                    label={widget.title}
                    documentItems={widget?.elements?.map((element) => ({
                      title: element.description,
                      buttonAction: () => {
                        getButtonActionByActionType(element.action, push, queryClient);
                      },
                    }))}
                  />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            case IWidgetType.TEXT_ACTION:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <div style={{ marginTop: 80 }}>
                    {widget?.elements?.map((element) => (
                      <CTCBox
                        title={element.title || ''}
                        description={element.description || ''}
                        buttonLabel={element.action?.title || ''}
                        buttonAction={() =>
                          getButtonActionByActionType(element.action, push, queryClient)
                        }
                      />
                    ))}
                  </div>
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            case IWidgetType.TABS_WIDGETS:
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <TabsWidget
                    tabs={widget?.elements?.map((element) => ({
                      label: element.title || '',
                      widgets:
                        element?.widgets?.map((w) => ({
                          ...w,
                          isNoAnimation: true,
                        })) || [],
                    }))}
                  />
                  {widget?.divider?.image &&
                    animateWidgetIfNeeded(
                      <ImageDivider
                        image={widget?.divider?.image}
                        alt={widget?.divider?.text || ''}
                      />,
                      widget?.isNoAnimation
                    )}
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            case IWidgetType.FORMS: {
              const ref = widget?.ref || '';
              const formRef = forms?.[ref];
              if (!formRef) return <div />;
              const WidgetLeadPlatform = (
                <LeadPlatformConsumer
                  form={formRef.forms}
                  onSuccess={(submitOutput: ISubmitOutput) =>
                    manageSuccessLeadPlatform(submitOutput, push, queryClient)
                  }
                  onError={(submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
                    manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient)
                  }
                />
              );
              widgetUi = (
                <FormContainer isLastWidget={isLastWidget}>{WidgetLeadPlatform}</FormContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            }
            case IWidgetType.HUB_DEVICES: {
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Fade direction="up" triggerOnce>
                    <DevicesList
                      devices={allDevices || []}
                      topText={widget.title}
                      listCardTitle={widget.description}
                    />
                  </Fade>
                </WidgetContainer>
              );
            }
            case IWidgetType.TABLE_CHARACTERISTICS: {
              return (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <Fade direction="up" triggerOnce>
                    <ProductCharacteristicsTable
                      widgetsCharacteristics={page?.params?.elements?.widgetsCharacteristics}
                    />
                  </Fade>
                </WidgetContainer>
              );
            }
            case IWidgetType.FULL_BANNER:
              fullBannerWidget = page?.params?.elements?.fullbanner;
              widgetUi = (
                <WidgetContainer isLastWidget={isLastWidget}>
                  <ConsumerFullBanner fullBanner={fullBannerWidget} />
                </WidgetContainer>
              );
              return animateWidgetIfNeeded(widgetUi, widget?.isNoAnimation);
            default:
              return null;
          }
        })
      )}
    </>
  );
};

export default Widgets;
