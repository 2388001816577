import { useEffect, useState } from 'react';
import {
  IActivableSection,
  ICarouselCMS,
  IPageProps,
  ISlides,
  ProductType,
  retrieveAppSession,
  useGetActivable,
  useGetActive,
} from '@vfit/consumer/data-access';
import { getAppSessionItems, getSliders, showError } from './hubApp.utils';

export const useHubApp = ({ page }: IPageProps) => {
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userMsisdns, setUserMsisdn] = useState('');
  const [sliders, setSliders] = useState<ICarouselCMS[] | undefined>(undefined);
  const { elements } = page?.params || {};
  const { widgets } = elements || {};
  const [offer, setOffer] = useState<{
    id: string;
    price: string;
    title: string;
    section?: IActivableSection;
  }>({
    id: '',
    price: '',
    title: '',
    section: undefined,
  });
  const {
    data: activableData,
    isError: isErrorActivable,
    isLoading: isLoadingActivable,
    isSuccess: isSuccessActivable,
    refetch: refetchActivable,
  } = useGetActivable(userMsisdns, ProductType.NBA, '', false);
  const {
    data: activeData,
    isLoading: isLoadingActive,
    isSuccess: isSuccessActive,
    isError: isErrorActive,
    refetch: refetchGetActive,
  } = useGetActive('', '', false);

  const handleCloseModal = () => {
    setShowActivationModal(false);
  };

  const handleOpenActivation = () => {
    setShowActivationModal(true);
  };

  const manageActivationCatalog = (event: any) => {
    const { detail } = event as CustomEvent as { detail: ISlides };
    const allSections: IActivableSection[] = activableData?.sections || [];
    let section: IActivableSection = {};
    allSections?.forEach((s) => {
      const allProductsSection = s?.products || [];
      allProductsSection?.forEach((e) => {
        const allBOIds = e?.campaignDetail?.components?.billingOffer || [];
        if (allBOIds) {
          const ids = allBOIds?.map((aBoId: { id: string }) => aBoId?.id?.toString());
          if (ids?.includes(detail?.id?.toString()) && Object.keys(section)?.length === 0) {
            section = s;
          }
        }
      });
    });

    setOffer({
      id: detail?.id?.toString() || '',
      price: '',
      title: detail?.title || '',
      section,
    });
  };

  useEffect(() => {
    setShowActivationModal(!!offer?.id);
  }, [offer]);

  useEffect(() => {
    if (sliders) setIsLoading(false);
  }, [sliders]);

  useEffect(() => {
    if (isSuccessActive) {
      setSliders([...getSliders(widgets?.sliders || [], activableData, activeData)]);
    } else if (isErrorActive) {
      showError();
    }
  }, [isLoadingActive, isErrorActive, isSuccessActive]);

  useEffect(() => {
    if (isSuccessActivable) {
      refetchGetActive();
    } else if (isErrorActivable) {
      showError();
    }
  }, [isLoadingActivable, isErrorActivable, isSuccessActivable]);

  useEffect(() => {
    if (userMsisdns) {
      refetchActivable();
    }
  }, [userMsisdns]);

  useEffect(() => {
    if (activeData) {
      if (typeof document !== 'undefined') {
        document.addEventListener('hubAppActivationServiceEvent', manageActivationCatalog);
      }
    }
    return () =>
      document.removeEventListener('hubAppActivationServiceEvent', manageActivationCatalog);
  }, [activeData]);

  useEffect(() => {
    // force app
    localStorage.setItem('isApp', 'true');
    retrieveAppSession();
    setTimeout(() => {
      const appSessionItems = getAppSessionItems();
      if (!appSessionItems) {
        showError();
      }
      setUserMsisdn(appSessionItems);
    }, 1000);
  }, []);

  return {
    offer,
    showActivationModal,
    isLoading,
    sliders,
    activableData,
    handleCloseModal,
    handleOpenActivation,
  };
};
