import { useQueryClient } from 'react-query';
import { setTrackView, TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { IProduct } from '@vfit/consumer/data-access';
import { setTrackLink } from '@vfit/shared/data-access';

interface ITaggingHomepage {
  taggingOptions?: TrackingOptions;
  product: IProduct;
}

const TaggingProductDetail = ({ taggingOptions, product }: ITaggingHomepage) => {
  const client = useQueryClient();
  const urlParams = new URLSearchParams(window.location.search);
  const isOpenCoverageTool = Boolean(urlParams.get('act'));
  const pageProduct = SoftManagerService(client).getPageProduct(product);

  const { trackView } = useTracking({
    event: ['product_view'],
    event_label: SoftManagerService(client).getPromoLabel(product?.slug || '', 'product_view'),
    opts: {
      ...taggingOptions,
      product_category: pageProduct.product_category || '',
      event_category: 'sales',
    },
    pageProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isOpenCoverageTool,
  });
  setTrackLink(trackView);
  setTrackView(trackView);

  return null;
};

export default TaggingProductDetail;
