import {
  ALTERNATIVE_OFFER_SHOW_MESSAGE_IDKEY,
  ALTERNATIVEOFFERIDKEY,
  CMS_TOOL_START,
  getFormattedPriceString,
  ILockInConfig,
  IN_ACTIVATION,
  START_FROM_APP_LOCKIN,
  START_FROM_LOCKIN,
} from '@vfit/consumer/data-access';
import { Banner } from '@vfit/shared/atoms';
import { AppMobile, getHome } from '@vfit/shared/data-access';
import { OfferMap } from '@vfit/shared/components';
import { SummaryOffer } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyFixed.context';
import { ShoppingCartOfferContainer } from './topShoppingCart.style';
import { getLocalAddress } from '../../shoppingCart.utils';
import { ITopShoppingCart } from './topShoppingCart.models';
import { DISABLE_RESET, isEnabledWaitingCart } from '../../../iBuyFixed.utils';

const TopShoppingCart = ({
  hub,
  titleOfferMap,
  editAddressLabel,
  isSuccessDynamicCart,
}: ITopShoppingCart) => {
  const { product, isLoadingCart, setIsShowCheckout } = useCheckout();
  const IS_LOADING_CART = isEnabledWaitingCart() ? isLoadingCart : !isSuccessDynamicCart;
  const {
    offerLockInWeb: lockInWeb,
    offerLockInApp: lockInApp,
    isLockInMMProduct,
    isLockInProduct,
  } = product;
  const startFromLockin = localStorage.getItem(START_FROM_LOCKIN);
  const isAlternativeOffer = localStorage.getItem(ALTERNATIVEOFFERIDKEY);
  const isAlternativeOfferShowMapMessage = localStorage.getItem(
    ALTERNATIVE_OFFER_SHOW_MESSAGE_IDKEY
  );
  const isAppLockInRetrieved = window.localStorage.getItem(START_FROM_APP_LOCKIN);
  const isAppLockIn: boolean = !!isAppLockInRetrieved && JSON.parse(isAppLockInRetrieved as string);
  const { lockInMM, lockInHub } = lockInWeb || {};

  const onEditAddress = () => {
    const cmsId = localStorage.getItem(CMS_TOOL_START) || '';
    if (!AppMobile.checkIsApp()) {
      if (!cmsId) window.location.href = '/';
      const redirectUrl = `${hub}?${IN_ACTIVATION}=${cmsId}`;
      window.open(redirectUrl, '_self');
      localStorage.setItem(DISABLE_RESET, '1');
      localStorage.removeItem(CMS_TOOL_START);
    } else {
      const home = getHome();
      window.open(`${home}/offers?cmsId=${cmsId}&app=true`, '_self');
    }
  };

  const getOfferMap = (lockInConfig?: ILockInConfig) => {
    const { banner } = lockInConfig || {};
    const address = getLocalAddress();
    let specsMap =
      product?.checkoutMap?.specs?.elements?.map((spec) => ({
        title: spec?.modalTitle || '',
        shortDescription: spec?.frontTitle || '',
        description: spec?.modalContent || '',
      })) || [];
    if (lockInConfig) {
      specsMap =
        lockInConfig?.shortCharacteristicsLockIn?.items?.map((spec) => ({
          title: spec?.title || '',
          shortDescription: spec?.title || '',
          description: spec?.action || '',
        })) || [];
    }
    const showBanner = banner?.text && !IS_LOADING_CART;
    const productOfferTitle = getFormattedPriceString(product.price, product.recurrence);
    const isLoadingCoords = !address || !address.city || !isSuccessDynamicCart;
    return (
      <>
        {showBanner && (
          <Banner text={banner?.text || ''} color={banner?.color} textColor={banner?.textColor} />
        )}
        <OfferMap
          coords={{
            lat: address?.latitude ? parseFloat(address.latitude.toString()) : 0,
            lng: address?.longitude ? parseFloat(address.longitude.toString()) : 0,
          }}
          isLoading={IS_LOADING_CART}
          isLoadingCoords={isLoadingCoords}
          lottie={product?.shoppingCart?.lottie}
          offer={{
            title: titleOfferMap || '',
            topCtaLabel: editAddressLabel || '',
            topCtaAction: onEditAddress,
            originalPrice: product.originalPrice,
            currentPrice: productOfferTitle,
            bottomTitle: product?.category || '',
            subDescTitle: product?.subprice || '',
            subDescription: product?.description || '',
            description: product?.checkoutMap?.description || '',
            promoList: {
              title: product?.checkoutMap?.specs?.title || '',
              elements: specsMap,
            },
          }}
          mapConfig={product?.map}
          isAlternativeOffer={!!isAlternativeOffer && !!isAlternativeOfferShowMapMessage}
          inflation={product?.inflactionCartView}
          isGlobalPromoActive={product.isGlobalPromoActive}
        />
      </>
    );
  };

  const getOfferCart = (lockInConfig: ILockInConfig) => {
    const { title, image, lottie, message } = lockInConfig;
    return (
      <ShoppingCartOfferContainer>
        <SummaryOffer
          product={product}
          title={title}
          image={image}
          message={message}
          lottie={lottie}
          onActivateSolution={() => setIsShowCheckout(true)}
          onEditAddress={onEditAddress}
          isLoading={IS_LOADING_CART}
        />
      </ShoppingCartOfferContainer>
    );
  };

  if (!isAppLockIn && !isLockInMMProduct && !isLockInProduct && IS_LOADING_CART) {
    return getOfferMap();
  }

  if (isAppLockIn && lockInApp) {
    if (lockInApp?.isMap) return getOfferMap(lockInApp);
    return getOfferCart(lockInApp);
  }

  if (startFromLockin && ((isLockInProduct && lockInHub) || lockInHub)) {
    if (lockInHub?.isMap) return getOfferMap(lockInHub);
    return getOfferCart(lockInHub);
  }

  if ((isLockInMMProduct && lockInMM) || (isAlternativeOffer && isLockInProduct && lockInMM)) {
    if (lockInMM?.isMap && startFromLockin) return getOfferMap(lockInMM);
    return getOfferCart(lockInMM);
  }

  return getOfferMap();
};

export default TopShoppingCart;
