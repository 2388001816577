import { useEffect, useState } from 'react';
import {
  ErrorCodes,
  GetCustomerResponse,
  IFindCallerResponse,
  getDXLBundlePO,
  errorMock,
  createPayloadFixed,
  useAssociateCustomer,
  useCampaignAccept,
  useCampaignsInfo,
  useGetShoppingCartSilentUpdate,
  usePage,
  useRetrieveMultiLines,
  useShoppingCartMutation,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { resetData } from '@vfit/shared/data-access';
import {
  IAddressResponse,
  IOfferingServiceabilityResponse,
  IUpdateProductPriceType,
} from '@vfit/shared/models';
import { updateProductPrice } from '@vfit/consumer/ibuyfixed';
import { useCheckout } from '../../../iBuyFixed.context';
import { getEligibilityProduct } from './useLockInAppFlow.utils';

/**
 * Lockin APP Flow
 * If is lockin (DXL)
 * campaigns
 * accept
 * getCart
 *
 * if is not lockin (NOT DXL but alternative)
 * createCart
 */
export const useLockInAppFlow = () => {
  const { products: allProducts } = usePage();
  const queryClient = useQueryClient();
  const [acceptOrderId, setAcceptOrderId] = useState<string>('');
  const {
    product,
    isStartLockInAppFlow,
    lockInAppFlow,
    shippingAddress,
    setCustomerId,
    setProduct,
    setLockInAppFlow,
    setIsStartLockInAppFlow,
  } = useCheckout();
  const {
    data: campaignsData,
    error: campaignsError,
    isLoading: isLoadingCampaigns,
    isSuccess: isSuccessCampaigns,
    isError: isErrorCampaigns,
    refetch: refetchCampaigns,
  } = useCampaignsInfo({
    keyDependency: ['campaignsLockInApp'],
  });
  const {
    data: acceptData,
    error: acceptError,
    isLoading: isLoadingAccept,
    isSuccess: isSuccessAccept,
    isError: isErrorAccept,
    mutate: mutateAccept,
    reset: resetCampaignAccept,
  } = useCampaignAccept();
  const {
    data: getShoppingCartData,
    error: getShoppingCartError,
    isLoading: isLoadingShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isError: isErrorShoppingCart,
    refetch: refetchShoppingCart,
  } = useGetShoppingCartSilentUpdate(acceptOrderId, {
    enabled: false,
    keyDependency: ['getShoppingCartLockInApp'],
  });
  const {
    mutate: mutatePostShoppingCart,
    isLoading: isLoadingPostShoppingCart,
    isSuccess: isSuccessPostShoppingCart,
    isError: isErrorPostShoppingCart,
    error: errorPostShoppingCart,
    data: postShoppingCartData,
  } = useShoppingCartMutation();

  const onSuccessAppLockin = () => {
    if (lockInAppFlow?.checkEligibility?.lockInProduct) {
      setProduct({
        ...lockInAppFlow.checkEligibility.lockInProduct,
        ...updateProductPrice(
          lockInAppFlow.checkEligibility.lockInProduct,
          IUpdateProductPriceType.EDIT
        ),
      });
    }
  };

  const resetLockInAppFlow = () => {
    sessionStorage.removeItem('uxfauthorization');
    resetCampaignAccept();
    resetData(queryClient, ['getShoppingCartLockInApp', 'campaignsLockInApp']);
    setLockInAppFlow({
      ...lockInAppFlow,
      resetLockInAppFlow,
      shoppingCart: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
      accept: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
      checkEligibility: {
        eligible: false,
        lockInOffer: undefined,
        lockInProduct: undefined,
        errorCode: '',
      },
      campaigns: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
    });
  };

  useEffect(() => {
    if (isLoadingShoppingCart) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        shoppingCart: {
          ...lockInAppFlow.shoppingCart,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorShoppingCart) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        shoppingCart: {
          ...lockInAppFlow.shoppingCart,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('shoppingCart', getShoppingCartError),
        },
      });
    } else if (isSuccessShoppingCart) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        shoppingCart: {
          ...lockInAppFlow.shoppingCart,
          data: getShoppingCartData,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      const customerLocal: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      if (customerLocal?.[0]?.id || findCaller?.customerRef?.id)
        setCustomerId(customerLocal?.[0]?.id || findCaller?.customerRef?.id || '');
      onSuccessAppLockin();
    }
  }, [isSuccessShoppingCart, isErrorShoppingCart, isLoadingShoppingCart]);

  useEffect(() => {
    if (isLoadingPostShoppingCart) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        shoppingCart: {
          ...lockInAppFlow.shoppingCart,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorPostShoppingCart) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        shoppingCart: {
          ...lockInAppFlow.shoppingCart,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('shoppingCart', errorPostShoppingCart),
        },
      });
    } else if (isSuccessPostShoppingCart) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        shoppingCart: {
          ...lockInAppFlow.shoppingCart,
          data: postShoppingCartData,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      const customerLocal: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      if (customerLocal?.[0]?.id || findCaller?.customerRef?.id)
        setCustomerId(customerLocal?.[0]?.id || findCaller?.customerRef?.id || '');
      onSuccessAppLockin();
    }
  }, [isSuccessPostShoppingCart, isErrorPostShoppingCart, isLoadingPostShoppingCart]);

  useEffect(() => {
    if (acceptOrderId) refetchShoppingCart();
  }, [acceptOrderId]);

  useEffect(() => {
    if (isLoadingAccept) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        accept: {
          ...lockInAppFlow.accept,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorAccept) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        accept: {
          ...lockInAppFlow.accept,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('accept', acceptError),
        },
      });
    } else if (isSuccessAccept) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        shoppingCart: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: undefined,
          error: undefined,
        },
        accept: {
          ...lockInAppFlow.accept,
          data: acceptData,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      setAcceptOrderId(acceptData?.orderId || '');
    }
  }, [isSuccessAccept, isErrorAccept, isLoadingAccept]);

  useEffect(() => {
    if (isLoadingCampaigns) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        campaigns: {
          ...lockInAppFlow.campaigns,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorCampaigns) {
      setLockInAppFlow({
        ...lockInAppFlow,
        resetLockInAppFlow,
        campaigns: {
          ...lockInAppFlow.campaigns,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('campaigns', campaignsError),
        },
      });
    } else if (isSuccessCampaigns) {
      const { campaignEligibility, lockInProduct } = getEligibilityProduct(
        allProducts || [],
        campaignsData,
        product
      );
      const isEligibleOffer = campaignEligibility?.eligible && campaignEligibility?.lockInOffer;
      setLockInAppFlow({
        ...lockInAppFlow,
        ...(isEligibleOffer && {
          accept: {
            isLoading: true,
            data: undefined,
            error: undefined,
            isError: false,
            isSuccess: false,
          },
        }),
        resetLockInAppFlow,
        campaigns: {
          ...lockInAppFlow.campaigns,
          data: campaignsData,
          error: undefined,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
        checkEligibility: {
          ...campaignEligibility,
          ...(lockInProduct && {
            lockInProduct: {
              ...lockInProduct,
            },
          }),
        },
      });
      if (isEligibleOffer) {
        mutateAccept({
          campaignId: campaignEligibility?.lockInOffer?.id || '',
          sessionId: campaignEligibility?.lockInOffer?.sessionId || '',
        });
      }
    }
  }, [isSuccessCampaigns, isErrorCampaigns, isLoadingCampaigns]);

  useEffect(() => {
    if (isStartLockInAppFlow) {
      setIsStartLockInAppFlow(false);
    }
  }, [isStartLockInAppFlow]);

  useEffect(() => {
    if (isStartLockInAppFlow) {
      // If is DXL lockin product, start lockin flow
      if (getDXLBundlePO()?.toString() === product?.offerId?.toString()) {
        refetchCampaigns();
      } else if (
        product?.offerId?.toString() &&
        !lockInAppFlow?.shoppingCart?.isLoading &&
        !lockInAppFlow?.shoppingCart?.isSuccess
      ) {
        const offeringServiceability: IOfferingServiceabilityResponse =
          getFromLocalStorageByKey('offeringServiceability');
        const payload = createPayloadFixed(
          product?.offerId?.toString(),
          (shippingAddress as IAddressResponse)?.id?.toString() || '',
          (offeringServiceability as IOfferingServiceabilityResponse)?.id?.toString() || ''
        );
        // is an alternative offer, call only shopping cart and associate
        setLockInAppFlow({
          ...lockInAppFlow,
          campaigns: {
            isLoading: false,
            isError: false,
            isSuccess: false,
          },
          accept: {
            isLoading: false,
            isError: false,
            isSuccess: false,
          },
          shoppingCart: {
            isLoading: true,
            isSuccess: false,
            isError: false,
          },
        });
        if (payload) {
          mutatePostShoppingCart(payload);
        }
      }
    }
  }, [isStartLockInAppFlow]);

  useEffect(() => {
    setLockInAppFlow({
      ...lockInAppFlow,
      resetLockInAppFlow,
    });
  }, []);

  return null;
};
