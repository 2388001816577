import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { setTrackLink, setTrackView, TrackingOptions, useTracking } from '@vfit/shared/data-access';

interface ITaggingBlank {
  taggingOptions?: TrackingOptions;
  isDisabledInitialTrack?: boolean;
}

const TaggingBlank = ({ taggingOptions, isDisabledInitialTrack }: ITaggingBlank) => {
  const client = useQueryClient();
  const { trackView } = useTracking({
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: {
      ...taggingOptions,
      product_category: '',
    },
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isDisabledInitialTrack || false,
  });
  setTrackView(trackView);
  setTrackLink(trackView);

  return null;
};

export default TaggingBlank;
