import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IPageNavigation } from '@vfit/shared/models';
import { IPageProps, retrieveAppSession, useTranslate } from '@vfit/consumer/data-access';
import {
  useFooter,
  useHeader,
  errorManager,
  ErrorService,
  useTrackingProvider,
} from '@vfit/shared/data-access';
import { StickyBannerHeader } from '@vfit/consumer/components';
import { usePostAppOfferFlow } from '@vfit/consumer/hooks';
import { colors } from '@vfit/shared/themes';
import { Footer, Header } from '@vfit/shared/components';
import Widgets from '../../Widgets/widgets';
import TaggingBlank from './enrg.tagging';
import { getEnrgInfo, getFwvPostEP } from './enrg.utils';
import { PostCheckout } from './components/PostCheckout/postCheckout';
import { NewLoader } from './enrg.style';
import { Loader } from './components/Loader/loader';
import { withProtectedWithoutCookie } from '../Hoc/withProtectedWithoutCookie';
import { WidgetWrapper } from '../Layout/pageLayout.style';

const Enrg = ({ page, isApp }: IPageProps) => {
  const client = useQueryClient();
  const { translate } = useTranslate();
  const enrgPageNavigation = client.getQueryData('getEnrgPageNavigation') as IPageNavigation;
  const { elements, haveHeader, haveFooter, isNewHeader, isHeaderSmall } = page?.params || {};
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { widgets, taggingOptions, stickybanner, topheaderbanner } = elements || {};
  const { header: customHeader, tagging } = useHeader(enrgPageNavigation);
  const { header } = useHeader(elements?.pageNavigation);
  const { footer } = useFooter(elements?.pageNavigation);
  const enableHeader = haveHeader && header && !isApp;
  const enableFooter = haveFooter && footer && !isApp;
  const [isLoading, setIsLoading] = useState(true);
  const [postData, setPostData] = useState({
    url: getFwvPostEP(),
    msisdn: '',
    account: '',
    checksum: '',
  });

  const { isLoading: isLoadingPostApp, isEligible, getPostData } = usePostAppOfferFlow();

  client.setQueryData('pageDatalayer', {
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: taggingOptions,
    visitorTrackingOpts: getEnrgInfo(),
  });

  const managePostEnrg = () => {
    const { msisdn, checksum, account } = getPostData?.() || {};
    if (!msisdn || !account) {
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: 'Ops',
        message: 'Si è verificato un problema. Riprovare in seguito',
        actionText: 'Chiudi',
        fullscreen: true,
        disableCloseModalOnClickAction: true,
      });
      return;
    }
    setPostData({
      ...postData,
      msisdn,
      account,
      ...(checksum && { checksum }),
    });
  };

  useEffect(() => {
    if (!isLoadingPostApp && !isEligible) {
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: 'Ops',
        message: 'Si è verificato un problema. Riprovare in seguito',
        fullscreen: true,
        disableCloseModalOnClickAction: true,
      });
    }
  }, [isLoadingPostApp, isEligible]);

  useEffect(() => {
    retrieveAppSession();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    if (typeof document !== 'undefined') {
      document.addEventListener('enrgServiceNotificationEvent', managePostEnrg);
    }
    return () => document.removeEventListener('enrgServiceNotificationEvent', managePostEnrg);
  }, []);

  if (isLoadingPostApp || isLoading) {
    return (
      <NewLoader>
        <Loader color={colors.$e60000} />
      </NewLoader>
    );
  }

  if (!isLoadingPostApp && !isEligible) {
    return null;
  }

  const getEnrgPage = () => {
    if (!postData?.url || !postData?.msisdn || !postData?.account)
      return (
        <>
          {enableHeader && (
            <Header
              onTrack={tagging}
              header={header}
              lite={isHeaderSmall}
              isExtendedHeader={isNewHeader}
              topHeaderBanner={topheaderbanner}
              onTranslate={translate}
              extendedHeaderLogo={customHeader?.body?.headerLogo?.image}
            />
          )}
          {stickybanner && <StickyBannerHeader bannerCustomConfig={stickybanner} />}
          {widgets?.sliders && <Widgets widgets={widgets?.sliders} tagging={tagging} page={page} />}
          {enableFooter && footer && (
            <WidgetWrapper padding="0">
              <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />
            </WidgetWrapper>
          )}
        </>
      );
    return <PostCheckout {...postData} />;
  };

  return (
    <div>
      {isReadyTagging && <TaggingBlank taggingOptions={taggingOptions} />}
      {getEnrgPage()}
    </div>
  );
};

const EnrgProtected = withProtectedWithoutCookie()(Enrg);

export default EnrgProtected;
