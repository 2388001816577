import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div<{ reducedBox?: boolean }>`
  background: linear-gradient(45deg, #222222 0%, #25282b 100%);
  padding: ${(props) => (props.reducedBox ? '8px' : '24px')};
  display: flex;
  flex-direction: ${(props) => (props.reducedBox ? 'row' : 'column')};
  gap: 8px;
  border-radius: 20px;
  width: 100%;
  color: white;

  @media (min-width: ${breakpoints.tablet}) {
    width: 515px;
  }

  @media (min-width: ${breakpoints.desktop}) {
  }
`;

export const UpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ImageContainer = styled.div<{ reducedBox?: boolean }>`
  width: ${(props) => (props.reducedBox ? '106.82px' : '150px')};
`;

export const BottomContainer = styled.div<{ reducedBox?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.reducedBox ? '4px' : '8px')};
  letter-spacing: 0px;
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: ${(props) => (props.reducedBox ? 'row' : 'column')};
    ${(props) => props.reducedBox && 'align-items: center;'}
  }
`;

export const Title = styled.h1<{ reducedBox?: boolean }>`
  margin: 0;
  font-family: ${fonts.vodafone};

  ${(props) =>
    props.reducedBox
      ? `${pxToCssFont(14, 16.8)};
      font-weight:400;
      display:inline;
      `
      : `${pxToCssFont(28, 36)}
      font-weight: 800;
      `}

  @media (min-width: ${breakpoints.tablet}) {
    ${(props) =>
      props.reducedBox &&
      `${pxToCssFont(16, 22)};
      font-weight:400;
      `}
  }

  @media (min-width: ${breakpoints.desktop}) {
  }
`;

export const Description = styled.h4`
  font-weight: 400;
  margin: 0;
  ${pxToCssFont(16, 22)}
  margin-bottom: 8px;
  p {
    margin: 0;
  }
`;

export const ButtonContainer = styled.div<{ reducedBox?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  ${pxToCssFont(14, 22.1)}
  letter-spacing: 0.5px;
  text-transform: uppercase;

  ${(props) => props.reducedBox && 'display: none;'}
  button {
    width: 100%;
    background-color: transparent !important;
    color: white !important;
    border-color: white !important ;

    :hover,
    :active {
      background-color: #d9d9d9 !important ;
      color: black !important ;
      border-color: transparent !important ;
    }
    :active {
      background-color: #b3b3b3 !important ;
    }
  }
  @media (min-width: ${breakpoints.tablet}) {
    button {
      width: auto;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    button {
      width: auto;
    }
  }
`;

export const CustomLink = styled.span`
  cursor: pointer;
  font-weight: 700;
  ${pxToCssFont(14, 16.8)}
  display: block;
  letter-spacing: 0px;
  text-decoration: underline;
  margin-left: 2px;
  font-family: ${fonts.vodafone};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(16, 22)}
    display: inline;
  }
`;
