import React, { useEffect } from 'react';
import { colors } from '@vfit/shared/themes';
import { IPostAppCheckout } from './postCheckout.models';
import { NewLoader } from '../../enrg.style';
import { Loader } from '../Loader/loader';

export const PostCheckout = ({ url, msisdn, account, checksum }: IPostAppCheckout) => {
  const removeAppVal = (str: string): string =>
    str?.replace('?app=true', '')?.replace('app=true', '');

  useEffect(() => {
    document?.getElementById('submitButton')?.click();
  }, []);

  return (
    <>
      <NewLoader>
        <Loader color={colors.$e60000} />
      </NewLoader>
      <form
        id="checkoutForm"
        action={url}
        method="POST"
        style={{
          display: 'none',
        }}
      >
        {msisdn && (
          <input type="hidden" name="visitor_id_asset_active" value={removeAppVal(msisdn)} />
        )}
        {account && <input type="hidden" name="account" value={removeAppVal(account)} />}
        {checksum && <input type="hidden" name="chk" value={removeAppVal(checksum)} />}
        <button type="submit" id="submitButton" />
      </form>
    </>
  );
};
