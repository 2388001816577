import {
  CMS_CONFIG,
  evaluateOfferDetails,
  getRedirectUrlFromHomePage,
  getTechByKey,
  IDroppedCartHomepageCms,
  IGlobalConfigCMS,
  ITechs,
  retrieveCartCookie,
  useCmsConfig,
  usePage,
} from '@vfit/consumer/data-access';
import { API, checkWindow, isAcceptedCookieLawGroup, PAGES } from '@vfit/shared/data-access';
import { ICartCookie } from '@vfit/shared/models';
import { evaluateCmsData } from './heroSliderConsumer.utils';
import { useTrackingHeroSliderConsumer } from './heroSliderConsumer.tagging';

const useHeroSliderConsumer = () => {
  const { products } = usePage();
  const cookieCart = retrieveCartCookie() as ICartCookie;
  const {
    placeId,
    userType,
    technology,
    address,
    cmsId,
    offerDetails: offerDetailsCookie,
  } = cookieCart || {};
  const basePathRedirect = offerDetailsCookie?.redirectUrl || '';
  const isLockinProduct = offerDetailsCookie?.isLockinProduct || false;
  const isAcceptedPrivacyPolicy = isAcceptedCookieLawGroup('4');
  const isOkPlaceId = placeId && placeId !== 'undefined';
  const displayedTechnology = technology?.displayedTecnology;
  const allTechs = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_TECNOLOGIES) as ITechs;
  const droppedCartHomepageCMS = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_DROPPED_CART_HOMEPAGE
  ) as IDroppedCartHomepageCms;
  const { globalconfig } =
    (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG) as IGlobalConfigCMS) || {};
  const { panicMode } = globalconfig || {};

  if (checkWindow() && panicMode) {
    (window as any).isPanicModeFixed = panicMode.enablePanicModeFixed === 'true';
  }

  const cmsRawData = displayedTechnology
    ? getTechByKey(allTechs, displayedTechnology)
    : droppedCartHomepageCMS;
  const offerDetails = evaluateOfferDetails(products, cookieCart?.productId);
  const cmsRefinedData = evaluateCmsData(
    cmsRawData,
    address,
    technology,
    userType,
    offerDetails?.price,
    offerDetails?.originalPrice,
    isLockinProduct
  );

  const { onTrack } = useTrackingHeroSliderConsumer();

  return {
    showCookieCart:
      Boolean(cookieCart) &&
      isAcceptedPrivacyPolicy &&
      isOkPlaceId &&
      droppedCartHomepageCMS?.droppedcarthomepage?.disableDroppedCart != 'true',
    cmsData: cmsRefinedData,
    redirectUrl: getRedirectUrlFromHomePage(
      basePathRedirect,
      placeId || '',
      cmsId || '',
      cookieCart?.hubPath || '',
      isLockinProduct
    ),
    onTrack,
  };
};

export default useHeroSliderConsumer;

