import { CustomText } from '@vfit/shared/atoms';
import React from 'react';
import { getActionUrlByActionType } from '@vfit/shared/data-access';
import { FullBanner } from '@vfit/shared/components';
import { IWFullBanner } from './consumerFullBanner.models';
import { DisclaimerContainer } from './consumerFullBanner.style';

const ConsumerFullBanner = ({ fullBanner: fullBannerWidget }: IWFullBanner) => {
  if (!fullBannerWidget) return null;
  return (
    <div>
      {fullBannerWidget?.title && (
        <CustomText
          text={fullBannerWidget?.title}
          size={36}
          lineHeight={50}
          sizeMobile={30}
          lineHeightMobile={38}
        />
      )}
      <FullBanner
        action={{
          ...fullBannerWidget?.customAction,
          style: fullBannerWidget?.customAction?.style
            ? // eslint-disable-next-line no-unsafe-optional-chaining
              +fullBannerWidget?.customAction.style
            : 0,
        }}
        title={fullBannerWidget?.description || ''}
        gradient={fullBannerWidget?.backgroundGradient || ''}
        icon={fullBannerWidget?.image || ''}
        imageBg={fullBannerWidget?.imageBackgroundDesktop || ''}
        imageBgMobile={fullBannerWidget?.imageBackgroundMobile || ''}
        disableMarginBottom
        actionNavigationUrl={
          fullBannerWidget?.customAction && getActionUrlByActionType(fullBannerWidget?.customAction)
        }
      />
      {fullBannerWidget?.disclaimer && (
        <DisclaimerContainer>
          <CustomText
            text={fullBannerWidget?.disclaimer}
            lineHeight={22}
            size={16}
            textAlign="left"
          />
        </DisclaimerContainer>
      )}
    </div>
  );
};

export default ConsumerFullBanner;
