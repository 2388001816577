import styled from 'styled-components';
import { colors, fonts } from '@vfit/shared/themes';

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
`;
