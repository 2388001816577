import { trackView, ITrackError, CartProduct, PageProduct, TOptions } from '@vfit/shared/data-access';
import { retrieveProductTechnology } from '@vfit/consumer/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { IOffer } from './activationLandingModal.models';

const getTaggingActivationLanding = (offer?: IOffer, pageType = 'activation') => {
  const trackingProduct: CartProduct = {
    cart_product_id: `${offer?.campaignId || offer?.id}`,
    cart_product_name: offer?.title,
    cart_product_category: 'fixed',
    cart_product_price: offer?.price && removeCurrency(offer.price),
    cart_product_quantity: '1',
    cart_product_technology: retrieveProductTechnology() || '',
    cart_total: offer?.price && removeCurrency(offer.price),
  };

  const pageProductsInfo: PageProduct = {
    product_name: offer?.title || '',
    product_price: offer?.price ? removeCurrency(offer?.price) : '',
    product_id: `${offer?.campaignId || offer?.id}`,
    product_quantity: '1',
    product_category: 'Addon',
  };

  const opts: TOptions = {
    event_label: pageType,
    product_target_segment: 'consumer',
    event_category: 'sales',
    page_subsection: 'activation',
    page_section: 'fixed addons',
    page_type: pageType,
    journey_type: 'journey',
    journey_name: 'activation',
  };

  return {
    trackingProduct,
    opts,
    pageProductsInfo,
  };
};

const trackActivationLanding = (
  offer: IOffer,
  isSuccessActivation: boolean,
  isLoadingActivation: boolean,
  isErrorActivation: boolean,
  error?: ITrackError
) => {
  const trackingObj = getTaggingActivationLanding(offer);
  const globalTracking = {
    ...(trackingObj?.trackingProduct && { ...trackingObj.trackingProduct }),
    ...(trackingObj?.pageProductsInfo && { ...trackingObj.pageProductsInfo }),
    ...(trackingObj?.opts && { ...trackingObj.opts }),
    product_quantity: '1',
    product_target_segment: 'consumer',
  };
  if (isSuccessActivation) {
    trackView({
      ...globalTracking,
      event_name: 'view',
      event_label_track: 'activation:typ',
      page_type: 'activation typ',
    });
  } else if (isErrorActivation) {
    const pageError = [
      error?.error?.url || '',
      error?.error?.status || '',
      error?.errorType || '',
      error?.errorCode || '',
      error?.isBlocking ? 'blk' : 'nblk',
    ]
      .filter(Boolean)
      .join('_');
    trackView({
      ...globalTracking,
      event_name: 'page_error',
      event_label_track: 'activation:error',
      page_type: 'activation error',
      event_category: 'error',
      page_error: pageError,
      page_error_code: error?.errorCode || '',
    });
  } else if (isLoadingActivation) {
    trackView({
      ...globalTracking,
      event_name: 'view',
      event_label_track: 'activation:loading',
      page_type: 'activation loading”',
    });
  } else {
    trackView({
      ...globalTracking,
      event_name: 'view',
      event_label_track: 'activation:confirm',
      page_type: 'activation confirm',
    });
  }
};

export { getTaggingActivationLanding, trackActivationLanding };
