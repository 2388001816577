import { ClickBox, CustomText } from '@vfit/shared/atoms';
import { Arrow } from '@vfit/shared-icons';
import { GalleryImage, Pills, StickyOffer, VfModal } from '@vfit/shared/components';
import { colors, fonts, stringToAsciiCode } from '@vfit/shared/themes';
import { ActionTextType, IActionStyle, ITextModal } from '@vfit/shared/models';
import { ICharacteristicsValues, ISku } from '@vfit/consumer/data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import { checkWindow, getButtonActionByActionType, trackLink } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';
import { useState } from 'react';
import {
  Box,
  ColorItem,
  ContainerDeviceImage,
  ContainerDeviceInfo,
  ContainerTags,
  ContainerTopDevice,
  DeviceCustomize,
  DeviceCustomizeContainer,
  ListItem,
  ModalContent,
  ModalTitle,
  SlideDeviceContent,
  SlideDeviceNavigation,
} from './topDevice.style';
import DeviceOffer from './components/deviceOffer';
import WithProductDetails from './components/withProductDetails';
import { ITopDevice } from './topDevice.models';
import {
  evaluateColors,
  evaluateSizes,
  isAvailableColor,
  isAvailableSize,
  selectSkuBySize,
} from './topDevice.utils';

const TopDevice = ({ device, selectedSku, changeSku, deviceCharacteristics }: ITopDevice) => {
  const [modal, setModal] = useState<ITextModal>({
    show: false,
  });
  const { asPath, push } = useRouter();
  const { devicedetail } = device;
  const {
    models,
    tagLine,
    haveMarketingLabel,
    deviceBrand,
    deviceName,
    description,
    lowestPrice: price,
    offerLabel,
    pdp,
    labelDetail,
  } = devicedetail;

  const sellableSku = models?.filter((el) => el?.isSellable);
  const tags = pdp?.keywords?.map((el) => ({ keyword: el }));
  const { isMobile } = useDeviceType();
  const { devicecharacteristic } = deviceCharacteristics || {};
  const sizes = evaluateSizes(sellableSku, devicecharacteristic);
  const colorList = evaluateColors(sellableSku);

  const findModelByColor = (colorName: string) =>
    sellableSku.find((sku) => sku.displayNameColor === colorName);

  const actionStickyOffer = () => {
    trackLink(pdp?.action?.title || '');
    getButtonActionByActionType(pdp.action, push);
  };

  const goBack = () => {
    if (checkWindow()) window.location.href = asPath.slice(0, asPath.lastIndexOf('/'));
  };

  const onClickTypeInText = (type: ActionTextType, desc: string | undefined, title?: string) => {
    if (desc) {
      setModal({
        show: true,
        text: desc || '',
        title: title || '',
        disableOrganizeText: true,
      });
    }
  };

  const resetModal = () => {
    setModal({
      show: false,
      text: '',
      title: '',
      disableOrganizeText: false,
    });
  };

  const renderGallery = (model: ISku) => (
    <ContainerDeviceImage>
      <GalleryImage
        key={selectedSku?.sku || ''}
        images={model?.images?.map((el) => el)}
        slideStyle={{
          borderRadius: 0,
          height: 480,
          width: 250,
        }}
      />
    </ContainerDeviceImage>
  );

  const getSticky = () => {
    const cta = IActionStyle.PRIMARY;

    return (
      cta && (
        <StickyOffer
          slideBanner="upward"
          id="sticky-offer-device"
          topLabel={pdp?.stickyTopLabel || ''}
          title={selectedSku?.price || price}
          description={pdp?.stickyShowDetailLabel || ''}
          ctaActivation={{
            action: actionStickyOffer,
            label: pdp?.action?.title || '',
          }}
          showDetailLabel={pdp?.stickyShowDetailLabel}
          hideDetailLabel={pdp?.stickyHideDetailLabel}
          content={getPdpDetails()}
        />
      )
    );
  };

  const getPdpDetails = () => (
    <WithProductDetails description={pdp?.stickyDescription}>
      <DeviceOffer
        brand={deviceBrand}
        title={deviceName}
        subtitle={description}
        price={selectedSku?.price || price}
        offerLabel={labelDetail}
        detail={selectedSku?.description}
        frontLabel={offerLabel}
        onClickTypeInText={onClickTypeInText}
      />
    </WithProductDetails>
  );

  return (
    <>
      <SlideDeviceContent>
        <SlideDeviceNavigation>
          {pdp.backLabel && (
            <button type="button" className="back" onClick={goBack}>
              <div>
                <Arrow rotate={130} />
              </div>
              <span>{pdp.backLabel}</span>
            </button>
          )}
        </SlideDeviceNavigation>
        <ContainerTopDevice>
          {!isMobile && renderGallery(selectedSku)}
          <ContainerDeviceInfo>
            <ContainerTags>
              {haveMarketingLabel && tagLine && (
                <Pills pills={[tagLine]} variant={colors.$00697c} />
              )}
              {tags?.map((tag, index) => (
                <Pills
                  key={stringToAsciiCode(index.toString())}
                  pills={[tag.keyword]}
                  variant={colors.$0d0d0d}
                />
              ))}
            </ContainerTags>

            <DeviceOffer
              brand={deviceBrand}
              title={deviceName}
              subtitle={description}
              price={selectedSku?.price || price}
              offerLabel={labelDetail}
              detail={selectedSku?.description}
              frontLabel={offerLabel}
              onClickTypeInText={onClickTypeInText}
            />
            {isMobile && renderGallery(selectedSku)}
            <DeviceCustomizeContainer>
              <DeviceCustomize>
                <p>{devicecharacteristic?.colors?.label || ''}</p>
                <ListItem>
                  {colorList?.map((color) => (
                    <div key={color.id}>
                      <ClickBox
                        selected={color.displayName === selectedSku?.displayNameColor}
                        onClick={() => {
                          changeSku(findModelByColor(color.displayName)!);
                        }}
                        disabled={!isAvailableColor(selectedSku, sellableSku, color.displayName)}
                      >
                        <Box selected={color.displayName === selectedSku?.displayNameColor}>
                          <ColorItem itemColor={findModelByColor(color.displayName)!.exColor} />
                          <p>{color.displayName}</p>
                        </Box>
                      </ClickBox>
                    </div>
                  ))}
                </ListItem>
              </DeviceCustomize>
            </DeviceCustomizeContainer>

            <DeviceCustomizeContainer>
              <DeviceCustomize>
                <p>{devicecharacteristic?.sizes?.label || ''}</p>
                <ListItem>
                  {sizes?.map((size: ICharacteristicsValues) => (
                    <div key={size.id}>
                      <ClickBox
                        selected={size.id.toString() === selectedSku?.idSize}
                        onClick={() => {
                          changeSku(selectSkuBySize(sellableSku, size.id.toString(), selectedSku)!);
                        }}
                        disabled={!isAvailableSize(selectedSku, sellableSku, size.id)}
                      >
                        <Box selected={size.id.toString() === selectedSku?.idSize}>
                          <p>{size.value}</p>
                        </Box>
                      </ClickBox>
                    </div>
                  ))}
                </ListItem>
              </DeviceCustomize>
            </DeviceCustomizeContainer>
          </ContainerDeviceInfo>
        </ContainerTopDevice>
        {getSticky()}
      </SlideDeviceContent>
      <VfModal height={563} isOpen={modal.show} handleClose={resetModal} customFooter={undefined}>
        <>
          {modal.title && (
            <ModalTitle>
              <CustomText
                fontFamily={fonts.exbold}
                size={36}
                lineHeight={45}
                textAlign="left"
                text={modal.title}
              />
            </ModalTitle>
          )}
          {modal.text && (
            <ModalContent>
              <CustomText
                size={18}
                lineHeight={24}
                textAlign="left"
                text={modal.text}
                disableOrganizeText={modal.disableOrganizeText}
              />
            </ModalContent>
          )}
        </>
      </VfModal>
    </>
  );
};

export default TopDevice;
