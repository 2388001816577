import {
  APP_PLACEHOLDERS,
  ENRG_QSTRING,
  IActivableProduct,
  IActivableResponse,
  IActivableSection,
  IAppSession,
  ICarouselCMS,
  LOGGED_USER_KEYS,
} from '@vfit/consumer/data-access';
import {
  checkWindow,
  getFromLocalStorageByKey,
  errorManager,
  ErrorService,
  TVisitorLoginStatus,
  VisitorTrackingOpts,
} from '@vfit/shared/data-access';
import { QueryClient } from 'react-query';
import { IActionType } from '@vfit/shared/models';
import crypto from 'crypto-es';

const getHubAppInfo = (): VisitorTrackingOpts => {
  const { SHA256 } = crypto;
  const user = getFromLocalStorageByKey('user');
  const appSessionFromStorage: any = checkWindow() && localStorage.getItem('appSession');
  const silentLogin: any = getFromLocalStorageByKey('silentLogin');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const customerData: any = getFromLocalStorageByKey('customerData');
  const appSession: any = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
  const appMsisdn: string = appSession?.value;
  const visitorLoginStatus: TVisitorLoginStatus =
    appMsisdn || isLoggedUser ? 'logged in' : 'logged out';

  return {
    visitor_login_type: 'web',
    ...(user?.customer?.[0].id && { visitor_customer_id: user?.customer[0].id }),
    ...(user?.taggedInfoCust?.email && {
      visitor_id_email: SHA256(user?.taggedInfoCust?.email).toString(),
    }),
    ...(user?.customer?.[0].msisdns && {
      visitor_id_asset_list: user?.customer?.[0].msisdns?.map(
        (msisdn: string) => SHA256(msisdn)?.toString() || ''
      ),
    }),
    ...(appMsisdn && { visitor_id_asset_active: SHA256(appMsisdn).toString() }),
    ...(visitorLoginStatus && { visitor_login_status: visitorLoginStatus }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.birthDate && {
        visitor_customer_dob: customerData[0]?.owningIndividual?.birthDate,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.gender && {
        visitor_customer_gender: customerData[0]?.owningIndividual?.gender,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.city && {
        visitor_customer_location_city: customerData[0]?.billingAddress?.city,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.nation && {
        visitor_customer_location_country: customerData[0]?.owningIndividual?.nation,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.postalCode && {
        visitor_customer_location_id: customerData[0]?.billingAddress?.postalCode,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.firstName && {
        visitor_customer_name_first: customerData[0]?.owningIndividual?.firstName,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.lastName && {
        visitor_customer_name_last: customerData[0]?.owningIndividual?.lastName,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.stateOrProvince && {
        visitor_customer_region: customerData[0]?.billingAddress?.stateOrProvince,
      }),
  };
};

const showError = () => {
  errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
    title: 'Ops!',
    message: 'Si è verificato un errore. Riprova in seguito',
    actionText: 'Chiudi',
    actionEvent: () => window.location.reload(),
    onClose: () => window.location.reload(),
  });
};

const getAppSessionItems = (): string => {
  const appSession: IAppSession = checkWindow() ? getFromLocalStorageByKey('appSession') : null;
  if (!appSession?.items || appSession?.items.length === 0) {
    showError();
    return '';
  }
  return appSession?.items?.map((e) => e.value || '')?.join(',');
};

/**
 * Method to organize the hub app page
 * @param queryClient
 */
const organizeHubAppPage = (queryClient: QueryClient) => {
  const cmsPage: any = queryClient.getQueryData('getHubApp');
  const { pagesparams, widgets, tagging, seo, fullbanner } = cmsPage || {};
  return {
    params: {
      key: pagesparams?.key,
      haveHeader: pagesparams?.visibility?.haveHeader === 'true',
      isHeaderSmall: pagesparams?.visibility?.isHeaderSmall === 'true',
      haveFooter: pagesparams?.visibility?.haveFooter === 'true',
      isShow: pagesparams?.visibility?.showPages === 'true',
      isDisabledInitialTrack: pagesparams?.visibility?.isDisabledInitialTrack === 'true',
      isProtected: pagesparams?.visibility?.isProtected === 'true',
      protectedPass: pagesparams?.visibility?.protectedPass || '',
      path: pagesparams?.urlBuilder?.map(
        (urlBuilder: any) => urlBuilder.page?.trim()?.toLowerCase() || ''
      ) || [''],
      elements: {
        ...(pagesparams?.apiPath?.apiProductsList && {
          apiProductsList: pagesparams.apiPath.apiProductsList,
        }),
        ...(pagesparams?.apiPath?.apiOfferList && {
          apiOfferList: pagesparams.apiPath.apiOfferList,
        }),
        ...(pagesparams?.apiPath?.apiDeviceList && {
          apiDeviceList: pagesparams.apiPath.apiDeviceList,
        }),
        ...(widgets && widgets?.sliders && { widgets }),
        ...(seo && { seo }),
        ...(fullbanner && { fullbanner }),
        ...(tagging && {
          taggingOptions: {
            event_category: tagging?.eventCategory || '',
            page_section: tagging?.pageSection || '',
            page_subsection: tagging?.pageSubsection || '',
            page_type: tagging?.pageType || '',
            event: tagging?.event || '',
            eventLabel: tagging?.eventLabel || '',
            journey_type: tagging?.journeyType || '',
            product_target_segment: tagging?.productTargetSegment || '',
            product_category: tagging?.productCategory || '',
          },
        }),
      },
    },
  };
};

/**
 * Method to check if addon is visible in all section
 * @param sections
 * @param activeData
 * @param boId
 */
const isVisibleAddon = (
  sections: IActivableSection[],
  activeData: IActivableProduct[],
  boId: string
): boolean => {
  let isVisible = false;
  sections?.forEach((s) => {
    const productsSection = s?.products || [];
    productsSection?.forEach((p) => {
      const billingOffer = p?.campaignDetail?.components?.billingOffer || [];
      const billingOfferIds = billingOffer?.map((b: { id: string }) => b.id?.toString() || '');
      if (!isVisible && boId && billingOfferIds?.includes(boId)) {
        isVisible = true;
      }
    });
  });
  const allActiveCampaignId = activeData?.map((p) => p?.id?.campaignId?.toString() || '') || [];
  if (!isVisible && boId && allActiveCampaignId?.includes(boId)) {
    isVisible = true;
  }
  return isVisible;
};

const getSliders = (
  sliders: ICarouselCMS[],
  activableData: IActivableResponse,
  activeData: IActivableProduct[]
): ICarouselCMS[] => {
  const querystringParams = (checkWindow() && window.location.search) || {};
  const urlParams = new URLSearchParams(querystringParams);
  const allSections = activableData?.sections || [];
  return sliders?.map((slider) => {
    const { elements } = slider;
    if (!elements || elements?.length === 0) return slider;
    return {
      ...slider,
      elements: elements
        ?.filter((e) => {
          if (e.id) {
            return isVisibleAddon(allSections, activeData, e?.id?.toString());
          }
          return e.category === 'all';
        })
        ?.map((e) => ({
          ...e,
          category: e.category === 'all' ? '' : e?.category || '',
          action: {
            ...e.action,
            ...(e?.id &&
              isVisibleAddon(allSections, [], e?.id?.toString()) && {
                type: IActionType.HUB_CATALOG_ACT,
              }),
            url: e?.action?.url
              ?.replace(APP_PLACEHOLDERS.ACCOUNT_APP, urlParams?.get(ENRG_QSTRING.ACCOUNT) || '')
              ?.replace(APP_PLACEHOLDERS.CHECKSUM_APP, urlParams?.get(ENRG_QSTRING.CHECKSUM) || '')
              ?.replace(APP_PLACEHOLDERS.MSISDN_APP, urlParams?.get(ENRG_QSTRING.MSISDN) || ''),
          },
        })),
    };
  });
};

export { getHubAppInfo, getAppSessionItems, showError, getSliders, organizeHubAppPage };
