import { TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { ICarouselCMS, IDevice, IProduct } from '@vfit/consumer/data-access';
import { setTrackLink, setTrackView } from '@vfit/shared/data-access';
import { getProductsShowedInHub } from './blank.utils';

interface ITaggingBlank {
  taggingOptions?: TrackingOptions;
  isDisabledInitialTrack?: boolean;
  widgets: ICarouselCMS[];
  customHubs?: { [key: string]: IProduct[] | IDevice[] } | undefined;
}

const TaggingBlank = ({
  taggingOptions,
  isDisabledInitialTrack,
  widgets,
  customHubs,
}: ITaggingBlank) => {
  const client = useQueryClient();
  const pageProduct = getProductsShowedInHub(widgets || [], customHubs);
  const { trackView } = useTracking({
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: {
      ...taggingOptions,
      product_category: pageProduct?.product_category || '',
    },
    pageProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isDisabledInitialTrack || false,
  });
  setTrackView(trackView);
  setTrackLink(trackView);

  return null;
};

export default TaggingBlank;
