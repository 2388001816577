import { useEffect } from 'react';
import { ILeadPlatform, LeadPlatform } from '@vfit/shared/components';
import { useTracking } from '@vfit/shared/data-access';
import { IErrorFormApi, ISubmitOutput } from '@vfit/shared/models';
import { checkIsApp, retrieveAppSession } from '@vfit/consumer/data-access';
import { setTrackLink, trackLink } from '@vfit/shared/data-access';

const LeadPlatformConsumer = ({ form, onSuccess, onError }: ILeadPlatform) => {
  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'user input',
    opts: {
      journey_type: 'task',
      page_subsection: 'user input',
      page_section: 'lead platform',
      page_browser_system: checkIsApp() ? 'webview' : 'browser',
    },
  });

  setTrackLink(trackView);

  const trackResponse = (type: 'success' | 'error', error?: IErrorFormApi) => {
    const isError = type === 'error';

    const getErrorTrack = () => {
      const statusCode = error?.status?.toString() || '';
      const subCode = error?.data?.['subCode'] || '';
      const description = error?.data?.['description'] || '';
      let pageError = statusCode;
      if (subCode) {
        pageError = `${pageError}_${subCode}`;
      }
      if (description) {
        pageError = `${pageError}_${description.replace(
          /[\s~`!@#$%^&*()àòùì_+\-={[}\]|\\:;"'<,>.?/]+/g,
          '_'
        )}`;
      }
      return {
        event_category: 'error',
        page_error: pageError,
        page_error_code: statusCode,
        page_type: 'error page',
        event_label_track: 'error',
        page_subsection: 'error',
      };
    };

    trackView({
      event_name: type === 'success' ? 'view' : 'page_error',
      ...(isError && {
        ...getErrorTrack(),
      }),
      event_label_track: 'typ',
      journey_type: 'task',
      page_section: 'lead platform',
      page_browser_system: checkIsApp() ? 'webview' : 'browser',
    });
  };

  const onSuccessLeadPlatformConsumer = (submitOutput: ISubmitOutput) => {
    onSuccess?.(submitOutput);
    trackResponse('success');
  };

  const onErrorLeadPlatformConsumer = (
    submitOutput: ISubmitOutput[],
    errorCmsApi?: IErrorFormApi
  ) => {
    onError?.(submitOutput, errorCmsApi);
    trackResponse('error', errorCmsApi);
  };

  const trackSubmitButton = () => {
    trackLink(`click on ${form?.submit?.label?.toLowerCase() || 'conferma'}`);
  };

  useEffect(() => {
    if (checkIsApp()) retrieveAppSession();
  }, []);

  return (
    <LeadPlatform
      form={form}
      onSuccess={onSuccessLeadPlatformConsumer}
      onError={onErrorLeadPlatformConsumer}
      onTrackSubmit={trackSubmitButton}
    />
  );
};

export default LeadPlatformConsumer;
