import { QueryClient } from 'react-query';
import crypto from 'crypto-es';
import { IIdentifyResponse, LOGGED_USER_KEYS } from '@vfit/consumer/data-access';
import { checkWindow, getFromLocalStorageByKey, handleEnv, TVisitorLoginStatus, VisitorTrackingOpts } from '@vfit/shared/data-access';

const getEnrgInfo = (): VisitorTrackingOpts => {
  const { SHA256 } = crypto;
  const user = getFromLocalStorageByKey('user');
  const appSessionFromStorage: any = checkWindow() && localStorage.getItem('appSession');
  const silentLogin: any = getFromLocalStorageByKey('silentLogin');
  const identifyEnrichement: IIdentifyResponse = getFromLocalStorageByKey('identify');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const customerData: any = getFromLocalStorageByKey('customerData');
  const appSession: any = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
  const appMsisdn: string = appSession?.value;
  const visitorLoginStatus: TVisitorLoginStatus =
    appMsisdn || isLoggedUser ? 'logged in' : 'logged out';

  return {
    visitor_login_type: 'web',
    ...(user?.customer?.[0].id && { visitor_customer_id: user?.customer[0].id }),
    ...(user?.taggedInfoCust?.email && {
      visitor_id_email: SHA256(user?.taggedInfoCust?.email).toString(),
    }),
    ...(user?.customer?.[0].msisdns && {
      visitor_id_asset_list: user?.customer?.[0].msisdns?.map(
        (msisdn: string) => SHA256(msisdn)?.toString() || ''
      ),
    }),
    ...(identifyEnrichement &&
      identifyEnrichement.id && {
        visitor_id_asset_active: SHA256(identifyEnrichement.id).toString(),
      }),
    ...(appMsisdn && { visitor_id_asset_active: SHA256(appMsisdn).toString() }),
    ...(visitorLoginStatus && { visitor_login_status: visitorLoginStatus }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.birthDate && {
        visitor_customer_dob: customerData[0]?.owningIndividual?.birthDate,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.gender && {
        visitor_customer_gender: customerData[0]?.owningIndividual?.gender,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.city && {
        visitor_customer_location_city: customerData[0]?.billingAddress?.city,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.nation && {
        visitor_customer_location_country: customerData[0]?.owningIndividual?.nation,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.postalCode && {
        visitor_customer_location_id: customerData[0]?.billingAddress?.postalCode,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.firstName && {
        visitor_customer_name_first: customerData[0]?.owningIndividual?.firstName,
      }),
    ...(customerData &&
      customerData[0]?.owningIndividual?.lastName && {
        visitor_customer_name_last: customerData[0]?.owningIndividual?.lastName,
      }),
    ...(customerData &&
      customerData[0]?.billingAddress?.stateOrProvince && {
        visitor_customer_region: customerData[0]?.billingAddress?.stateOrProvince,
      }),
  };
};

/**
 * Method to organize the enrgPage
 * @param queryClient
 */
const organizeEnrgPage = (queryClient: QueryClient) => {
  const cmsPage: any = queryClient.getQueryData('getEnrgPage');
  const cmsPageNavigation = queryClient.getQueryData('getPageNavigation');
  const { pagesparams, widgets, tagging, seo, topheaderbanner, fullbanner } = cmsPage || {};
  return {
    params: {
      key: pagesparams?.key,
      layout: '',
      haveHeader: pagesparams?.visibility?.haveHeader === 'true',
      isHeaderSmall: pagesparams?.visibility?.isHeaderSmall === 'true',
      isNewHeader: pagesparams?.visibility?.isNewHeader === 'true',
      haveFooter: pagesparams?.visibility?.haveFooter === 'true',
      isShow: pagesparams?.visibility?.showPages === 'true',
      isDisabledInitialTrack: pagesparams?.visibility?.isDisabledInitialTrack === 'true',
      isProtected: pagesparams?.visibility?.isProtected === 'true',
      protectedPass: pagesparams?.visibility?.protectedPass || '',
      path: pagesparams?.urlBuilder?.map(
        (urlBuilder: any) => urlBuilder.page?.trim()?.toLowerCase() || ''
      ) || [''],
      elements: {
        pageNavigation: cmsPageNavigation,
        ...(pagesparams?.apiPath?.apiProductsList && {
          apiProductsList: pagesparams.apiPath.apiProductsList,
        }),
        ...(pagesparams?.apiPath?.apiOfferList && {
          apiOfferList: pagesparams.apiPath.apiOfferList,
        }),
        ...(pagesparams?.apiPath?.apiDeviceList && {
          apiDeviceList: pagesparams.apiPath.apiDeviceList,
        }),
        ...(widgets && widgets?.sliders && { widgets }),
        ...(topheaderbanner && { topheaderbanner }),
        ...(fullbanner && { fullbanner }),
        ...(seo && { seo }),
        ...(tagging && {
          taggingOptions: {
            event_category: tagging?.eventCategory || '',
            page_section: tagging?.pageSection || '',
            page_subsection: tagging?.pageSubsection || '',
            page_type: tagging?.pageType || '',
            event: tagging?.event || '',
            eventLabel: tagging?.eventLabel || '',
            journey_type: tagging?.journeyType || '',
            product_target_segment: tagging?.productTargetSegment || '',
            product_category: tagging?.productCategory || '',
          },
        }),
      },
    },
  };
};

/**
 * NOT DELETE
 * IS Temporary FOR ENRG FLOW in production/test
 */
const getFwvPostEP = () => {
  const isDevLocal = process.env['NODE_ENV'] === 'development'
  if (isDevLocal) return 'http://localhost:6767/api';
  const env = process?.env?.['NEXT_PUBLIC_FW_POST_FORM'] || '';
  return atob(env) || '';
};

export { getEnrgInfo, organizeEnrgPage, getFwvPostEP };
