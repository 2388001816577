import { Children, useEffect, useState } from 'react';
import { ImageDivider, CustomText, ModemDetails, TechnologyDetails } from '@vfit/shared/atoms';
import { API, getFromLocalStorageByKey, TechnologyCategories } from '@vfit/shared/data-access';
import {
  ISlides,
  useCmsConfig,
  ITechCard,
  ITechs,
  IDevices,
  IShoppingCartResponse,
  retrieveProduct,
  retrieveProductTechnology,
  recoverServiceabilityField,
  CONSUMER_CMS_DEFAULT,
} from '@vfit/consumer/data-access';
import { removeCurrency, useTechnologyCardsFlow } from '@vfit/consumer/hooks';
import { Fade } from 'react-awesome-reveal';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { TechCard, VfModal } from '@vfit/shared/components';
import { IOfferingServiceabilityResponse } from '@vfit/shared/models';
import { ITechCardInfo, ITechModal, ITechnologyCards } from './technologyCards.models';
import {
  organizeTechnologyCardModal,
  organizeTechCardsProductDetail,
} from './technologyCards.utils';
import { DisclaimerContainer } from '../ProductsSolutions/productsSolutions.style';

const TechnologyCards = ({ title, divider, disclaimer }: ITechnologyCards) => {
  const productTracking = retrieveProduct();
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const client = useQueryClient();

  // Custom property product for to track it on shopping cart at cart view.
  const trackingProduct = {
    cart_id: (shoppingCart as IShoppingCartResponse)?.id,
    cart_product_id: `${productTracking?.offerId}`,
    cart_product_name: productTracking?.slug,
    cart_product_category: 'fixed',
    cart_product_price: productTracking?.price && removeCurrency(productTracking.price),
    cart_product_quantity: '1',
  };

  const opts = {
    event_category: 'sales',
    page_subsection: 'checkout',
    page_section: 'fixed offers',
    page_type: 'cart',
    journey_name: 'checkout',
    product_technology: retrieveProductTechnology(),
  };

  const { trackView } = useTracking({
    event: ['cart_view'],
    opts,
    cartProduct: trackingProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: true,
  });

  const { serviceability, tecnology, isSuccess } = useTechnologyCardsFlow();
  const [techCard, setTechCard] = useState<ISlides[]>([]);
  const [cmsTechConfig, setCmsTechConfig] = useState<ITechCard | undefined>();
  const [techCardInfo, setTechCardInfo] = useState<ITechCardInfo>({
    download: '-',
    upload: '-',
    latency: '-',
  });
  const allTechs = useCmsConfig(CONSUMER_CMS_DEFAULT, API.CMS_GET_TECNOLOGIES) as ITechs;
  const allDevices = useCmsConfig(CONSUMER_CMS_DEFAULT, API.CMS_GET_DEVICES) as IDevices;

  const DEFAULT_MODAL: ITechModal = {
    show: false,
    component: undefined,
  };

  useEffect(() => {
    if (serviceability) {
      const download =
        recoverServiceabilityField(
          (serviceability as IOfferingServiceabilityResponse)?.serviceabilityCharacteristic,
          'MaxDownloadSpeed'
        ) || '-';
      const isFtthTim = tecnology?.mappedCategory === TechnologyCategories.FTTHTIM;
      let upload =
        recoverServiceabilityField(
          (serviceability as IOfferingServiceabilityResponse)?.serviceabilityCharacteristic,
          'UploadMax'
        ) || '-';
      if (isFtthTim) {
        upload = '300';
      }
      const latency =
        recoverServiceabilityField(
          (serviceability as IOfferingServiceabilityResponse)?.serviceabilityCharacteristic,
          'Latency'
        ) || '-';
      setTechCardInfo({
        download,
        upload,
        latency,
      });
    }
  }, [isSuccess]);

  const switchTech = () => {
    let tech: ITechCard | undefined;
    switch (tecnology?.mappedCategory) {
      case TechnologyCategories.FTTH:
        setCmsTechConfig(allTechs.ftth);
        tech = allTechs.ftth;
        break;
      case TechnologyCategories.FTTC:
        setCmsTechConfig(allTechs.fttc);
        tech = allTechs.fttc;
        break;
      case TechnologyCategories.ADSL:
        setCmsTechConfig(allTechs.adsl);
        tech = allTechs.adsl;
        break;
      case TechnologyCategories.FWAINDOOR:
        setCmsTechConfig(allTechs.FWAINDOOR);
        tech = allTechs.FWAINDOOR;
        break;
      case TechnologyCategories.FWAOUTDOOR:
        setCmsTechConfig(allTechs.FWAOUTDOOR);
        tech = allTechs.FWAOUTDOOR;
        break;
      case TechnologyCategories.FWAOUTDOOR5G:
        setCmsTechConfig(allTechs.FWAOUTDOOR5G);
        tech = allTechs.FWAOUTDOOR5G;
        break;
      case TechnologyCategories.FTTHTIM:
        setCmsTechConfig(allTechs.FTTHTIM);
        tech = allTechs.FTTHTIM;
        break;
      case TechnologyCategories.FTTHCD:
        setCmsTechConfig(allTechs.FTTHCD);
        tech = allTechs.FTTHCD;
        break;
      case TechnologyCategories.FWAINDOOR5G:
        setCmsTechConfig(allTechs.FWAINDOOR5G);
        tech = allTechs.FWAINDOOR5G;
        break;
      default:
        break;
    }
    if (tecnology?.mappedCategory) {
      const organizedCards = organizeTechCardsProductDetail(
        tech,
        tecnology?.mappedCategory,
        allDevices
      );
      setTechCard(organizedCards);
    }
  };

  useEffect(() => {
    if (tecnology) switchTech();
  }, [tecnology]);

  const [techModal, setTechModal] = useState<ITechModal>(DEFAULT_MODAL);

  const handleCloseModal = () => {
    setTechModal(DEFAULT_MODAL);
  };

  const showDetail = (product: ISlides) => {
    // device
    if (!product.showInfoLine) {
      trackView({
        event_name: 'ui_interaction',
        event_label_track: `${product.detail?.title
          ?.replace(/(<([^>]+)>)/gi, '')
          .replace(/(?:\r\n|\r|\n)/g, '')}`,
        event_category: 'users',
        event_action: 'click',
      });
      setTechModal({
        show: true,
        tabletMode: false,
        component: (
          <ModemDetails
            title={product?.detail?.title || ''}
            content={product?.detail?.description?.textSimple || ''}
          />
        ),
      });
    } else {
      const {
        title: techTitle,
        firstDescription,
        secondDescription,
        imageSrc,
        subtitle,
        accordions,
      } = organizeTechnologyCardModal(cmsTechConfig as ITechCard);
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'technology details',
        event_category: 'users',
        event_action: 'click',
      });
      setTechModal({
        show: true,
        tabletMode: true,
        containerHeight: '80%',
        component: (
          <TechnologyDetails
            title={techTitle}
            firstDescription={firstDescription}
            accordions={accordions}
            secondDescription={secondDescription}
            imageSrc={imageSrc}
            subtitle={subtitle}
          />
        ),
      });
    }
  };

  return (
    <>
      {title && techCard.length > 0 && <CustomText text={title} />}
      <div>
        {Children.toArray(
          techCard.length > 0 &&
            techCard?.map((product, index) => (
              <TechCard
                techCard={product}
                even={index % 2 === 0}
                onDetail={() => showDetail(product)}
                download={`${techCardInfo?.download || ''}/s maximum`}
                upload={`${techCardInfo?.upload || ''} Megabit/s maximum`}
                latency={`${techCardInfo?.latency || ''} ms`}
              />
            ))
        )}
        {techModal?.show && techModal?.component && (
          <VfModal
            isOpen={techModal?.show}
            handleClose={handleCloseModal}
            tabletMode={techModal?.tabletMode}
            height={techModal?.containerHeight}
          >
            {techModal?.component}
          </VfModal>
        )}
      </div>
      {disclaimer && (
        <DisclaimerContainer>
          <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
        </DisclaimerContainer>
      )}
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </>
  );
};

export default TechnologyCards;
