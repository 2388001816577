import { useState } from 'react';
import {
  Container,
  UpperContainer,
  BottomContainer,
  Title,
  Description,
  CustomLink,
  ButtonContainer,
  ImageContainer,
} from './blackBox.style';
import { IBlackBoxCard, IBlackBoxCMS } from './blackBoxCard.model';
import { Button, ImageAtoms } from '@vfit/shared/atoms';
import { ExitIcon } from '@vfit/shared-icons';

const BlackBoxCard = (props: IBlackBoxCard) => {
  const { title, description, buttonLabel, timeToShow, showX, imageReducedSrc, imageSrc } =
    props?.blackBoxConfig || {};
  const [reducedBox, setReducedBox] = useState(false);

  const reduceBlackBox = () => {
    setReducedBox(true);
  };

  return (
    <Container reducedBox={reducedBox}>
      <UpperContainer>
        <ImageContainer key={reducedBox ? 'container-reduced' : 'container-expanded'} reducedBox={reducedBox}>
          {reducedBox ? (
            <ImageAtoms nameCard={'logo-reduced'} image={imageReducedSrc} />
          ) : (
            <ImageAtoms nameCard={'logo-expanded'} image={imageSrc} />
          )}
        </ImageContainer>
        {showX && !reducedBox && (
          <div>
            <span
              role="button"
              tabIndex={0}
              className="closing-icon"
              onClick={() => reduceBlackBox()}
            >
              <ExitIcon stroke="white" />
            </span>
          </div>
        )}
      </UpperContainer>
      <BottomContainer reducedBox={reducedBox}>
        <Title reducedBox={reducedBox}>
          {title}
          {reducedBox && <CustomLink>{buttonLabel}</CustomLink>}
        </Title>
        {!reducedBox && (
          <Description
            dangerouslySetInnerHTML={{
              __html: description || '',
            }}
          />
        )}
        <ButtonContainer reducedBox={reducedBox}>
          <Button>{buttonLabel}</Button>
        </ButtonContainer>
      </BottomContainer>
    </Container>
  );
};

export default BlackBoxCard;

