import { useEffect, useState } from 'react';
import { AppMobile } from '@vfit/shared/data-access';
import { retrieveAppSession } from '@vfit/consumer/data-access';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const withAppSession = () => (Component) => (props) => {
  const isApp = AppMobile.checkIsApp();
  const [isShowPage, setIsShowPage] = useState(!isApp);

  const checkAppSession = () => {
    if (isApp) {
      retrieveAppSession();
      setTimeout(() => {
        setIsShowPage(true);
      }, 800);
    }
  };

  useEffect(() => {
    checkAppSession();
  }, []);

  if (!isShowPage) return null;

  return <Component {...props} />;
};

export { withAppSession };
