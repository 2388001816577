import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { IPageNavigation } from '@vfit/shared/models';
import { IPageProps, retrieveAppSession, useTranslate } from '@vfit/consumer/data-access';
import { Footer, Header } from '@vfit/shared/components';
import { AppMobile, useFooter, useHeader, errorManager, ErrorService, useTrackingProvider } from '@vfit/shared/data-access';
import { StickyBannerHeader } from '@vfit/consumer/components';
import { useThirdPartyEligibilityCustomer } from '@vfit/consumer/hooks';
import { colors } from '@vfit/shared/themes';
import Widgets from '../../Widgets/widgets';
import { withProtected } from '../Hoc/withProtected';
import TaggingBlank from './tpo.tagging';
import { getTpoInfo } from './tpo.utils';
import { NewLoader } from './tpo.style';
import { Loader } from './components/loader/loader';
import { WidgetWrapper } from '../Layout/pageLayout.style';
import { ITpoEditorialConfig } from './tpo.models';

const Tpo = ({ page, isApp }: IPageProps) => {
  const client = useQueryClient();
  const { translate } = useTranslate();
  const tpoPageNavigation = client.getQueryData('getTpoPageNavigation') as IPageNavigation;
  const tpoEditorialConfig = client.getQueryData('getTpoEditorialConfig') as ITpoEditorialConfig;
  const { elements, haveHeader, haveFooter, isNewHeader, isHeaderSmall } = page?.params || {};
  const editorialConfigs = tpoEditorialConfig?.editorialconfigs?.elements || [];
  const { isReady: isReadyTagging } = useTrackingProvider();
  const { widgets, taggingOptions, stickybanner, topheaderbanner } = elements || {};
  const { header: customHeader, tagging } = useHeader(tpoPageNavigation);
  const { header } = useHeader(elements?.pageNavigation);
  const { footer } = useFooter(elements?.pageNavigation);
  const enableHeader = haveHeader && header;
  const enableFooter = haveFooter && footer;

  const { isLoading, isEligible, token } = useThirdPartyEligibilityCustomer({
    isStartFlow: true,
    startFlow: 1,
  });

  const getLabel = (id: string): string =>
    editorialConfigs?.find((e) => e?.id?.toLowerCase() === id?.toLowerCase())?.value || '';

  client.setQueryData('pageDatalayer', {
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: taggingOptions,
    visitorTrackingOpts: getTpoInfo(),
  });

  useEffect(() => {
    if (!isLoading && !isEligible) {
      if (!token) {
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
          title: getLabel('no_token_title') || 'Ops',
          message:
            getLabel('no_token_message') || 'Si è verificato un problema. Riprovare in seguito',
          fullscreen: true,
          disableCloseModalOnClickAction: true,
        });
      } else {
        errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
          title: getLabel('error_token_title') || 'Ops',
          message:
            getLabel('error_token_message') ||
            "Non è possibile concludere l'acquisto per questa tipologia di utente. Riprova in seguito.",
          disableCloseModalOnClickAction: true,
        });
      }
    }
  }, [isLoading, isEligible]);

  useEffect(() => {
    if (AppMobile.checkIsApp()) {
      retrieveAppSession();
    }
  }, []);

  if (isLoading) {
    return (
      <NewLoader>
        <Loader color={colors.$e60000} />
      </NewLoader>
    );
  }

  if (!isLoading && !isEligible && !token) {
    return null;
  }

  return (
    <div>
      {isReadyTagging && <TaggingBlank taggingOptions={taggingOptions} />}
      {enableHeader && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          isExtendedHeader={isNewHeader}
          topHeaderBanner={topheaderbanner}
          onTranslate={translate}
          extendedHeaderLogo={customHeader?.body?.headerLogo?.image}
        />
      )}
      {stickybanner && <StickyBannerHeader bannerCustomConfig={stickybanner} />}
      {widgets?.sliders && <Widgets widgets={widgets?.sliders} tagging={tagging} page={page} />}
      {enableFooter && footer && (
        <WidgetWrapper padding="0">
          <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />
        </WidgetWrapper>
      )}
    </div>
  );
};

const TpoProtected = withProtected()(Tpo);

export default TpoProtected;
