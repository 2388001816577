import {IGetAddonResponse, IOfferingType} from "@vfit/consumer/data-access";
import {QueryClient} from "react-query";
import {TSavedAddons} from "./useAddonFlow.models";

const isAddonInSection = (
  section: any,
  addonId: string
): boolean => section?.some((el : any) => el.offeringId === addonId) ?? false;

const getSections = (getAddonsData?: IGetAddonResponse) => {
  const fixedVoiceSection =
    getAddonsData?.bundledFixedVoiceOfferingConfiguration?.[0]?.fixedVoiceOfferingConfiguration?.[0]?.bundledAddOnConfiguration;

  const dataSimSection =
    getAddonsData?.bundledDataSimOfferingConfiguration?.[0]?.mobileOfferingConfiguration?.[0]?.bundledAddOnGroupConfiguration;

  const internetOfferingSection =
    getAddonsData?.bundledInternetOfferingConfiguration?.[0]?.internetOfferingConfiguration?.[0]?.bundledAddOnConfiguration;

  return {
    fixedVoiceSection: fixedVoiceSection || null,
    dataSimSection: dataSimSection || null,
    internetOfferingSection: internetOfferingSection || null
  };
};

export const retrieveAvailableAddons = (getAddonsData : IGetAddonResponse) : string[] => {
  const availableList : string[] = [];
  const {fixedVoiceSection, dataSimSection, internetOfferingSection} = getSections(getAddonsData);
  if(fixedVoiceSection){
    fixedVoiceSection.forEach(el => availableList.push(el.offeringId));
  }
  if(dataSimSection){
    dataSimSection.forEach(group => {
      group.bundledAddOnConfiguration?.forEach(el => availableList.push(el.offeringId))
    });
  }
  if(internetOfferingSection){
    internetOfferingSection.forEach(el => availableList.push(el.offeringId));
  }
  return availableList
}

export const retrieveAddonTypeById = (addonId: string, getAddonsData? : IGetAddonResponse) : IOfferingType | undefined => {
  const {fixedVoiceSection, dataSimSection, internetOfferingSection} = getSections(getAddonsData);
  let addonType : IOfferingType | undefined;

  if(isAddonInSection(fixedVoiceSection, addonId)) addonType = IOfferingType.FIXED_VOICE;

  dataSimSection?.forEach(group =>  {
    if(isAddonInSection(group.bundledAddOnConfiguration, addonId)) addonType = IOfferingType.SIM_DATA;
  })

  if(isAddonInSection(internetOfferingSection, addonId)) addonType = IOfferingType.INTERNET;

  return addonType || undefined;
};

const getBaseObjSaveAddon = (addonType : IOfferingType, data: any) =>  {
  let obj : any = {};
  switch(addonType){
    case IOfferingType.FIXED_VOICE: {
      obj = data.fixedVoiceOfferingConfiguration;
      break;
    }
    case IOfferingType.INTERNET: {
      obj = data.internetOfferingConfiguration;
      break;
    }
    case IOfferingType.SIM_DATA: {
      obj = data.mobileOfferingConfiguration;
    }
  }
  return obj;
}

export const getReferenceId = (addonType: IOfferingType, addonMecId: string, data: any) =>{
  const baseObj = getBaseObjSaveAddon(addonType, data);
  return baseObj?.bundledAddOnConfiguration?.find((el : any) => el.offeringId === addonMecId)
    ?.addOnOfferingConfiguration[0]?.productSpecificationConfiguration
    ?.referenceId || null
}

export const saveAddonOnQueryClient = (queryClient : QueryClient, addonId: string, referenceId: string, addonType: IOfferingType) =>{
  const savedAddons = queryClient.getQueryData("SavedAddons") as Record<string, TSavedAddons> || {};
  queryClient.setQueryData("SavedAddons", {
    ...savedAddons,
    [addonId]: {
      referenceId,
      type: addonType
    }
  });
}

export const removeAddonFromQueryClient = (
  queryClient: QueryClient,
  addonId: string
) => {
  const savedAddons = queryClient.getQueryData("SavedAddons") as Record<string, TSavedAddons> || {};

  if (savedAddons[addonId]) {
    const { [addonId]: _, ...updatedAddons } = savedAddons;
    queryClient.setQueryData("SavedAddons", updatedAddons);
  }
};

export const getReferenceIdFromQueryClient = (
  queryClient : QueryClient,
  addonId: string
): string => {
  const savedAddons = queryClient.getQueryData("SavedAddons") as Record<string, TSavedAddons>;
  return savedAddons?.[addonId]?.referenceId || '';
}

