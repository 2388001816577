import {
  checkIsGoBackSIA,
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  errorMock,
  getCurrentUserType,
  GetCustomerResponse,
  IFindCallerResponse,
  IGlobalConfigCMS,
  IUserType,
  useAssociateCustomer,
  useBillingAccount,
  useCmsConfig,
  useCustomer,
  useGetCustomer,
  useGetFindCaller,
  useGetPaymean,
  usePatchCustomer,
  usePayment,
  useUpdateProductCharacteristic,
} from '@vfit/consumer/data-access';
import { useEffect } from 'react';
import {
  API,
  computeInverse,
  getFromLocalStorageByKey,
  IFiscalCodeReverse,
  PAGES,
} from '@vfit/shared/data-access';
import { LoggerInstance, resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { useCheckout } from '../../../iBuyFixed.context';
import { getBSNewMop, getErrorStatusText, getPatchPayload } from './useCustomerFlow.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { IUseCustomerResponse } from './useCustomerFlow.models';

/**
 * Customer flow
 * if the user is NEXT ACTIVE call this flow
 * findcaller - getCustomer - associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 * if the user in Prospect or Logged call
 * patchCustomer/postCustomer - getCustomer - associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 * if user is logged
 * associatecustomer - payment - billingaccount - paymean - updateproductcharacteristics
 */
export const useCustomerFlow = () => {
  const queryClient = useQueryClient();
  const {
    isStartCustomerFlow,
    owningData,
    customerFlow,
    lockInFlow,
    currentStepKey,
    customerId,
    findCaller: findCallerPreOTP,
    setIsStartDeliveryBase,
    setCustomerFlow,
    setCustomerId,
    setIsStartCustomerFlow,
    setBillingEmail,
  } = useCheckout();
  const isNextActive = !!(owningData.isValid && findCallerPreOTP.verifyOtp);
  const isLogged = getCurrentUserType() === IUserType.LOGGED_USER;
  const globalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;
  const {
    data: paymentData,
    error: errorPayment,
    isSuccess: isSuccessPayment,
    isLoading: isLoadingPayment,
    isError: isErrorPayment,
    refetch: refetchPayment,
  } = usePayment({
    enabled: false,
  });
  const {
    error: paymeanError,
    data: paymeanData,
    isSuccess: isSuccessPaymean,
    isLoading: isLoadingPaymean,
    isError: isErrorPaymean,
    refetch: refetchPaymean,
  } = useGetPaymean({ enabled: false }, globalConfig);
  const {
    error: billingAccountError,
    isLoading: isBillingAccountLoading,
    isError: isBillingAccountError,
    isSuccess: isBillingAccountSuccess,
    data: billingAccountData,
    refetch: refetchBillingAccount,
  } = useBillingAccount(
    {
      enabled: false,
    },
    globalConfig
  );
  const {
    data: associateCustomerData,
    error: associateCustomerError,
    mutate: associateCustomerMutate,
    isSuccess: isSuccessAssociateCustomer,
    isError: isErrorAssociateCustomer,
    isLoading: isLoadingAssociateCustomer,
  } = useAssociateCustomer();
  const {
    data: patchCustomerData,
    error: patchCustomerError,
    mutate: patchCustomerMutate,
    isSuccess: isSuccessPatchCustomer,
    isError: isErrorPatchCustomer,
    isLoading: isLoadingPatchCustomer,
  } = usePatchCustomer();
  const {
    data: postCustomerData,
    error: postCustomerError,
    mutate: postCustomerMutate,
    isSuccess: isSuccessPostCustomer,
    isError: isErrorPostCustomer,
    isLoading: isLoadingPostCustomer,
  } = useCustomer();
  const {
    data: customerData,
    error: customerError,
    isSuccess: isSuccessGetCustomer,
    isError: isErrorGetCustomer,
    isLoading: isLoadingGetCustomer,
    refetch: refetchGetCustomer,
  } = useGetCustomer(customerId, { enabled: false });
  const {
    mutate: mutateUpdateProductCharacteristics,
    error: updateProductCharacteristicsError,
    isError: isErrorUpdateProductCharacteristics,
    isSuccess: isSuccessUpdateProductCharacteristics,
    isLoading: isLoadingUpdateProductCharacteristics,
  } = useUpdateProductCharacteristic();
  const {
    data: findcallerData,
    error: findcallerError,
    isError: isErrorFindcaller,
    isSuccess: isSuccessFindcaller,
    isLoading: isLoadingFindcaller,
    refetch: refetchFindcaller,
  } = useGetFindCaller(
    { fiscalCode: owningData?.owningIndividual?.fiscalCode?.toUpperCase() },
    {
      enabled: false,
    }
  );

  const handleCRMErrorCodes = (backendError: string): string => {
    switch (backendError) {
      case 'CRM-0020':
      case 'CRM-0024':
        return ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT;
      case 'CRM-0021':
      case 'CRM-0023':
        return ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT;
      case 'CRM-0022':
      case 'CRM-0025':
        return ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT;
      default:
        return '';
    }
  };

  const isAlreadyDoneAssociateCustomer = () => {
    const alreadyDoneAssociateCustomer = getFromLocalStorageByKey('associateCustomer');
    const shoppingCart = getFromLocalStorageByKey('shoppingCart');
    return !!shoppingCart && !!alreadyDoneAssociateCustomer;
  };

  useEffect(() => {
    if (isLoadingPaymean) {
      setCustomerFlow({
        ...customerFlow,
        paymean: {
          ...customerFlow.paymean,
          error: undefined,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorPaymean) {
      setCustomerFlow({
        ...customerFlow,
        paymean: {
          ...customerFlow.paymean,
          isSuccess: false,
          isLoading: false,
          isError: true,
          error: errorMock('paymean', paymeanError),
        },
      });
    } else if (isSuccessPaymean) {
      setCustomerFlow({
        ...customerFlow,
        paymean: {
          ...customerFlow.paymean,
          error: undefined,
          data: paymeanData,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      });
    }
  }, [isErrorPaymean, isLoadingPaymean, isSuccessPaymean]);

  useEffect(() => {
    if (isBillingAccountLoading) {
      setCustomerFlow({
        ...customerFlow,
        billingAccount: {
          ...customerFlow.billingAccount,
          error: undefined,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isBillingAccountError) {
      setCustomerFlow({
        ...customerFlow,
        billingAccount: {
          ...customerFlow.billingAccount,
          isSuccess: false,
          isError: true,
          isLoading: false,
          error: errorMock('billingAccount', billingAccountError),
        },
      });
    } else if (isBillingAccountSuccess) {
      const isAlreadyDonePayMean = getFromLocalStorageByKey('payMean');
      setCustomerFlow({
        ...customerFlow,
        paymean: {
          ...customerFlow.paymean,
          ...(!isAlreadyDonePayMean && { isLoading: true }),
        },
        billingAccount: {
          ...customerFlow.billingAccount,
          data: getBSNewMop(billingAccountData, globalConfig),
          error: undefined,
          isError: false,
          isSuccess: true,
          isLoading: false,
        },
      });
      setBillingEmail(billingAccountData.billDeliveryDetails.emailAddress);
      if (!isAlreadyDonePayMean) refetchPaymean();
    }
  }, [isBillingAccountError, isBillingAccountLoading, isBillingAccountSuccess, billingAccountData]);

  useEffect(() => {
    if (isLoadingPayment) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        payment: {
          ...customerFlow.payment,
          error: undefined,
          isError: false,
          isSuccess: true,
          isLoading: false,
        },
      });
    } else if (isErrorPayment) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        payment: {
          ...customerFlow.payment,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('payment', errorPayment),
        },
      });
    } else if (isSuccessPayment) {
      const isAlreadyDoneBillingAccount = getFromLocalStorageByKey('billingAccount');
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        billingAccount: {
          ...customerFlow.billingAccount,
          ...(!isAlreadyDoneBillingAccount && { isLoading: true }),
        },
        payment: {
          ...customerFlow.payment,
          data: paymentData,
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      });
      if (!checkIsGoBackSIA() && !isAlreadyDoneBillingAccount) refetchBillingAccount();
    }
  }, [isSuccessPayment, isErrorPayment, isLoadingPayment]);

  useEffect(() => {
    if (isLoadingUpdateProductCharacteristics) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        updateProductChatacteristics: {
          ...customerFlow.updateProductChatacteristics,
          error: undefined,
          isError: false,
          isSuccess: true,
          isLoading: false,
        },
      });
    } else if (isErrorUpdateProductCharacteristics) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        updateProductChatacteristics: {
          ...customerFlow.updateProductChatacteristics,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('updateProductChatacteristics', updateProductCharacteristicsError),
        },
      });
    } else if (isSuccessUpdateProductCharacteristics) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        updateProductChatacteristics: {
          ...customerFlow.updateProductChatacteristics,
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      });
    }
  }, [
    isSuccessUpdateProductCharacteristics,
    isErrorUpdateProductCharacteristics,
    isLoadingUpdateProductCharacteristics,
  ]);

  useEffect(() => {
    if (isLoadingAssociateCustomer) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        associateCustomer: {
          ...customerFlow.associateCustomer,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorAssociateCustomer) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        associateCustomer: {
          ...customerFlow.associateCustomer,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('associateCustomer', associateCustomerError),
        },
      });
      resetData(queryClient, ['associateCustomer']);
    } else if (isSuccessAssociateCustomer) {
      if ((associateCustomerData as any)?.cartValidation?.status === 'blocked') {
        setCustomerFlow({
          ...customerFlow,
          resetPatchAndPost,
          associateCustomer: {
            ...customerFlow.associateCustomer,
            data: associateCustomerData,
            isLoading: false,
            isSuccess: false,
            isError: true,
            error: errorMock('associateCustomer', {
              status: '500',
              statusText: getErrorStatusText('500', ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED),
              url: '',
            }),
          },
        });
      } else {
        const isAlreadyDonePayment = getFromLocalStorageByKey('paymentData');
        setCustomerFlow({
          ...customerFlow,
          resetPatchAndPost,
          payment: {
            ...customerFlow.payment,
            ...(!isAlreadyDonePayment && { isLoading: true }),
          },
          associateCustomer: {
            ...customerFlow.associateCustomer,
            data: associateCustomerData,
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: undefined,
          },
        });
        const customerLocal: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
        const findCallerInactive: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
        if (!customerId && (customerLocal?.[0]?.id || findCallerInactive?.customerRef?.id))
          setCustomerId(customerLocal?.[0].id || findCallerInactive?.customerRef?.id || '');
        mutateUpdateProductCharacteristics(undefined);
        if (!isAlreadyDonePayment) refetchPayment();
        setIsStartDeliveryBase(true);
      }
    }
  }, [isSuccessAssociateCustomer, isErrorAssociateCustomer, isLoadingAssociateCustomer]);

  useEffect(() => {
    if (isLoadingGetCustomer) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        customerInfo: {
          ...customerFlow.customerInfo,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorGetCustomer) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        customerInfo: {
          ...customerFlow.customerInfo,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('customerInfo', customerError),
        },
      });
    } else if (isSuccessGetCustomer) {
      const isNextInactive = getCurrentUserType() === IUserType.NEXT_USER_INACTIVE;
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        ...(!isNextInactive &&
          !isAlreadyDoneAssociateCustomer() && {
            associateCustomer: {
              isLoading: true,
              isSuccess: false,
              isError: false,
              data: undefined,
              error: undefined,
            },
          }),
        customerInfo: {
          ...customerFlow.customerInfo,
          data: customerData?.[0],
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      });
      if (!isAlreadyDoneAssociateCustomer() && !isLogged) {
        associateCustomerMutate();
      }
    }
  }, [isSuccessGetCustomer, isErrorGetCustomer, isLoadingGetCustomer]);

  useEffect(() => {
    if (customerId) {
      const alreadyHaveCustomerData = getFromLocalStorageByKey('customerData');
      if (!alreadyHaveCustomerData) refetchGetCustomer();
    }
  }, [customerId]);

  useEffect(() => {
    if (isLoadingPostCustomer) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        postCustomer: {
          ...customerFlow.postCustomer,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorPostCustomer) {
      const headerErrorCode = postCustomerError.headers?.get('ERR-errorCode') || '';
      const backendError = postCustomerError.headers?.get('ERR-backendErrorCode') || '';
      const backendMessage = postCustomerError.headers?.get('ERR-backendErrorMessage') || '';
      const errorCode =
        headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE
          ? handleCRMErrorCodes(backendError)
          : headerErrorCode;
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        postCustomer: {
          ...customerFlow.postCustomer,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock(
            'postCustomer',
            {
              status: postCustomerError.status,
              statusText: getErrorStatusText(postCustomerError.status, errorCode),
              url: '',
            },
            backendError,
            errorCode,
            backendMessage
          ),
        },
      });
      resetData(queryClient, ['postCustomer']);
    } else if (isSuccessPostCustomer) {
      const alreadyHaveCustomerData = getFromLocalStorageByKey('customerData');
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        ...(alreadyHaveCustomerData && {
          customerInfo: {
            isError: false,
            isSuccess: false,
            isLoading: true,
            data: undefined,
            error: undefined,
          },
        }),
        postCustomer: {
          ...customerFlow.postCustomer,
          data: postCustomerData,
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      });
      setCustomerId(postCustomerData.id);
    }
  }, [isSuccessPostCustomer, isErrorPostCustomer, isLoadingPostCustomer]);

  useEffect(() => {
    if (isLoadingPatchCustomer) {
      const alreadyDoneAssociateCustomer = getFromLocalStorageByKey('associateCustomer');
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        ...(alreadyDoneAssociateCustomer && {
          associateCustomer: {
            ...customerFlow.associateCustomer,
            ...lockInFlow.associateCustomer,
            isSuccess: false,
          },
        }),
        patchCustomer: {
          ...customerFlow.patchCustomer,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorPatchCustomer) {
      const headerErrorCode = patchCustomerError?.headers?.get('ERR-errorCode') || '';
      const backendError = patchCustomerError?.headers?.get('ERR-backendErrorCode') || '';
      const backendMessage = patchCustomerError.headers?.get('ERR-backendErrorMessage') || '';
      const errorCode =
        headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE
          ? handleCRMErrorCodes(backendError)
          : headerErrorCode;
      LoggerInstance.log('PatchCustomer', headerErrorCode, backendError, backendMessage, errorCode);
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        patchCustomer: {
          ...customerFlow.patchCustomer,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock(
            'patchCustomer',
            {
              status: patchCustomerError.status,
              statusText: getErrorStatusText(patchCustomerError.status, errorCode),
              url: '',
            },
            backendError,
            errorCode,
            backendMessage
          ),
        },
      });
      resetData(queryClient, ['patchCustomer']);
    } else if (isSuccessPatchCustomer) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        ...(!isAlreadyDoneAssociateCustomer() && {
          associateCustomer: {
            data: undefined,
            isLoading: true,
            isSuccess: false,
            isError: false,
            error: undefined,
          },
        }),
        ...(isAlreadyDoneAssociateCustomer() && {
          associateCustomer: {
            ...customerFlow.associateCustomer,
            isSuccess: true,
          },
        }),
        patchCustomer: {
          ...customerFlow.patchCustomer,
          data: patchCustomerData,
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
        customerInfo: {
          ...customerFlow.customerInfo,
          data: patchCustomerData?.[0],
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      });
      localStorage.setItem('customerData', JSON.stringify([patchCustomerData]));
      if (!isAlreadyDoneAssociateCustomer()) {
        associateCustomerMutate();
      }
    }
  }, [isSuccessPatchCustomer, isErrorPatchCustomer, isLoadingPatchCustomer]);

  useEffect(() => {
    if (isLoadingFindcaller) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        findcaller: {
          ...customerFlow.findcaller,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorFindcaller) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        findcaller: {
          ...customerFlow.findcaller,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('findcaller', findcallerError),
        },
      });
    } else if (isSuccessFindcaller) {
      setCustomerFlow({
        ...customerFlow,
        resetPatchAndPost,
        customerInfo: {
          isError: false,
          isSuccess: false,
          isLoading: true,
          data: undefined,
          error: undefined,
        },
        findcaller: {
          ...customerFlow.findcaller,
          data: findcallerData,
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      });
    }
  }, [isSuccessFindcaller, isErrorFindcaller, isLoadingFindcaller]);

  const resetPatchAndPost = (stateCustomerFlow: IUseCustomerResponse) => {
    if (stateCustomerFlow?.postCustomer?.isError) {
      localStorage.removeItem('postCustomer');
      setCustomerFlow({
        ...stateCustomerFlow,
        postCustomer: {
          error: undefined,
          data: undefined,
          isLoading: false,
          isError: false,
          isSuccess: false,
        },
      });
    } else if (stateCustomerFlow?.patchCustomer.isError) {
      localStorage.removeItem('patchCustomer');
      setCustomerFlow({
        ...stateCustomerFlow,
        patchCustomer: {
          error: undefined,
          data: undefined,
          isLoading: false,
          isError: false,
          isSuccess: false,
        },
      });
    }
  };

  const createCustomer = () => {
    const cfData: IFiscalCodeReverse | null = computeInverse(
      owningData.owningIndividual.fiscalCode
    ) as IFiscalCodeReverse;
    postCustomerMutate({
      owningData,
      cfInverse: cfData,
    });
  };

  useEffect(() => {
    if (isStartCustomerFlow) {
      setIsStartCustomerFlow(false);
    }
  }, [isStartCustomerFlow]);

  const checkPrecondition = () => {
    if (isNextActive) {
      refetchFindcaller();
    } else if (isLogged) {
      if (!isAlreadyDoneAssociateCustomer()) {
        const customerLocal: GetCustomerResponse = getFromLocalStorageByKey('customerData')?.[0];
        setCustomerFlow({
          ...customerFlow,
          resetPatchAndPost,
          associateCustomer: {
            isLoading: true,
            isSuccess: false,
            isError: false,
            data: undefined,
            error: undefined,
          },
          customerInfo: {
            ...customerFlow.customerInfo,
            data: customerLocal,
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: undefined,
          },
        });
        if (!customerFlow?.associateCustomer?.isLoading) {
          associateCustomerMutate();
        }
      }
    } else if (currentStepKey !== ID_FLOWS.CONTACT_CARD) {
      const isNextActiveInactive =
        getCurrentUserType() === IUserType.NEXT_USER_INACTIVE ||
        getCurrentUserType() === IUserType.NEXT_USER_ACTIVE;
      const alreadyDonePostCustomer = getFromLocalStorageByKey('postCustomer');
      if (alreadyDonePostCustomer || isNextActiveInactive) {
        patchCustomerMutate(getPatchPayload(owningData));
      } else {
        createCustomer();
      }
    }
  };

  useEffect(() => {
    if (isStartCustomerFlow) checkPrecondition();
  }, [isStartCustomerFlow]);

  useEffect(() => {
    setCustomerFlow({
      ...customerFlow,
      resetPatchAndPost,
    });
  }, []);

  return null;
};
