import React from 'react';
import { isMobileProduct } from '@vfit/consumer/data-access';
import { useDynamicShoppingCartFlow } from '@vfit/consumer/hooks';
import { FLOW_TYPE_DYNAMIC_SHOPPING_CART } from '@vfit/shared/models';
import { IBuyMobile } from '@vfit/consumer/ibuymobile';
import { IBuyFixed } from '@vfit/consumer/ibuyfixed';
import { withAppSession } from '../Hoc/withAppSession';

const ShoppingCart = () => {
  const {
    flowType,
    forceInit,
    product,
    isSuccess: isSuccessDynamicCart,
    isError: isErrorDynamicCart,
  } = useDynamicShoppingCartFlow();

  if (!product && !forceInit) return <div />;

  const isMobile = isMobileProduct();
  if (isMobile || flowType == FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_MOBILE) {
    return <IBuyMobile />;
  }
  return (
    <IBuyFixed
      isSuccessDynamicCart={isSuccessDynamicCart}
      isErrorDynamicCart={isErrorDynamicCart}
    />
  );
};

const ShoppingCartWithAppSession = withAppSession()(ShoppingCart);

export default ShoppingCartWithAppSession;
